import React from "react";
import questionIllustration from "../../components/assets/questionIllustration.png";
import { motion, useScroll, useTransform } from "framer-motion";
import { fadeRightToLeft, fadeUp } from "..";

const EnglishTutoring = () => {
  // const { scrollYProgress } = useScroll();
  // const opacity = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
  // const sentence = `At TutorWo, we direct our attention to teaching your child the
  // skills they need to be proficient readers and writers, which form
  // the groundwork for English and lifelong literacy in disciplines
  // beyond English. To lay the foundations, our lessons are centred on:`;

  // const splitToWords = () => {
  //   const words = [];
  //   sentence.split(" ").map((word, index) => {
  //     words.push(
  //       <motion.span
  //         style={opacity}
  //         key={word + index}
  //         className="ml-3 opacity-10 inline">
  //         {word}
  //       </motion.span>
  //     );
  //   });

  //   return words;
  // };

  // const headingAnimation = {
  //   hidden: { opacity: 0 },
  //   visible: {
  //     opacity: 1,
  //     transition: {
  //       staggerChildren: 0.1,
  //       delayChildren: 0.3,
  //     },
  //   },
  // };

  const parent = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 1,
      },
    },
  };

  const lessonsFoundation = [
    "reading/ text comprehension skills",
    "expanding vocabulary bank",
    "spelling",
    "writing skills, written expression and written fluency",
  ];

  const secondaryStudentsFocus = [
    "metalanguage",
    "text response",
    "comparative analysis",
    "persuasive language analysis and writing",
  ];

  return (
    <section className="flex justify-center mt-10">
      <div className="flex flex-col">
        {/* Illustration and question */}
        <div className="flex flex-col-reverse  md:flex-row justify-center items-center">
          <img
            className="-translate-y-24 sm:translate-y-0 w-[24rem] h-[32rem]"
            src={questionIllustration}
          />
          <div className="relative flex flex-col items-center justify-center">
            <svg
              className="w-[23rem] sm:w-[32rem] h-[32rem] -translate-y-8 md:translate-y-0"
              width="1083"
              height="497"
              viewBox="0 0 1083 497"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.43631 453.239C29.6888 370.718 83.2116 288.197 122.936 209.115C143.843 166.917 190.257 110.34 194.857 65.0164C196.529 50.3252 215.346 39.3849 237.09 40.6352L1042.02 0C1063.77 1.25031 1080.07 14.3786 1078.82 29.0698L916.578 354.464C914.905 369.155 896.089 380.096 874.345 378.845L224.963 409.791C185.239 427.92 122.099 446.05 82.3753 463.867C37.6336 483.872 -16.7255 528.883 5.43631 453.239Z"
                fill="#863D90"
              />
            </svg>
            <p className="absolute top-[9rem] right-5 lg:top-40 sm:right-10 text-center text-white font-semibold text-[0.9rem] sm:text-lg w-[80%] p-5">
              Does your child lack strategies in reading comprehension, struggle
              with writing, and/or is all over the place with spelling, grammar
              and punctuation?
            </p>
          </div>
        </div>
        {/* End - Illustration and question */}
        {/* Information */}
        <div className="flex flex-col lg:flex-row justify-center mx-auto w-[95%] lg:w-[70%] gap-10 mt-20 border-2 border-purple rounded-3xl">
          <motion.h2
            variants={fadeRightToLeft}
            initial={"hidden"}
            whileInView={fadeRightToLeft.visible}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
            className="text-white text-2xl font-semibold w-full p-10 bg-purple rounded-3xl bg-hero bg-contain">
            At TutorWo, we direct our attention to teaching your child the
            skills they need to be proficient readers and writers, which form
            the groundwork for English and lifelong literacy in disciplines
            beyond English. To lay the foundations, our lessons are centred on:
          </motion.h2>
          <div className="pt-5 pb-12 pl-10 lg:p-5 rounded-3xl flex flex-col justify-center lg:w-[60%]">
            <motion.ul
              className="pl-5 list-disc text-lg text-blue font-semibold gap-2"
              variants={parent}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}>
              {lessonsFoundation.map((focus, index) => (
                <motion.li
                  key={"lessonsFoundation" + index}
                  variants={fadeUp}
                  whileInView={fadeUp.visible}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
                  {focus}
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </div>
        {/* End- Information */}
        {/* Information 2*/}
        <div className="flex flex-col lg:flex-row justify-center mx-auto w-[95%] lg:w-[70%] gap-10 mt-20 border-2 border-purple rounded-3xl">
          <motion.h2
            variants={fadeRightToLeft}
            initial={"hidden"}
            whileInView={fadeRightToLeft.visible}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
            className="text-white text-2xl font-semibold w-full p-10 bg-blue rounded-3xl bg-hero bg-contain">
            For our secondary school students, there will be addition focus in
            the following areas that will be crucial for success in VCE later
            down the track:
          </motion.h2>
          <div className="pt-5 pb-12 pl-10 lg:p-5 rounded-3xl flex flex-col justify-center lg:w-[60%]">
            <motion.ul
              className="pl-5 list-disc text-lg text-purple font-semibold gap-2"
              variants={parent}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}>
              {secondaryStudentsFocus.map((focus, index) => (
                <motion.li
                  key={"secondaryStudentsFocus" + index}
                  variants={fadeUp}
                  whileInView={fadeUp.visible}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}>
                  {focus}
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </div>
        {/* End- Information 2*/}
      </div>
    </section>
  );
};

export default EnglishTutoring;
