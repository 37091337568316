import React from "react";
import studyGuide from "../../assets/studyGuide.png";
import ServicesHero from "../../ServicesHero";

const VceStudyGuides = () => {
  return (
    <section className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Study Guides"
        secondHeading="VCE Study Guides"
        headingDescription="Why reinvent the wheel creating your own notes when our top ATAR scores have already done it for you? Save time by gaining full access to the notes and summaries produced by top ATAR scorers."
        leftTag="Full access to notes"
        rightTag="Summaries by top ATAR scorers"
        image={studyGuide}
        imageDescription="Illustration of scholarship selective school exam"
      />
      <h2 className="text-purple text-3xl font-bold mt-10">
        -- COMING SOON --
      </h2>
    </section>
  );
};

export default VceStudyGuides;
