import React, { useState } from "react";
import {
  zoe,
  jade,
  omar,
  ruby,
  justine,
  yiChen,
  winnie,
  fiona2,
  tristina,
  tina,
  sucharita,
  matthew,
  cathryn,
  carol1,
  sammi,
  andy,
  ardhan,
  catherine,
  charmaine,
  lorraine,
  // rachel,
  ruoChen,
  ahelee,
  amelia,
  carol2,
  diya,
  helene,
  parkhi,
  sylvia,
  priyenka,
  venus,
  fadeUp,
  fadeLeftToRight,
  fadeRightToLeft,
  rachel,
} from "../";
import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";
// import { ConstructionOutlined } from "@mui/icons-material";

const OurTutors = () => {
  const numberTag = "bg-white border-blue text-blue";
  const languageTag = "bg-white border-purple text-blue";
  const scienceTag = "bg-white border-orange text-blue";
  const otherTag = "bg-white border-yellow text-blue";
  const [showModal, setShowModal] = useState(false);
  const [{ tutorName, tutorDescription, tutorImage, testimonial }, setTutor] =
    useState({});
  const handleClick = (e) => {
    const targetIndex = e.target.getAttribute("data-index");
    setTutor({
      tutorName: tutorToDisplay[targetIndex].name,
      tutorDescription: tutorToDisplay[targetIndex].description,
      tutorImage: tutorToDisplay[targetIndex].imageUrl,
      testimonial: tutorToDisplay[targetIndex].testimonial,
    });
    setShowModal(true);
  };

  const people = [
    {
      name: "Ahelee",
      role: "Scholarship and Entrance Test Preparation | Secondary English | Secondary Mathematics | VCE Biology | VCE English | VCE English Language",
      imageUrl: ahelee,
      description:
        "Ahelee was an academic achievement scholarship student and School Captain at Melbourne Girls Grammar. She graduated with an ATAR of 97.70 and is currently completing a Bachelor's degree in Biomedicine at the University of Melbourne. Ahelee has a proven track record in inspiring her students with her strong passion and enthusiasm for English and English Language. Beyond tutoring and pursuing her studies, she is currently the Multicultural Youth Network Facilitator at the Victorian Multicultural Commission, the Senior Youth Ambassador producer and host for the Student Broadcast Network on radio station 94.1FM 3WBC, as well as an Ambassador for MS Australia. Ahelee is also a member of the Youth Advisory Board for Spill The Beans, an organisation that works to empower young people to voice their ideas, stories and inspiration. She is a passionate advocate of the perspectives of young Australians in a democracy as a regular contributor and commentator on The Age, ABC News and her own blog.",
      subjects: [
        ["Secondary English", languageTag],
        ["Scholarship and Entrance Test Preparation ", otherTag],
        ["Secondary Mathematics", numberTag],
        ["VCE Biology", scienceTag],
        ["VCE English", languageTag],
        ["VCE English language", languageTag],
      ],
      testimonial: [
        {
          name: "Andy",
          role: "Parent",
          feedback:
            "My girls said Ahelee was good, easy to talk to and understand. She even gave me an email with a short breakdown. That was nice as well.",
        },
        {
          name: "Deborah",
          role: "Parent",
          feedback:
            "My daughter said Ahelee was very positive and enthusiastic in her approach to teaching. My daughter came away feeling happy and positive with new ideas/ strategies that she will be working on.",
        },
      ],
    },
    {
      name: "Amelia",
      role: "Secondary English, VCE English, VCE English Literature, VCE German, VCE Legal Studies",
      imageUrl: amelia,
      description:
        "Amelia graduated from MacRobertson Girls High School with an ATAR of 97+. For English, she achieved an A+ examination score of 56/60 which was within the top 1 percent of the State, and a VCAA perfect 20/20 for Section A. Amelia offers a bank of English resources, notes and Raw 50 exemplar essays. She helps her students to conquer the examination and understand how English works, not as English, but as a VCE subject. Amelia strives to work alongside her students and achieve their full academic potential. She is currently studying a Bachelor of Law (Honours) and Arts at Monash University.",
      subjects: [
        ["Secondary English", languageTag],
        ["VCE English", languageTag],
        ["VCE English Literature", languageTag],
        ["VCE German", languageTag],
        ["VCE Legal Studies", otherTag],
      ],
      testimonial: [
        {
          name: "Amber",
          role: "Year 12",
          feedback:
            "Amelia sends me the lesson plan prior to class and is very flexible in adapting the class to suit my needs.",
        },
        {
          name: "Anish",
          role: "Year 12",
          feedback:
            "Amelia sends me a lesson plan before every class with detailed information on the topic along with resources to help guide me. This allows for me to be prepared and ready to learn for when my class begins. She is very respectful and happily assists me if I am confused or unsure about anything. Amelia has allowed me to express my writing but also provide ample amounts of suggestions and support to ensure I can gain as much success as possible through my writing.",
        },
      ],
    },
    {
      name: "Andy",
      role: "Secondary Mathematics, VCE Mathematics, VCE Physics",
      imageUrl: andy,
      description:
        "Andy graduated from the Victorian Certificate of Education (VCE) with an ATAR of 93.10 in 2012 and was awarded the Deakin International Scholarship for his double degree in Teaching and Science. In 2016, Andy completed his Bachelor of Teaching and Bachelor of Science, achieving Distinction in both degrees. He started teaching Junior Mathematics in 2017 followed by VCE General Mathematics and Physics the year after. Andy is an experienced teacher who implements High Impact Teaching Strategies and contemporary pedagogical practices. He is currently the Director of Professional Learning at his school. As part of Andy's role, he collaborates with Domain Leaders, the Assistant Principal and all teaching staff in ensuring the provision of learning and teaching is aligned with the goals and outcomes of the school's Strategic Plan.",
      subjects: [
        ["Secondary Mathematics", numberTag],
        ["VCE Mathematics", numberTag],
        ["VCE Physics", numberTag],
      ],
      testimonial: [
        {
          name: "Tony",
          role: "",
          feedback:
            "Andy (Mathematics Tutor) starts his session off by getting an update from Ian, investigates also looking at the school IT platforms. Andy will explain to Ian as to what the learning outcomes are from his new lesson. Andy is working parallel with Ian's curriculum so that he and Ian's maths teacher are on the same page. Andy at the end of his lesson will give me an update, let me know what I need to follow up with Ian and at times ask me to seek clarification from Ian's teacher at school on particular work that Ian has done. Ian's school teacher is more than happy to engage in the conversation. Ian is currently averaging 78.9 on his maths school work.",
        },
      ],
    },
    {
      name: "Ardhan",
      role: "VCE Biology, VCE Psychology",
      imageUrl: ardhan,
      description: `Ardhan is a Biomedicine and Engineering student at Monash University. He previously graduated from the selective-entry Nossal High School with an ATAR of 93. His teaching style is designed to make students’ learning experience interactive and effective. Ardhan believes in clear communication to simplify complex topics and actively encourage questions. Students’ unique learning style matters, and he is committed to adapting his methods to suit his students’ needs. Ardhan emphasises building problem-solving skills and breaking down tough concepts into manageable parts. What sets him apart is his empathy for students' struggles. Ardhan has faced his own academic challenges, including initial setbacks in engineering. Through dedication and hard work, he not only passed but excelled in that subject. These experiences have given Ardhan a profound understanding of the hurdles students can face. He understands the frustration and uncertainty that can come with academic setbacks, and his commitment is to provide tailored support that addresses students’ unique learning styles and needs. Ardhan is a tutor who not only possesses subject expertise but also understands and empathises with his students’ academic journey. `,
      subjects: [
        ["VCE Biology", scienceTag],
        ["VCE Psychology", scienceTag],
      ],
    },
    {
      name: "Carol",
      role: "VCE Chinese Second Language/Second Language Advanced",
      imageUrl: carol1,
      description:
        "Carol has a Bachelor of Arts (Chinese and International Studies) from the University of Sydney where she maintained a High Distinction average. She is currently completing a Master of Teaching (Secondary) at Monash University. Carol previously graduated from Camberwell Girls Grammar School where she was the International Captain. She achieved an ATAR of 94.60 with Study Scores of 45 and 48 in Chinese and Japanese respectively. Carol possesses proven expertise in boosting her students' confidence and interest in learning Chinese, particularly in improving their speaking and writing skills. She achieves the forementioned by executing innovative approaches and techniques in teaching as well as creating teaching resources that guide students in reaching their full potential.",
      subjects: [
        ["VCE Chinese Second Language", languageTag],
        ["VCE Chinese Second Language Advanced", languageTag],
      ],
    },
    {
      name: "Carol",
      role: "Secondary Mathematics, VCE Mathematics",
      imageUrl: carol2,
      description:
        "Carol is an experienced and dedicated Mathematics tutor. She graduated from Mac.Robertson Girls’ High School with an ATAR of 97.95. Carol is continuing her passion for Mathematics through a Bachelor of Science degree majoring in Data Science at the University of Melbourne. Through her previous teaching experiences, Carol effectively adapts her teaching style to each individual student’s learning needs and promotes growth through the development of critical thinking and problem-solving skills.",
      subjects: [
        ["Secondary Mathematics", numberTag],
        ["VCE Mathematics", numberTag],
      ],
      testimonial: [
        {
          name: "Rachael",
          role: "Student",
          feedback:
            "Carol asks in advance what I want to go through in class, which is very helpful as it is like following what I need. Whenever we go through a question together, she goes through each step to make sure I understand how to do them and why we do them. Whenever she notices that an answer may be incorrect, she goes through each step again, which is something that I want to do since I usually just go with whatever answer I get.",
        },
      ],
    },
    {
      name: "Catherine",
      role: "VCE Biology, VCE Chemistry",
      imageUrl: catherine,
      description:
        "Graduating with an ATAR of 99.00 from McKinnon Secondary College, Catherine is currently studying Medicine at Monash University. She not only excelled at both Chemistry and Biology, achieving raw Study Scores of 45 and 48 respectively, but also gained extensive experience interacting with and tutoring students! Catherine can efficiently tailor her teaching style to match the needs of each student as she recognises that everyone needs something different - whether it be a full overview of a particular area of knowledge, general homework help or going through specific concepts with a fine-toothed comb in Q&A sessions. Having recently completed VCE herself, Catherine will not only be able to provide comprehensive resources (notes/summaries/practice questions/exams) but also personal support and vital organisational advice as understands the stress students are under during this period of time. Beyond her studies, she is passionate about sustainability and enjoys knitting to relieve stress! She looks forward to formulating the recipe for exam confidence and success in each and every one of her students!",
      subjects: [
        ["VCE Biology", scienceTag],
        ["VCE Chemistry ", scienceTag],
      ],
    },
    {
      name: "Cathryn",
      role: "Secondary Mathematics, VCE Mathematics, VCE Physics",
      imageUrl: cathryn,
      description:
        "Cathryn is a well-rounded tutor of Mathematics and Physics who is in the progress of completing the Bachelor of Science in Mathematics and Astrophysics and Bachelor of Education (Honours) in Secondary Education at Monash University. She possesses several years of experience in mainstream and special schools and has also presented in Physics at the Monash Virtual School and the Monash Scholars Program for secondary school students. Moreover, Cathryn currently serves as President of the Society of Physics, Astro and Mathematics, and member of the Science Future Leadership, Science Employability, and Education Ambassador programs.",
      subjects: [
        ["Secondary Mathematics", numberTag],
        ["VCE Mathematics", numberTag],
        ["VCE Physics", numberTag],
      ],
    },
    {
      name: "Charmaine",
      role: "Scholarship and Entrance Test Preparation, Secondary English, Secondary Mathematics, VCE English, VCE English Literature",
      imageUrl: charmaine,
      description:
        "Charmaine possesses prior teaching experience at a highly regarded tutoring institution. Outside tutoring, she is currently completing her Arts and Law double degree at Monash University and has received an Achieving Potential Scholarship for Distinction alongside her course. Charmaine has a strong track record in high academic achievement. During her VCE years, she participated in the Principal’s Scholars Program and the VCE English High Achievement Program, and was awarded the Gain Wisdom Merit, Academic Excellence, Australian Mathematics Competition High Achievement Award, and First Place in the State-wide Mathematics Game Competition. In addition to her academic achievements and teaching experience, Charmaine is also highly skilled in communication and is able to adapt to the learning style of each individual student. Her positive attitude and passion for learning create a comfortable and engaging environment for her students, allowing them to thrive and reach their full potential. Charmaine furthers her passion for English through reading in her spare time, and enjoys trying new recipes in cooking and baking. This impressive track record of academic success and dedication to learning has prepared Charmaine well for her role as a tutor. Her experience in teaching, combined with her own passion for learning, make her an ideal candidate for helping students achieve their goals and succeed in all areas of their lives.",
      subjects: [
        ["Scholarship and Entrance Test Preparation", otherTag],
        ["Secondary English", languageTag],
        ["Secondary Mathematics", numberTag],
        ["VCE English", languageTag],
        ["VCE English Literature", languageTag],
      ],
    },
    // {
    //   name: "Christina",
    //   role: "Primary and Lower Secondary English, Primary and Secondary Mathematics",
    //   imageUrl: christinaPei,
    //   description:
    //     "Christina possesses years of experience as a tutor and was at one stage teaching students from ages 3 to 18 English and Mathematics at a highly regarded tutoring institution. As such, she is well positioned in helping students with their conceptual understanding and fundamental skills in both key learning areas. Beyond tutoring, she is currently completing her postgraduate degree in Law, the Juris Doctor, at the Melbourne Law School of the University of Melbourne.",
    //   subjects: [
    //     ["Primary and Lower Secondary English", languageTag],
    //     ["Primary and Secondary Mathematics", numberTag],
    //   ],
    // },
    // {
    //   name: "Cynthia",
    //   role: "Primary and Secondary English, Primary and Lower Secondary Mathematics, Lower Secondary Science, VCE Biology, VCE Chemistry",
    //   imageUrl: cynthiaNguyen,
    //   description:
    //     "Cynthia is the Assistant Director, People and Culture Leader, and Curriculum and Pedagogical Mentor at TutorWo. Her niche in teaching is her natural ability to engage students with lessons that are creative, entertaining, interactive, and stimulating. Cynthia possesses a strong passion in STEM (Science, Technology, Engineering and Mathematics) education. As such, she has previously organised engineering outreach programs at primary and secondary schools. Cynthia is also specialised in mentoring students with high learning potential and preparing them for scholarship and selective entry secondary school examinations. She is able to draw from her own previous experiences as a teaching assistant at a specialist organisation in gifted and talented education, coupled with her own student days back at Mac.Robertson Girls' High School, an academically selective secondary school in Melbourne. While in the process of completing her Bachelor of Primary and Secondary Education (Honours) degree at Monash University, she is also a staff member at the university. Cynthia is presently one of the heads of department within the Faculty of Education, serving as the Senior Student Engagement Officer.",
    //   subjects: [
    //     ["Primary and Secondary English", languageTag],
    //     ["Primary and Lower Secondary Mathematics", numberTag],
    //     ["Lower Secondary Science", scienceTag],
    //     ["VCE Biology", scienceTag],
    //     ["VCE Chemistry", scienceTag],
    //   ],
    // },
    // {
    //   name: "Emi",
    //   role: "Secondary Science, VCE Biology",
    //   imageUrl: emiMcRae,
    //   description:
    //     "Emi is currently a Biology and General Science Teacher at the selective-entry Melbourne High School. She is also a contributing writer of textbook questions for Oxford University Press’ online learning platform. Emi graduated with First Class Honours from the University of Melbourne for both Master of Teaching (Secondary) and Bachelor of Science (Honours) degrees. The latter consisted of studies in the fields of Biotechnology and Genetics, as well as Biomedical research. Pre-university, Emi was the DUX of her school. She graduated from the Victorian Certificate of Education (VCE) with an ATAR of 99.45.",
    //   subjects: [
    //     ["Secondary Science", scienceTag],
    //     ["VCE Biology", scienceTag],
    //   ],
    // },
    {
      name: "Diya",
      role: "Secondary English, Secondary Mathematics, Secondary Science, VCE Biology, VCE Psychology ",
      imageUrl: diya,
      description:
        "Graduating from MacRobertson Girls High School, Diya is currently completing the Bachelor of Biomedical Science at Monash University. She has successfully sat and aced the selective school entrance examination, and further has extensive knowledge in VCE Biology and Psychology as well as tips and tricks for examination success. She caters her teachings to the student through strengths and weaknesses and has a strong focus on strategy when it comes to answering questions. Having completed multiple Mathematics and Science competitions back in her secondary school days to a high degree, and further exploring her passion in public speaking, Diya has an in-depth understanding of Mathematics, English and Science in the secondary years, which she can explain in various methods to students, to enhance learning.",
      subjects: [
        ["Secondary English", languageTag],
        ["Secondary Mathematics", numberTag],
        ["Secondary Science", scienceTag],
        ["VCE Biology", scienceTag],
        ["VCE Psychology", scienceTag],
      ],
      testimonial: [
        {
          name: "Grace",
          role: "",
          feedback:
            "Diya has been incredibly helpful and has definitely allowed me to gain a greater understanding of the content. She has explained everything clearly and her use of diagrams and writing have been very helpful for me. She is very patient and kind, and has made tutoring feel approachable.",
        },
        {
          name: "Mirella",
          role: "",
          feedback:
            "Diya is excellent at summarising for students to help them revise. She goes beyond the tutoring and extends her assistance to include helpful exam /test tips. Diya also has a caring and positive attitude towards her students. I would highly recommend her.",
        },
      ],
    },
    // {
    //   name: "Fiona",
    //   role: "Secondary Mathematics, VCE Mathematics",
    //   imageUrl: null,
    //   description:
    //     "Fiona is currently a Mathematics and Science Teacher at the high-performing Glen Waverley Secondary College, and previously Balwyn High School, another highly regarded government school in Victoria. She was previously a Numeracy Tutor under the Victorian Government's Tutor Learning Initiative at Narre Warren South P-12 College where she played instrumental roles in bridging gaps in students' foundational numeracy and mathematical skills. Fiona holds a Bachelor of Science and Bachelor of Education double degree from Monash University. ",
    //   subjects: [
    //     ["Secondary Mathematics", numberTag],
    //     ["VCE Mathematics", numberTag],
    //   ],
    // },
    {
      name: "Fiona",
      role: "Primary English, Primary Mathematics",
      imageUrl: fiona2,
      description:
        "Fiona is currently teaching at one of the high-performing government schools in Victoria (top 10 based on 2022 NAPLAN results). She has a proven track record in using highly effective teaching strategies to lift students' performance in literacy and numeracy. Fiona has recently been trained in the approach of Multisensory Structured Language; the focus in building strong foundations in students' spelling which correlates to their reading and writing ability. She is well regarded as a highly enthusiastic and passionate educator who possesses talent in building excellent rapport and relationships with her learners. ",
      subjects: [
        ["Primary English", languageTag],
        ["Primary Mathematics", numberTag],
      ],
    },
    {
      name: "Helene",
      role: "Primary and Secondary English | Primary and Secondary Mathematics | VCE Chemistry | VCE Mathematics |Victorian Selective Entry High School Examination Preparation",
      imageUrl: helene,
      description: `Helene is in her final Semester of the Bachelor of Education (Honours) in Secondary Education and Bachelor of Science, majoring in Mathematics, and minoring in Chemistry. Having graduated from the Mac.Robertson Girls’ High School with an ATAR of 95+, she was awarded the Vice-Chancellor’s Scholarship for Excellence from Monash University, and also received a Dean’s award during her degree. Helene’s philosophy of teaching centres around the student. Having tutored 100s of students from Prep to Year 12, Helene can tailor her teaching style according to the student’s needs, allowing her students to reach their full potential and achieve their personal learning goals. Although learning the content is important, Helene also prioritises the student’s well-being and values the importance of developing skills like critical and creative thinking. Beyond teaching, she is passionate about music, having been involved in orchestras all throughout high school and currently volunteering as a pianist for her local community choir. Helene looks forward to inspiring the next generation of leaders to become lifelong learners!`,
      subjects: [
        ["Primary and Secondary English", languageTag],
        ["Primary and Secondary Mathematics", numberTag],
        ["VCE Chemistry", scienceTag],
        ["VCE Mathematics", numberTag],
        [
          "Victorian Selective Entry High School Examination Preparation",
          otherTag,
        ],
      ],
    },
    // {
    //   name: "Jade",
    //   role: "Secondary English, VCE English, VCE English as an Additional Language (EAL)",
    //   imageUrl: jade,
    //   description:
    //     "Jade is a committed, dedicated, and experienced tutor of English and English as an Additional Language/ Dialect (EAL/D), including at a Victorian Certificate of Education (VCE) level. She holds a degree in Psychology from the University of Melbourne and has completed a Master's degree in Social Work. Jade has worked extensively with youth across a wide range of other public and private settings, including in the field of education. In all her roles, she has a strong track record in building strong rapport and relationships with all learners. Her approach is to break down information into chunks that are much more easily digestible, and to find an angle of fun and excitement to English. In addition to tutoring, Jade is an excellent source of inspiration for students who have post-VCE, tertiary studies in sight, as she also provides mentoring for students applying for places at universities, including Special Entry Access Scheme (SEAS) applications. Her hobbies include painting and also reading, so she is very likely to give some good recommendations for novels as well! ",
    //   subjects: [
    //     ["Secondary English", languageTag],
    //     ["VCE English", languageTag],
    //     ["VCE English as an Additional Language (EAL)", languageTag],
    //   ],
    // },
    // {
    //   name: "Judee",
    //   role: "Primary and Secondary English, VCE English",
    //   imageUrl: judeeMadoosoodun,
    //   description:
    //     "Judee Madoosoodun is well loved as a tutor by her students. She is a high achieving individual who is currently completing a Bachelor of Arts (Literature and Visual Arts) and Master of Teaching with a high distinction average. She previously graduated VCE with a Study Score of 40+ in English.",
    //   subjects: [
    //     ["Primary and Secondary English", languageTag],
    //     ["VCE English", languageTag],
    //   ],
    // },
    // {
    //   name: "Julia",
    //   role: "Primary and Secondary English, VCE English, Primary and Lower Secondary Mathematics",
    //   imageUrl: juliaHo,
    //   description:
    //     "Julia is a highly relational tutor, and this has been reflected through the positive rapport and relationships she has developed with all her students. She is currently completing a Law degree at the Royal Melbourne Institute of Technology (RMIT) University and plays an active role in the organisation, Legal Action for Afghanistan. During Julia’s pre-university years, she served as Social Justice Captain and a member of the Student Representative Council (SRC). She also participated in the Debaters Association of Victoria (DAV) Schools Competition and was awarded the Mark Dreyfus Leadership Award.",
    //   subjects: [
    //     ["Primary and Secondary English", languageTag],
    //     ["VCE English", languageTag],
    //     ["Primary and Lower Secondary Mathematics", numberTag],
    //   ],
    // },
    {
      name: "Justine",
      role: "Primary and Secondary English, VCE English",
      imageUrl: justine,
      description:
        "Justine graduated as a DUX with a perfect study score of 50 in English and a 97.70 ATAR. She is an experienced English tutor and will guide students in the right direction with effective feedback and clear explanations. Justine understands the subjectivity of English and exactly what teachers look for, hence she will cater to her students’ unique abilities to mould and strengthen them in the best way. She will stimulate her students' analytical, creative and abstract thinking through discussions, allowing them to unleash their intellectual abilities to the fullest and seamlessly transcribe their thoughts into appealing sentences, giving them the merit they deserve. She is currently completing a Bachelor's degree in Computer Science at Monash University and is the recipient of the Monash University Mentor Scholarship and the Motorola Solutions Leader Scholarship.",
      subjects: [
        ["Primary and Secondary English", languageTag],
        ["VCE English", languageTag],
      ],
    },
    // {
    //   name: "Kylie",
    //   role: "Secondary English, VCE English, VCE English Literature",
    //   imageUrl: kylieTan,
    //   description:
    //     "Kylie Tan who also has been tutoring Year 7 - VCE students for a number of years, including English and Literature. She is skilled in preparing students for VCE examinations, refining writing skills and literary analysis skills to further strengthen their confidence and academic performance. Kylie holds a Bachelor of Science and is currently completing the Juris Doctor (law degree) at the Monash University Law Chambers. Kylie has participated and continues to be an active participant in many speech and leadership competitions, including her successful selection to compete as part of the Monash delegation to the National Model United Nations Competition in New York on two occasions, representing the country of Luxembourg in the United Nations Industrial Development Organisation (UNIDO) in 2020 and representing the United States of America in the United Nations Environment Assembly (UNEA) in 2021.",
    //   subjects: [
    //     ["Secondary English", languageTag],
    //     ["VCE English", languageTag],
    //     ["VCE English Literature", languageTag],
    //   ],
    // },
    // {
    //   name: "Lily",
    //   role: "Primary and Secondary Mathematics, VCE Mathematics, VCE Accounting, English as an Additional Language (EAL)",
    //   imageUrl: lilyFei,
    //   description:
    //     "Lily is a high achieving individual who is currently a Mathematics Teacher at a top-performing government secondary school in Melbourne’s east, which is also her alma mater where she completed her Victorian Certificate of Education (VCE) and achieved an ATAR of 98.95. As a student, Lily participated in many academic and non-academic competitions. Most notably, she attained High Distinction Awards in the Australian Mathematics Competition (AMC) and the UNSW Global Mathematics Competition. Lily graduated from Monash University with the Bachelor of Commerce and Bachelor of Education (Secondary). She was one of the recipients of the High Achiever Award presented by the Faculty of Education at the university. ",
    //   subjects: [
    //     ["Primary and Secondary Mathematics", numberTag],
    //     ["VCE Mathematics", numberTag],
    //     ["VCE Accounting", numberTag],
    //     ["English as an Additional Language (EAL)", languageTag],
    //   ],
    // },
    // {
    //   name: "Lorraine",
    //   role: "Primary and Secondary Mathematics, VCE Chemistry, VCE Mathematics, VCE Physics",
    //   imageUrl: lorraine,
    //   description:
    //     "Lorraine is a science undergraduate with strong professional knowledge, understanding and skills in the fields of mathematic and physiology. She is proficient in advanced mathematics, including calculus, mathematical statistics, and linear algebra. Lorraine possesses strong logical reasoning and analysis skills and skillset in using mathematic application software. Her research was centred on human body function and disease development. Lorraine also has a strong professional working knowledge of human neuroscience and endocrine control systems.  ",
    //   subjects: [
    //     ["Primary and Secondary Mathematics", numberTag],
    //     ["VCE Chemistry", scienceTag],
    //     ["VCE Mathematics", numberTag],
    //     ["VCE Physics", numberTag],
    //   ],
    // },
    {
      name: "Matthew",
      role: "Primary and Secondary English, VCE English, VCE English as an Additional Language (EAL)",
      imageUrl: matthew,
      description:
        "Matthew is a high-performing English and English as an Additional Language (EAL) Teacher with a wealth of experience of nearly 20 years in some of Australia’s finest private schools. He presently teaches at Ivanhoe Grammar School where a high proportion of students in VCE English classes achieve study scores of 40 and above year after year. Matthew was previously the Head of English at The Rockhampton Grammar School and the Head of Boys Boarding at the well-regarded Ballarat Clarendon College. He has a double Master’s degree in Education and Arts, and possesses specific expertise in working with boys to achieve strong academic results.",
      subjects: [
        ["Primary and Secondary English", languageTag],
        ["VCE English", languageTag],
        ["VCE English as an Additional Language (EAL)", languageTag],
      ],
    },
    // {
    //   name: "Omar",
    //   role: "Secondary English, VCE English, VCE Business Management, VCE History, VCE Legal Studies, VCE Revolutions",
    //   imageUrl: omar,
    //   description:
    //     "Omar is a highly passionate and experienced tutor who is committed to understanding, mentoring and growing his students. His skillset includes simplifying and explaining complex topics across a wide range of humanities subjects in the secondary years, including English, Legal Studies, History, and Business Management. Omar's experience in tutoring began with his classmates at school, organising afternoon sessions to prepare them for exams. He then volunteered his tutoring services at the Fawkner Community House, where he helped disadvantaged students with their homework. Omar graduated top of all his classes with the third-highest ATAR at his school. He is now in the final year of his Bachelor of Law degree at Royal Melbourne Institute of Technology (RMIT) University. In the course of completing his degree, he was elected by his peers as the Executive Treasurer at RMIT Law Students' Society. Omar has completed a vast amount of work in the legal sector, completing work for Legal Action for Afghanistan, Refugee Legal Centre and is now working full-time at Bardo Lawyers. Using his experience as a law practitioner, he lends his emotional intelligence, listening and articulation skills to teach students with potential the subjects in Humanities which he dominated whilst he was in school. Additionally, his legal experience gives him an edge in teaching Legal Studies and providing his students with in-depth understanding of the legal system! Omar aims to be one of our best and most capable tutors, not only by teaching his students, but he mentors and prepares them for their future in education long past high School.",
    //   subjects: [
    //     ["Secondary English", languageTag],
    //     ["VCE English", languageTag],
    //     ["VCE Business Management", numberTag],
    //     ["VCE History", otherTag],
    //     ["VCE Legal Studies", otherTag],
    //     ["VCE Revolutions", otherTag],
    //   ],
    //   testimonial: [
    //     {
    //       name: "Alissa",
    //       role: "Student",
    //       feedback:
    //         "Omar’s enthusiasm and energy set him apart from other tutors. Having a tutor like him who is attentive and engaging makes it easier to focus on work. Omar has assisted me by pointing out the flaws in my work and thought process, allowing me to re-evaluate and reflect on why I had made that mistake. He encourages me to voice my opinions, which has given me the motivation to strive for success. ",
    //     },
    //   ],
    // },
    {
      name: "Pakhi",
      role: "Secondary English, VCE English, VCE English Literature, Scholarship and Entrance Test Preparation, Victorian Selective Entry High School Examination Preparation",
      imageUrl: parkhi,
      description:
        "Pakhi graduated from the Mac. Robertson Girls' High School in 2022 with an ATAR of 97+ with Study Scores in the top 2 percent of the State in VCE Literature and Philosophy and within the top 9 percent in Psychology, Global Politics, Methods and French. Her achievement in Philosophy was recognised as the recipient of The Philosophy Award. Pakhi efficiently tailors her teaching style to match the needs of every student, ensuring tangible growth across all subject areas. Having recently completed VCE, Pakhi is adept at formulating and providing comprehensive resources that will unlock a student's full potential and provide them with a vital skill set to succeed in VCE Exams, scholarship and entrance tests, and beyond. Pakhi's approach breaks down content in an accessible manner, allowing students to gain content literacy, and ensuring fluency in their expression. Her aim lies in preparing students for all types of questions and using the set exam structure to their advantage. Beyond tutoring, Pakhi is pursuing an Arts/Law (Honours) degree at Monash University.",
      subjects: [
        ["Secondary English", languageTag],
        ["VCE English", languageTag],
        ["VCE English Literature", languageTag],
        ["Scholarship and Entrance Test Preparation", otherTag],
        [
          "Victorian Selective Entry High School Examination Preparation",
          otherTag,
        ],
      ],
      testimonial: [
        {
          name: "Rachael",
          role: "Student",
          feedback:
            "I really like how she uses PowerPoints when teaching me, which she sends through after every class. This really helps as I can use those when revising for tests/ SACs. When I was preparing for my first SAC, she was really helpful in terms of like giving me tips and being available when I have any questions prior to the SAC.",
        },
      ],
    },
    // {
    //   name: "Priyenka",
    //   role: "VCE Psychology",
    //   imageUrl: priyenka,
    //   description:
    //     "Priyenka graduated from the Victorian Certificate of Education (VCE) at Mac.Robertson Girls’ High School with an ATAR of 98+ and achieved a raw study score of 50 in VCE Psychology. Her achievement in the subject was also recognised as the recipient of the VCE Premier’s Award. Psychology has an array of nuances regarding understanding content and mark allocation in examinations. Priyenka’s aim as the tutor is to break down content in a manner which is easily digestible, provide guidance on how to answer questions in a way which hits every point necessary, and use the exam structure to the student’s advantage. Outside tutoring, she is currently completing her Arts and Law degrees at the Australian National University.",
    //   subjects: [["VCE Psychology", scienceTag]],
    // },
    {
      name: "Rachel",
      role: "Primary English, Primary Mathematics",
      imageUrl: rachel,
      description:
        "Rachel is a registered Primary School Teacher, currently working as a Casual Relief Teacher. She offers students from Prep to Grade 6 English and Math tutoring services. Having worked in a range of different schools with students of varying needs and abilities, Rachel recognises that each student learns differently and therefore tailors her teaching to meet the needs of each student. Also, she utilises teaching resources and implements appropriate strategies to support each student in reaching their potential. Beyond teaching, Rachel enjoys music and plays the piano, and following her recent visit to Hawaii, she has now taken up learning the ukulele.",
      subjects: [
        ["Primary English", languageTag],
        ["Primary Mathematics", numberTag],
      ],
    },
    {
      name: "Ruby",
      role: "VCE Biology, VCE Chemistry",
      imageUrl: ruby,
      description:
        "Ruby currently teaches Science at a sought-after secondary school in Melbourne's southeast. She graduated from Monash University with a Bachelor of Biomedical Science and a Master of Teaching (Secondary). Ruby's teaching methods are Biology and General Science. She takes great pride in empowering her students to realise their learning potential through her provision of high-quality teaching and learning programs. Ruby’s passion in teaching and providing equal access to education for all students regardless of their socioeconomic background saw her volunteering her time at not-for-profit organisations, offering educational support to disadvantaged secondary students. ",
      subjects: [
        ["VCE Biology", scienceTag],
        ["VCE Chemistry", scienceTag],
      ],
    },
    // {
    //   name: "Ruochen",
    //   role: "Secondary Mathematics, VCE Mathematics",
    //   imageUrl: ruoChen,
    //   description:
    //     "Ruochen is currently the Mathematics and Science Teacher at Bacchus Marsh Grammar School. Outside school, she also prepares students for the Mathematical Association of America's American Mathematics Competition (MAA AMC) and at the same time, promotes the development and enhancement of algorithmic thinking and problem-solving skills. Ruochen was also the conference host at the Mathematical Association of Victoria in 2020.",
    //   subjects: [
    //     ["Secondary Mathematics", numberTag],
    //     ["VCE Mathematics", numberTag],
    //   ],
    // },
    {
      name: "Sammi",
      role: "Primary and Secondary Mathematics, VCE Mathematics, VCE Physics",
      imageUrl: sammi,
      description:
        "Sammi is a qualified teacher in the primary and secondary years. She graduated from Monash University with the Bachelor of Science and the Bachelor of Education (Honours) in Primary Education. Sammi then proceeded onto completing the Master of Teaching (Secondary) at The University of Melbourne with her methods being Physics and Mathematics. She currently teaches Mathematics and Science at a highly sought-after girls' school in Melbourne's southeast. ",
      subjects: [
        ["Primary and Secondary Mathematics", numberTag],
        ["VCE Mathematics", numberTag],
        ["VCE Physics", numberTag],
      ],
    },
    // {
    //   name: "Stephanie",
    //   role: "Secondary Mathematics, VCE English Language, VCE Mathematics",
    //   imageUrl: stephanieKervin,
    //   description:
    //     "Stephanie Kervin is a high-achieving tutor of VCE English Language, VCE General and Further Mathematics and Year 10 Mathematics. They have consistently received top ratings in student feedback for their ability to explain complex and difficult-to-grasp concepts thoroughly and logically, building right up from the foundations where necessary. Stephanie also possesses experience in producing high-quality learning resources for VCE, such as examination-style questions accompanied with worked solutions. They are currently completing a Bachelor of Arts with a major in Linguistics and English Language at Monash University, maintaining a High Distinction average across their current course. Stephanie previously graduated with the VCE Baccalaureate and attained an ATAR of 99.35. ",
    //   subjects: [
    //     ["Secondary Mathematics", numberTag],
    //     ["VCE Mathematics", numberTag],
    //     ["VCE English Language", languageTag],
    //   ],
    // },
    {
      name: "Sucharita",
      role: "Primary English, Primary Mathematics",
      imageUrl: sucharita,
      description:
        "Sucharita is a highly experienced primary school teacher who has taught across a range of settings that include state schools in metropolitan cities and regional areas to independent schools and international schools across the globe. Her global experience and Master's qualifications in Primary Teaching and International Education from the University of Melbourne have equipped her to personalise, differentiate and integrate cultural experiences into her lessons. She values anecdotal assessment data and tailors each lesson to suits the student's needs. Sucharita is enthusiastic about using a variety of metacognitive strategies in her lessons to instil a sense of self-worth in her students. She constantly looks for new and innovative ways to incorporate open-ended investigative tasks, to let students think critically and creatively.",
      subjects: [
        ["Primary English", languageTag],
        ["Primary Mathematics", numberTag],
      ],
    },
    {
      name: "Sylvia",
      role: "Primary English, Secondary English, VCE Biology, VCE Psychology",
      imageUrl: sylvia,
      description:
        "Sylvia is a high achieving and well-rounded individual. She is currently completing a Degree in Medicine at Bond University. Sylvia previously graduated with the International Baccalaureate Diploma Programme from Wesley College and has for the past number of years tutored primary, middle, VCE, IBDP, and tertiary students in many subject areas. She has also taught at TAFEs, and she has a strong track record in her ability to relate with students, explain complicated concepts in an easy-to-understand manner, as for being an effective communicator.",
      subjects: [
        ["Primary English", languageTag],
        ["Secondary English", languageTag],
        ["VCE Biology", scienceTag],
        ["VCE Psychology", scienceTag],
      ],
    },
    {
      name: "Tina",
      role: "Primary English, Primary Mathematics",
      imageUrl: tina,
      description:
        "Tina with a Bachelor of Primary Education and Bachelor of Arts from Monash University and is currently working as a primary school teacher. Working with young children is Tina's passion and she finds it extremely rewarding to make a positive difference to young people's lives. Outside of teaching and tutoring, Tina enjoys the art of dance and learning the Japanese language and culture. During Year 12, she received a VCE Premier's Award for achieving a perfect study score of 50 in VCE VET Dance. Tina also received a Prize for the best second year student in Japanese Studies in 2021 and achieved a High Distinction average with a Grade Point Average of 3.76 out of 4 throughout her Bachelor's program at Monash University.",
      subjects: [
        ["Primary English", languageTag],
        ["Primary Mathematics", numberTag],
      ],
    },
    {
      name: "Tristina",
      role: "Primary and Secondary English, Primary and Secondary Mathematics, VCE Biology, VCE English, VCE Mathematics, VCE Psychology",
      imageUrl: tristina,
      description:
        "Tristina is a highly engaging, enthusiastic and passionate tutor who highly regarded for going above and beyond for her students. She is currently completing the Bachelor of Science at the University of Melbourne. Tristina graduated from the Victorian Certificate of Education (VCE) with an ATAR of 95.70 and an impressive study score of 46 for English. As a tutor, she strives to build rapport with her students to understand their needs and navigate ways to employ their skills confidently. Tristina's dedication is evident in her ample and insightful feedback on her students' work. She also formulates notes and gathers resources that best suit her students' academic goals. Outside Tristina's studies, she enjoys spending time with her pets and exploring new skills such as crocheting.",
      subjects: [
        ["Primary and Secondary English", languageTag],
        ["Primary and Secondary Mathematics", numberTag],
        ["VCE Biology", scienceTag],
        ["VCE English", languageTag],
        ["VCE Mathematics", numberTag],
        ["VCE Psychology", scienceTag],
      ],
      testimonial: [
        {
          name: "Tiffany",
          role: "Student",
          feedback:
            "Tristina has stood out to me as a tutor as she has provided unlimited resources and given feedback that is specific and detailed to improving the quality of my work.",
        },
      ],
    },
    {
      name: "Venus",
      role: "VCE Business Management, VCE Economics",
      imageUrl: venus,
      description:
        "Venus is an experienced teacher of Economics and Business Management. She holds a degree in Economics as well as a Master of Teaching in Secondary Education from the University of Melbourne. Venus is skilled in assisting students with course content and developing their problem-solving and critical thinking skills. Her strong cross-disciplinary approach to teaching positions her to cultivate students’ interest in commerce. Venus aims to make this learning area as relevant to the students by incorporating it with real work knowledge and current affairs.",
      subjects: [
        ["VCE Business Management", numberTag],
        ["VCE Economics", numberTag],
      ],
    },
    {
      name: "Winnie",
      role: "Primary and Lower Secondary English, Primary and Secondary Mathematics",
      imageUrl: winnie,
      description:
        "Winnie is an experienced tutor and has taught primary and secondary-aged students for a number of years. She is currently completing a Bachelor's degree in Commerce, majoring in Economics and Finance, as well as a Diploma in Computing (Information technology) at the University of Melbourne. Winnie's aim as the tutor is to break down content in a manner that is easily digestible and entertaining, allowing the students to build a more retentive memory of knowledge. She also provides guidance on how to answer questions in a way that hits every mark and uses the examination structure to the student's advantage.",
      subjects: [
        ["Primary and Lower Secondary English", languageTag],
        ["Primary and Secondary Mathematics", numberTag],
      ],
    },
    {
      name: "Yichen",
      role: "Secondary Mathematics, VCE Mathematics, University Mathematics",
      imageUrl: yiChen,
      description:
        "Yichen possesses extensive experience in teaching senior secondary and undergraduate university students Mathematics, including the Victorian Certificate of Education (VCE). She recently graduated from University of Melbourne with a Master of Education specialising in Mathematics Education with a First Class Honours. Yichen is now working as a research, lecturer, and marking assistant at the University, focusing on student well-being and values in mathematics teaching and learning. She is the co-author for two articles on students' values and wellbeing, and also works as a researcher at the Third Wave Lab. Yichen previously completed the Bachelor of Science (Honours) in Applied Mathematics with First Class Honours previously in the University of Auckland in New Zealand, and worked their as a graduate teaching assistant for several undergraduate mathematics subjects. Yichen is passionate about individualised teaching and takes pride in her patience in finding the best learning approach for every student. She always plans her lessons to suit their individual learning needs and interests. Yichen is also highly experienced in engaging students from diverse backgrounds. In her free time, she likes to read, swim and play video games. Yichen looks forward to meeting and helping students to excel in their studies!",
      subjects: [
        ["Secondary Mathematics", numberTag],
        ["VCE Mathematics", numberTag],
        ["University Mathematics", numberTag],
      ],
    },
    {
      name: "Zoe",
      role: "Secondary English, Secondary Humanities, VCE Legal Studies, VCE Media",
      imageUrl: zoe,
      description:
        "Zoe is a high achieving, dedicated and enthusiastic English and VCE Legal Studies teacher at a secondary school in Melbourne's inner southeast. She graduated with a Bachelor of Arts and Master of Teaching from the University of Melbourne where she achieved a Weighted Average Mark (WAM) of 90+ for the latter. As part of Zoe's Arts degree, she was also awarded the prestigious and competitive New Colombo Plan Scholarship where she undertook part of her studies in Asia. Beyond the classroom, she is also a qualified netball coach and previously coached sports at an elite independent school in Melbourne's bayside suburbs. Zoe's pedagogical approach to tutoring is centred around the individual. She utilises diagnostic assessment to gain insight into her students' learning needs. She then creates highly nuanced and engaging learning sequences to aid students in achieving their learning goals. As a secondary school teacher, Zoe understands the requirements of success within a mainstream school setting and can provide insight into improving organisational skills, regulating classroom behaviour and thinking with a growth mindset. Zoe looks forward to working with students to help them reach their full potential. ",
      subjects: [
        ["Secondary English", languageTag],
        ["Secondary Humanities", otherTag],
        ["VCE Legal Studies", otherTag],
        ["VCE Media", otherTag],
      ],
    },
  ];

  /**  Subject filters **/
  let uniqueTutorToDisplay = [];
  const [tutorToDisplay, setTutorToDisplay] = useState(people);
  // Search name function
  const [query, setQuery] = useState("");
  const handleInputChange = (e) => {
    setQuery(e.target.value);
    const includeName = people.filter((tutor, index) => {
      return tutor.name.toLowerCase().includes(query.toLowerCase());
    });
    uniqueTutorToDisplay = [];
    includeName.map((tutor) => {
      if (!uniqueTutorToDisplay.includes(tutor)) {
        return uniqueTutorToDisplay.push(tutor);
      }
    });

    // if (uniqueTutorToDisplay == []) {
    //   setTutorToDisplay(people);
    // } else {
    //   setTutorToDisplay(uniqueTutorToDisplay);
    // }
    setTutorToDisplay(uniqueTutorToDisplay);
  };
  // End - Search name function
  // Filter Scrolling
  const filter = useRef();
  useEffect(() => {
    setWidth(filter.current.scrollWidth - filter.current.offsetWidth);
  }, []);
  const [width, setWidth] = useState(0);
  // End - Filter Scrolling

  // Display Filtered tutors after clicked

  const filterSubjects = async (tag) => {
    uniqueTutorToDisplay = [];
    people.map((tutor, index) => {
      tutor.subjects.filter((subject, filterIndex) => {
        const filteredTutors = subject[0].toLowerCase().includes(tag);
        if (filteredTutors) {
          if (!uniqueTutorToDisplay.includes(tutor)) {
            return uniqueTutorToDisplay.push(tutor);
          }
        }
      });
    });
    setTutorToDisplay(uniqueTutorToDisplay);
  };
  // End - Display Filtered tutors after clicked
  /**  END - Subject filters **/

  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-[89rem] sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          {/* Shapes decoration */}
          {/* <CircleGrid
            position="absolute"
            left="0"
            top="57rem"
            color="#863d90"
            size="80px"
            zIndex={0}
          />
          <CircleGrid
            position="absolute"
            right="0"
            bottom="13rem"
            color="#863d90"
            className="hidden sm:inline"
            size="80px"
            zIndex={0}
          />
          <Donut
            color="#863d90"
            top="54rem"
            left="1rem"
            size="4rem"
            className="hidden lg:inline lg:animate-shape"
            width={["0.5rem", "1rem", "1rem", "1rem"]}
            zIndex={0}
          />
          <Donut
            color="#863d90"
            bottom="22rem"
            right="1rem"
            size="4rem"
            className="hidden lg:inline lg:animate-shape"
            width={["0.5rem", "1rem", "1rem", "1rem"]}
            zIndex={0}
          /> */}
          {/* BOTTOM */}
          {/* <CircleGrid
            position="absolute"
            left="0"
            top="171rem"
            color="#863d90"
            className="hidden lg:inline"
            size="80px"
            zIndex={0}
          />
          <CircleGrid
            position="absolute"
            right="0"
            top="220rem"
            color="#863d90"
            className="hidden lg:inline"
            size="80px"
            zIndex={0}
          />
          <Donut
            color="#863d90"
            top="172rem"
            left="1rem"
            size="4rem"
            className="hidden lg:inline lg:animate-shape"
            width={["0.5rem", "1rem", "1rem", "1rem"]}
            zIndex={0}
          />
          <Donut
            color="#863d90"
            top="222rem"
            right="1rem"
            size="4rem"
            className="hidden lg:inline lg:animate-shape"
            width={["0.5rem", "1rem", "1rem", "1rem"]}
            zIndex={0}
          /> */}
          {/* <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple uppercase">
              MEET OUR BEST AND BRIGHTEST TUTORS
            </h2>
            <p className="text-center text-blue text-3xl font-bold tracking-tight sm:text-4xl">
              Our Tutors
            </p>
          </div> */}
          <motion.div
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="flex flex-col justify-center gap-2">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple uppercase">
              MEET OUR BEST AND BRIGHTEST TUTORS
            </h2>
            <p className="text-center text-blue text-3xl font-bold tracking-tight sm:text-4xl">
              Our Tutors
            </p>
          </motion.div>
          {/* SEARCH AND FILTER */}
          <div className="flex flex-col justify-center items-center overflow-hidden">
            {/* Search bar + Heading */}
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1, delay: 0.2 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row justify-center items-center gap-5 w-full">
              <h1 className="text-purple font-bold">Search for Tutor's name</h1>
              {/* Search bar */}
              <div className="relative text-gray-500 mb-10 mr-56 flex justify-center items-center">
                <MagnifyingGlassIcon className="w-5 h-5 absolute left-2 top-0 mt-[0.6rem] text-purple z-50" />
                <input
                  className="absolute top-0 ml-64 border-2 border-gray-300 bg-white h-10 px-10 pr-16 rounded-lg text-sm focus:outline-none"
                  type="search"
                  name="search"
                  placeholder="Search"
                  value={query}
                  onChange={handleInputChange}
                />
              </div>
              {/* End - Search bar */}
            </motion.div>
            {/* Search bar + Heading */}
            {/* Filter */}
            {/* <div className="flex flex-wrap justify-center items-center gap-5 mt-5"> */}
            {/* <h1 className="text-purple font-bold">Filter by subject</h1> */}
            {/* Subjects */}
            <motion.div
              variants={fadeRightToLeft}
              initial={"hidden"}
              whileInView={fadeRightToLeft.visible}
              transition={{ duration: 1, delay: 0.4 }}
              viewport={{ once: true }}
              ref={filter}
              drag="x"
              dragConstraints={{ right: 0, left: -width }}
              className="flex md:justify-center items-center gap-5 w-full mt-10 p-2">
              <button
                className="w-auto h-5 bg-purple rounded-full ring-2 ring-purple ring-offset-2 text-white p-5 flex items-center"
                onClick={() => setTutorToDisplay(people)}>
                ALL
              </button>

              <button
                className="w-auto h-5 bg-purple rounded-full ring-2 ring-purple ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("english")}>
                English
              </button>
              <button
                className="w-auto h-5 bg-orange rounded-full ring-2 ring-orange ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("biology")}>
                Biology
              </button>
              <button
                className="w-auto h-5 bg-orange rounded-full ring-2 ring-orange ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("chemistry")}>
                Chemistry
              </button>
              <button
                className="w-auto h-5 bg-orange rounded-full ring-2 ring-orange ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("psychology")}>
                Psychology
              </button>
              <button
                className="w-auto h-5 bg-blue rounded-full ring-2 ring-blue ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("physics")}>
                Physics
              </button>
              <button
                className="w-auto h-5 bg-blue rounded-full ring-2 ring-blue ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("math")}>
                Math
              </button>
              <button
                className="w-auto h-5 bg-yellow rounded-full ring-2 ring-yellow ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("legal")}>
                Legal
              </button>
              <button
                className="w-auto h-5 bg-yellow rounded-full ring-2 ring-yellow ring-offset-2 text-white p-5 flex items-center"
                onClick={() => filterSubjects("scholarship")}>
                Scholarship
              </button>
            </motion.div>
            {/* END - Subjects */}
            {/* </div> */}
            {/* End - Filter */}
          </div>
          {/* END - SEARCH AND FILTER */}
          <ul className="text-center space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8 ">
            {tutorToDisplay.map((person, index) => (
              <li key={person.name + index}>
                <div className="space-y-4 ">
                  {/* Tutor Image */}
                  <div className="aspect-w-3 aspect-h-2 relative  border-4 border-purple rounded-xl">
                    {person.imageUrl ? (
                      <img
                        className="object-cover h-[30rem] w-full shadow-lg rounded-lg "
                        src={person.imageUrl}
                        alt={person.name}
                      />
                    ) : (
                      <svg
                        className="object-cover h-[30rem] w-full rounded-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    )}
                  </div>
                  {/* Tutor details */}
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1 text-blue">
                      <h3>{person.name}</h3>
                      <div>
                        {person.subjects.map((subject, index) => (
                          <span
                            key={`${subject} ${index}`}
                            className={`${subject[1]}
                           border-2 border-solid mx-1 my-1 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium`}>
                            {subject[0]}
                          </span>
                        ))}
                      </div>
                    </div>
                    <button
                      data-index={index}
                      className="block w-30 py-1 px-4 mx-auto rounded-xl shadow bg-purple text-white font-medium hover:bg-yellow"
                      onClick={handleClick}>
                      View Profile
                    </button>
                  </div>
                </div>
              </li>
            ))}
            {/* MODAL */}
            {showModal && (
              <>
                <div className="fixed top-0 sm:top-32 lg:top-24 xl:top-30 2xl:top-[8%] 3xl:top-52 left-[5%] lg:left-[15%] xl:left-[25%] 2xl:left-[30%] bg-white border-2 border-purple scrollbar-thumb-grey scrollbar-thin scrollbar-cursor-pointer shadow-xl rounded-xl h-[38rem] sm:h-[32rem] lg:max-h-[120rem] 3xl:h-auto w-[90vw] mx-auto lg:w-[50vw] 2xl:w-[40vw] z-[200] overflow-y-scroll">
                  <XMarkIcon
                    className="hover:bg-yellow hover:ring-yellow w-10 h-10 float-right -translate-x-8 translate-y-8 p-2 rounded-lg text-white bg-purple ring-2 ring-offset-2 ring-purple shadow-lg cursor-pointer"
                    onClick={() => setShowModal(false)}
                  />
                  <div className="text-center mt-5 pb-5 lg:mt-8 border-b border-solid border-slate-200 rounded-t">
                    {tutorImage ? (
                      <img
                        className="m-auto w-40 h-40 mb-5 object-cover rounded-full"
                        src={tutorImage}
                        alt={tutorName}></img>
                    ) : (
                      <svg
                        className="m-auto w-40 h-40 mb-5 object-cover rounded-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    )}
                    <h3 className=" text-2xl font-semibold text-purple">
                      {tutorName}
                    </h3>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-blue text-md lg:text-lg leading-relaxed mb-8">
                      {tutorDescription}
                    </p>

                    {testimonial ? (
                      <div className="text-yellow">
                        <FormatQuoteRoundedIcon
                          className="rotate-180 my-2"
                          sx={{
                            fontSize: "5rem",
                            position: "relative",
                            marginRight: "80%",
                          }}
                        />
                        {testimonial.map((item, index) => (
                          <div
                            key={`testimonial_${index}`}
                            className="flex flex-col justify-center items-center">
                            <p
                              key={"pt-" + index}
                              className="text-base italic font-bold text-blue">
                              {item.feedback}
                            </p>
                            <div className="border-t-2 border-gray-100 my-5">
                              <p
                                key={"pn-" + index}
                                className="mt-1 text-base font-bold text-purple">
                                {item.name}
                              </p>
                              <p
                                key={"pn-" + index}
                                className=" text-base font-normal text-purple">
                                {item.role}
                              </p>
                            </div>
                          </div>
                        ))}
                        <FormatQuoteRoundedIcon
                          sx={{
                            position: "relative",
                            bottom: 50,
                            fontSize: "5rem",
                            marginLeft: "80%",
                          }}
                        />
                      </div>
                    ) : null}

                    <button
                      className="rounded-xl bg-purple text-white hover:bg-yellow font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-5 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}>
                      Close
                    </button>
                  </div>
                </div>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurTutors;
