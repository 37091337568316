import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const OurTalent = () => {
  // Heading scrolling effect
  const { scrollYProgress } = useScroll();

  const x = useTransform(scrollYProgress, [0, 1], [-150, 1200]);

  return (
    <section className="overflow-hidden">
      {/* TITLE - DISCOVER HOW WE CAN HELP */}
      <div className="relative -top-1 right-[260%] ">
        <motion.h1
          style={{ x }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          Leverage on our talent and expertise
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Leverage on our talent and expertise
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            Leverage on our talent and expertise
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Leverage on our talent and expertise
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            Leverage on our talent and expertise
          </motion.span>
        </motion.h1>
      </div>
      {/* END TITLE - DISCOVER HOW WE CAN HELP */}
    </section>
  );
};

export default OurTalent;
