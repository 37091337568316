import { Link } from "react-router-dom";
import catherine from "../../assets/tutors/Catherine.jpeg";
import justine from "../../assets/tutors/Justine.jpg";
import ruoChen from "../../assets/tutors/RuoChen.JPG";
import emi from "../../assets/tutors/Emi.jpg";
import whitney from "../../assets/tutors/Whitney.jpeg";
import cathryn from "../../assets/tutors/Cathryn.jpg";
import pakhi from "../../assets/tutors/Pakhi.jpg";
import amelia from "../../assets/tutors/Amelia.jpg";

import biology from "../../assets/MasterClasses/VCE exam revisions 2023/biology.jpg";
import mathMethod from "../../assets/MasterClasses/VCE exam revisions 2023/mathMethods.jpg";
import physics from "../../assets/MasterClasses/VCE exam revisions 2023/physics.jpg";
import english from "../../assets/MasterClasses/VCE exam revisions 2023/english.jpg";
import psychology from "../../assets/MasterClasses/VCE exam revisions 2023/psychology.jpg";

import headStartBiology from "../../assets/MasterClasses/headStartBiology.jpg";
import headStartEnglish1 from "../../assets/MasterClasses/headStartEnglish1.jpg";
import headStartEnglish from "../../assets/MasterClasses/headStartEnglish.jpg";
import headStartFurtherMath from "../../assets/MasterClasses/headStartFurtherMath.jpg";
import headStartGeneralMath from "../../assets/MasterClasses/headStartGeneralMath.jpg";
import headStartMathMethod from "../../assets/MasterClasses/headStartMathMethod.jpg";
import headStartPhysics from "../../assets/MasterClasses/headStartPhysics.jpg";
import headStartChemistry from "../../assets/MasterClasses/headStartChemistry.jpg";
import { Popover, Transition } from "@headlessui/react";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";
import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const revision = [
  {
    date: "Mondays 6.00 pm - 7.00 pm",
    time: [
      // "Session 1: 4th September 2023",
      // "Session 2: 11th September 2023",
      "Session 1: 18th September 2023",
      "Session 2: 25th September 2023",
      "Session 3: 2nd October 2023",
      "Session 4: 9th October 2023",
      "Session 5: 16th October 2023",
    ],
    // dateTwo: "Sunday, 22 January 2023",
    mode: "Online",
    investmentTotal: "AUD200.00 for the 5 sessions",
    investmentPerSession: "",
    tag: "bg-purple",
    subject: "Biology",
    imageUrl: biology,
    presenter: {
      name: "Emi",
      description:
        "Science Teacher and Faculty Coordinator at Victoria’s top selective school based on the 2022 VCE results, contributing textbook writer at the largest university press in the world",
      imageUrl: emi,
    },
  },
  {
    date: "Sundays 4.30 pm - 5.30 pm",
    time: [
      // "Session 1: 3rd September 2023",
      // "Session 2: 10th September 2023",
      "Session 1: 17th September 2023",
      "Session 2: 24th September 2023",
      "Session 3: 1st October 2023",
      "Session 4: 8th October 2023",
      "Session 5: 15th October 2023",
    ],
    // dateTwo: "Sunday, 22 January 2023",
    mode: "Online",
    investmentTotal: "AUD200.00 for the 5 sessions",
    investmentPerSession: "",
    tag: "bg-orange",
    subject: "English",
    imageUrl: english,
    presenter: {
      name: "Amelia",
      description:
        "Both top ATAR scorers of 97+, former achieved a 56/60 exam score with a perfect 20/20 for Section A in VCE English, latter attained a perfect Study Score of 50 for VCE English ",
      imageUrl: amelia,
    },
    presenterTwo: {
      name: "Justine",
      description:
        "Both top ATAR scorers of 97+, former achieved a 56/60 exam score with a perfect 20/20 for Section A in VCE English, latter attained a perfect Study Score of 50 for VCE English ",
      imageUrl: justine,
    },
  },
  {
    date: "Tuesdays 7:30 pm - 8:30 pm",
    time: [
      // "Session 1: 5th September 2023",
      // "Session 2: 12th September 2023",
      "Session 1: 19th September 2023",
      "Session 2: 26th September 2023",
      "Session 3: 3rd October 2023",
      "Session 4: 10th October 2023",
      "Session 5: 17th October 2023",
    ],
    // dateTwo: "Sunday, 22 January 2023",
    mode: "Online",
    investmentTotal: "AUD200.00 for the 5 sessions",
    investmentPerSession: "",
    tag: "bg-blue",
    subject: "Mathematical Methods",
    imageUrl: mathMethod,
    presenter: {
      name: "Whitney",
      description:
        "Mathematics Teacher at Victoria’s top selective school based on the 2022 VCE results",
      imageUrl: whitney,
    },
  },
  {
    date: "Sundays 10.00 am - 11.00 am",
    time: [
      // "Session 1: 3rd September 2023",
      // "Session 2: 10th September 2023",
      "Session 1: 17th September 2023",
      "Session 2: 24th September 2023",
      "Session 3: 1st October 2023",
      "Session 4: 8th October 2023",
      "Session 5: 15th October 2023",
    ],
    // dateTwo: "Sunday, 22 January 2023",
    mode: "Online",
    investmentTotal: "AUD200.00 for the 5 sessions",
    investmentPerSession: "",
    tag: "bg-blue",
    subject: "Physics",
    imageUrl: physics,
    presenter: {
      name: "Cathryn",
      description:
        "Mathematics and Science Teacher at one of Victoria’s top 10 government schools based on the 2022 VCE results, former presenter at the Monash Scholars Program and the Monash Virtual School ",
      imageUrl: cathryn,
    },
  },
  {
    date: "Friday 7.30 pm – 8.30 pm",
    time: [
      // "Session 1: 1st September 2023",
      // "Session 2: 8th September 2023",
      "Session 1: 15th September 2023",
      "Session 2: 22nd September 2023",
      "Session 3: 29th September 2023",
      "Session 4: 6th October 2023",
      "Session 5: 13th October 2023",
    ],
    // dateTwo: "Sunday, 22 January 2023",
    mode: "Online",
    investmentTotal: "AUD200.00 for the 5 sessions",
    investmentPerSession: "",
    tag: "bg-purple",
    subject: "Psychology",
    imageUrl: psychology,
    presenter: {
      name: "Pakhi",
      description:
        "Top ATAR scorer of 97+ and achieved a Study Score in the top 9 percent for VCE Psychology",
      imageUrl: pakhi,
    },
  },
];

// const headStart = [
//   {
//     date: "Sunday, 15 January 2023",
//     time: [
//       "Session 1: 4:00 pm – 5:30 pm",
//       "Session 2: 6:00 pm – 7:30 pm",
//       "Session 3: 4:00 pm – 5:30 pm",
//       "Session 4: 6:00 pm – 7:30 pm",
//     ],
//     dateTwo: "Sunday, 22 January 2023",
//     mode: "Online",
//     investmentTotal: "AUD180.00 for the 4 sessions",
//     investmentPerSession: "",
//     tag: "bg-purple",
//     subject: "Chemistry Unit 3",
//     imageUrl: headStartChemistry,
//     presenter: {
//       name: "Catherine",
//       description:
//         "Graduated with an ATAR of 99.00, Catherine is currently studying Medicine at Monash University. She not only excelled at both Chemistry and Biology, achieving raw study scores of 45 and 48 respectively, but also gained extensive experience interacting with and tutoring students! Catherine can efficiently tailor her teaching style to match the needs of each student as she recognises that everyone needs something different - whether it be a full overview of a particular area of knowledge, general homework help or going through specific concepts with a fine-toothed comb in Q&A sessions. Having recently completed VCE herself, Catherine will not only be able to provide comprehensive resources (notes/summaries/practice questions/exams) but also personal support and vital organisational advice as understands the stress students are under during this period of time. Beyond her studies, she is passionate about sustainability and enjoys knitting to relieve stress! She looks forward to formulating the recipe for exam confidence and success in each and every one of her students!  ",
//       imageUrl: catherine,
//     },
//   },
//   {
//     date: "Saturday, 21 January 2023",
//     time: [
//       "Session 1: 10:00 am – 11:00 am ",
//       "Session 2: 11:30 am – 12:30 pm",
//       "Session 3: 1:00 pm – 2:00 pm",
//     ],
//     mode: "Online",
//     investmentTotal: "AUD120.00",
//     investmentPerSession: "",
//     tag: "bg-orange",
//     subject: "English Unit 3",
//     imageUrl: headStartEnglish1,
//     presenter: {
//       name: "Justine",
//       description:
//         "Justine graduated as a DUX with a perfect study score of 50 in English and a 97.70 ATAR. She is an experienced English tutor and will guide you in the right direction with effective feedback and clear explanations. She understands the subjectivity of English and exactly what teachers look for, hence she will cater to your unique abilities to mould and strengthen them in the best way. Justine will stimulate your analytical, creative and abstract thinking through discussions, allowing you to unleash your intellectual abilities to the fullest and seamlessly transcribe your thoughts into appealing sentences, giving you the merit you deserve. She is currently a Biomedicine student at Monash University with a scholarship and was also elected as a Faculty Student Representative.",
//       imageUrl: justine,
//     },
//   },
//   {
//     date: "Tuesday, 17 January 2023",
//     time: [
//       "Session 1: 10:00 am – 11:30 am",
//       "Session 2: 12:00 pm – 1:30 pm",
//       "Session 1: 10:00 am – 11:30 am",
//       "Session 2: 12:00 pm – 1:30 pm",
//     ],
//     dateTwo: "Tuesday, 24 January 2023",
//     mode: "Online",
//     investmentTotal: "AUD180.00 for the 4 sessions",
//     investmentPerSession: "",
//     tag: "bg-blue",
//     subject: "General Mathematics Unit 3",
//     imageUrl: headStartGeneralMath,
//     presenter: {
//       name: "Ruochen",
//       description:
//         "Ruochen is currently the Mathematics and Science Teacher at the top-performing Bacchus Marsh Grammar School. Beyond school, she prepares students for the Mathematical Association of America’s American Mathematics Competition (MAA AMC) and at the same time, promotes the development and enhancement of algorithmic thinking and problem-solving skills. Ruochen was also the conference host at the Mathematical Association of Victoria in 2020.",
//       imageUrl: ruoChen,
//     },
//   },
//   {
//     date: "Tuesday, 17 January 2023",
//     time: [
//       "Session 1: 4:00 pm – 5:30 pm",
//       "Session 2: 6:00 pm – 7:30 pm",
//       "Session 3: 4:00 pm – 5:30 pm",
//       "Session 4: 6:00 pm – 7:30 pm",
//     ],
//     dateTwo: "Tuesday, 24 January 2023",
//     mode: "Online",
//     investmentTotal: "AUD180.00 for the 4 sessions",
//     investmentPerSession: "",
//     tag: "bg-blue",
//     subject: "Mathematical Methods Unit 3",
//     imageUrl: headStartMathMethod,
//     presenter: {
//       name: "Ruochen",
//       description:
//         "Ruochen is currently the Mathematics and Science Teacher at the top-performing Bacchus Marsh Grammar School. Beyond school, she prepares students for the Mathematical Association of America’s American Mathematics Competition (MAA AMC) and at the same time, promotes the development and enhancement of algorithmic thinking and problem-solving skills. Ruochen was also the conference host at the Mathematical Association of Victoria in 2020.",
//       imageUrl: ruoChen,
//     },
//   },
// ];

export default function MasterClasses() {
  return (
    <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
        <CircleGrid
          position="absolute"
          top="15rem"
          color="#863d90"
          size="80px"
          zIndex={0}
        />
        {/* <CircleGrid
          position="absolute"
          right="0"
          top="32rem"
          color="#863d90"
          size="80px"
          zIndex={0}
        /> */}
        {/* <Donut
          color="#863d90"
          top="17rem"
          left="1rem"
          size="4rem"
          className="hidden lg:inline lg:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        /> */}
        {/* <Donut
          color="#863d90"
          top="34rem"
          right="1rem"
          size="4rem"
          className="hidden lg:inline lg:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        /> */}
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-lg text-center font-semibold tracking-wider text-purple uppercase">
            Master Classes
          </h2>
          <p className="text-center mt-2 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
            {/* VCE Units 3 and 4 Final Examination 4-Week Revision Program */}
            VCE Units 3 and 4 Final Examination Revision Online Lectures
          </p>
          <p className="mt-4 w-[80%] max-w-4xl text-md text-gray-500 mx-auto">
            {/* The VCE Units 3 and 4 Final Examination Revision Program will
            consist of a comprehensive content recap and a focus on
            subject-specific examination-style questions and techniques. This
            includes probable examination questions and areas in the examination
            where students find difficult and are poorly addressed. */}
            Every subject will run for an hour per week across a 5-week period.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {revision.map((classes) => (
            <div
              key={classes.title}
              className="flex flex-col rounded-xl shadow-lg overflow-hidden border-4 border-purple">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={classes.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col">
                <div className="flex-1">
                  <p className="text-sm font-medium text-purple">
                    <span
                      className={`inline-flex items-center px-3 py-1 rounded-full text-md font-medium ${classes.tag} text-white`}>
                      {classes.subject}
                    </span>
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-blue">
                      {classes.date}
                    </p>
                    <div className="flex items-center mt-3 sm:mr-5 justify-between">
                      <h4 className="font-medium text-blue">Sessions:</h4>
                      <div className="flex flex-col text-right ">
                        <p className="text-base text-gray-500">
                          {classes.time[0]}
                        </p>
                        <p className="text-base text-gray-500">
                          {classes.time[1]}
                        </p>
                        <p className="text-base text-gray-500">
                          {classes.time[2]}
                        </p>
                        <p className="text-base text-gray-500">
                          {classes.time[3]}
                        </p>
                        <p className="text-base text-gray-500">
                          {classes.time[4]}
                        </p>
                        <p className="text-base text-gray-500">
                          {classes.time[5]}
                        </p>
                        {/* {classes.dateTwo ? null : (
                          <p className="text-base text-gray-500">
                            {classes.time[2]}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="flex justify-between items-center mt-3 mr-0 sm:mr-5">
                      <h4 className="font-medium text-blue">Mode:</h4>
                      <p className="text-base text-gray-500">{classes.mode}</p>
                    </div>
                    <div className="flex justify-between items-center mt-3 mr-0 sm:mr-5">
                      <h4 className="font-medium text-blue">Investment:</h4>
                      <div className="flex flex-col text-right">
                        <p className="text-base text-gray-500 ml-2">
                          {classes.investmentTotal}
                        </p>
                        <p className="text-base text-gray-500 ml-[3.8rem]">
                          {classes.investmentPerSession}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex items-center text-center">
                  {classes.presenterTwo ? (
                    <>
                      <div className="flex flex-col">
                        <div className="flex gap-4">
                          {/* Presenter image */}
                          <div className="flex-shrink-0">
                            <div>
                              <span className="sr-only">
                                {classes.presenter.name}
                              </span>
                              {classes.presenter.imageUrl ? (
                                <img
                                  className="h-16 w-16 rounded-full"
                                  src={classes.presenter.imageUrl}
                                  alt=""
                                />
                              ) : (
                                <svg
                                  className="h-16 w-16 rounded-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24">
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              )}
                            </div>
                          </div>
                          {/* Presenter image ends here*/}
                          {/* Presenter 2 image */}
                          <div className="flex-shrink-0">
                            <div>
                              <span className="sr-only">
                                {classes.presenterTwo.name}
                              </span>
                              {classes.presenterTwo.imageUrl ? (
                                <img
                                  className="h-16 w-16 rounded-full"
                                  src={classes.presenterTwo.imageUrl}
                                  alt=""
                                />
                              ) : (
                                <svg
                                  className="h-16 w-16 rounded-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24">
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              )}
                            </div>
                          </div>
                          {/* Presenter 2 image ends here*/}
                        </div>
                        <div className="flex gap-9">
                          {/* Presenter name */}
                          <div className="ml-1 flex items-center">
                            <p className="text-sm font-medium text-purple w-[20%] sm:w-full">
                              <Popover className="">
                                <Popover.Button className="hover:text-yellow">
                                  {classes.presenter.name}
                                </Popover.Button>

                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0">
                                  <Popover.Panel>
                                    <div className="absolute text-sm sm:text-[16px] -left-5 bottom-[3rem] lg:bottom-[2rem] bg-white border-purple border-2 rounded-xl w-[20rem] sm:w-[24rem] px-4 py-4 text-blue">
                                      {classes.presenter.description}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </p>
                          </div>
                          {/* Presenter name ends here */}
                          {/* Presenter 2 name */}
                          <div className="ml-1 flex items-center">
                            <p className="text-sm font-medium text-purple w-[20%] sm:w-full">
                              <Popover className="">
                                <Popover.Button className="hover:text-yellow">
                                  {classes.presenterTwo.name}
                                </Popover.Button>

                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0">
                                  <Popover.Panel>
                                    <div className="absolute text-sm sm:text-[16px] -left-24 bottom-[3rem] lg:bottom-[2rem] bg-white border-purple border-2 rounded-xl w-[20rem] sm:w-[24rem] px-4 py-4 text-blue">
                                      {classes.presenterTwo.description}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </p>
                          </div>
                          {/* Presenter 2 name ends here */}
                        </div>
                        <div className="space-x-1 text-sm text-gray-500">
                          <p>Co-Presenters</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Presenter image */}
                      <div className="flex-shrink-0">
                        <div>
                          <span className="sr-only">
                            {classes.presenter.name}
                          </span>
                          {classes.presenter.imageUrl ? (
                            <img
                              className="h-16 w-16 rounded-full"
                              src={classes.presenter.imageUrl}
                              alt=""
                            />
                          ) : (
                            <svg
                              className="h-16 w-16 rounded-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          )}
                        </div>
                      </div>
                      {/* Presenter image ends here*/}
                      {/* Presenter name */}
                      <div className="ml-3">
                        <p className="text-md font-medium text-purple w-[20%] sm:w-full">
                          <Popover className="">
                            <Popover.Button className="hover:text-yellow">
                              {classes.presenter.name}
                            </Popover.Button>

                            <Transition
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0">
                              <Popover.Panel>
                                <div className="absolute text-sm sm:text-[16px] -left-24 bottom-[3rem] lg:bottom-[2rem] bg-white border-purple border-2 rounded-xl w-[20rem] sm:w-[24rem] px-4 py-4 text-blue">
                                  {classes.presenter.description}
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <p>Presenter</p>
                        </div>
                      </div>
                      {/* Presenter name ends here */}
                    </>
                  )}

                  <a
                    href="https://forms.gle/YrRQ5EV3efSAVa447?fbclid=IwAR0ThD_XvkWQEykVzqX_bV7Pqifvr_oYL2dtlIv9rxlKv7u5288lOil4xfE"
                    target="_blank">
                    <button className="absolute right-0 bottom-2 mr-5 bg-purple text-white px-2 py-2 rounded-xl hover:bg-yellow">
                      Register now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <CircleGrid
        position="absolute"
        left="0"
        bottom="32rem"
        color="#863d90"
        size="80px"
        zIndex={0}
      /> */}
      <CircleGrid
        position="absolute"
        right="0"
        bottom="14rem"
        className="hidden lg:inline"
        color="#863d90"
        size="80px"
        zIndex={0}
      />
      <Donut
        color="#863d90"
        bottom="58rem"
        left="0.1rem"
        size="4rem"
        className="hidden xl:inline lg:animate-shape"
        width={["0.5rem", "1rem", "1rem", "1rem"]}
        zIndex={0}
      />
      <Donut
        color="#863d90"
        bottom="22rem"
        right="1rem"
        size="4rem"
        className="hidden lg:inline lg:animate-shape"
        width={["0.5rem", "1rem", "1rem", "1rem"]}
        zIndex={0}
      />
      {/* HEAD START */}
      {/* <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase">
            Master Classes
          </h2>
          <p className="text-center mt-2 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
            VCE Unit 3 Head Start Program
          </p>
          <p className="mt-4 w-[80%] max-w-4xl text-md text-gray-500 mx-auto">
            The VCE Unit 3 Head Start Program aims to frontload students with
            subject-specific knowledge, skills, and understandings that will
            then give them the head start for School-assessed Courseworks (SACs)
            and examinations.
          </p>
        </div>

        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {headStart.map((classes) => (
            <div
              key={classes.title}
              className="flex flex-col rounded-xl shadow-lg overflow-hidden border-4 border-purple">
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={classes.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-purple">
                    <span
                      className={`inline-flex items-center px-3 py-1 rounded-full text-md font-medium ${classes.tag} text-white`}>
                      {classes.subject}
                    </span>
                  </p>
                  <div className="block mt-2"> */}
      {/* Session 1 */}
      {/* <p className="text-xl font-semibold text-blue">
                      {classes.date}
                    </p>
                    <div className="flex justify-between items-center mt-3 sm:mr-5 text-right">
                      <h4 className="font-medium text-blue">Duration:</h4>
                      <div className="flex flex-col">
                        <p className="text-base text-gray-500">
                          {classes.time[0]}
                        </p>
                        <p className="text-base text-gray-500">
                          {classes.time[1]}
                        </p>
                        {classes.dateTwo ? null : (
                          <p className="text-base text-gray-500">
                            {classes.time[2]}
                          </p>
                        )}
                      </div>
                    </div> */}

      {/* Session 2 */}
      {/* <p className="text-xl font-semibold text-blue mt-5 ">
                      {classes.dateTwo}
                    </p>
                    {classes.dateTwo ? (
                      <div className="flex justify-between items-center mt-3 sm:mr-5">
                        <h4 className="font-medium text-blue">Duration:</h4>
                        <div className="flex flex-col text-right">
                          <p className="text-base text-gray-500">
                            {classes.time[2]}
                          </p>
                          <p className="text-base text-gray-500">
                            {classes.time[3]}
                          </p>
                        </div>
                      </div>
                    ) : null}

                    <div className="flex justify-between items-center mt-3 sm:mr-5">
                      <h4 className="font-medium text-blue">Mode:</h4>
                      <p className="text-base text-gray-500">{classes.mode}</p>
                    </div>
                    <div className="flex justify-between items-center mt-3 sm:mr-5">
                      <h4 className="font-medium text-blue">Investment:</h4>
                      <div className="flex flex-col text-right">
                        <p className="text-base text-gray-500 ml-2">
                          {classes.investmentTotal}
                        </p>
                        <p className="text-base text-gray-500 ml-6">
                          {classes.investmentPerSession}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <div>
                      <span className="sr-only">{classes.presenter.name}</span>
                      {classes.presenter.imageUrl ? (
                        <img
                          className="h-16 w-16 rounded-full"
                          src={classes.presenter.imageUrl}
                          alt=""
                        />
                      ) : (
                        <svg
                          className="h-16 w-16 rounded-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="text-md font-medium text-purple w-[20%] sm:w-full">
                      <Popover className="">
                        <Popover.Button className="hover:text-yellow">
                          {classes.presenter.name}
                        </Popover.Button>

                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0">
                          <Popover.Panel>
                            <div className="absolute text-sm sm:text-[16px] -left-24 bottom-[3rem] lg:bottom-[2rem] bg-white border-purple border-2 rounded-xl w-[20rem] sm:w-[24rem] px-4 py-4 text-blue">
                              {classes.presenter.description}
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <p>Presenter</p>
                    </div>
                  </div>
                  <a
                    href="https://forms.gle/aAXHtPCHLx4TGjz39"
                    target="_blank"
                    rel="noreferrer">
                    <button className="z-1 absolute right-0 bottom-2 mr-2 bg-purple text-white px-2 py-2 rounded-xl hover:bg-yellow flex items-center">
                      Enquire now
                      <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-white stroke-2 ml-2" />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      {/* END HERE */}
    </div>
  );
}
