import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Hero, About, Julian, WhyUs, fadeUp } from "../index";
import ata from "../assets/ATA Logo.jpeg";
import tutorwo from "../assets/tutorwo-transparent-logo-100h.png";
import Services from "../Services";
import OurTalent from "../OurTalent";
import Tutors from "../Tutors";
import Testimonial from "../Testimonial";
import { motion } from "framer-motion";

const Home = () => {
  const aboutRef = useRef();
  const servicesRef = useRef();
  const julianRef = useRef();
  const subjectsRef = useRef();
  const whyUsRef = useRef();
  let location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (location.hash.includes("#about")) {
        aboutRef.current.scrollIntoView(true);
      } else if (location.hash.includes("#julian")) {
        julianRef.current.scrollIntoView(true);
      } else if (location.hash.includes("#subjects")) {
        subjectsRef.current.scrollIntoView(true);
      } else if (location.hash.includes("#whyus")) {
        whyUsRef.current.scrollIntoView(true);
      }
    }, 1500);
  });

  return (
    <main>
      <Hero />
      <Services servicesRef={servicesRef} />
      {/* Horizontal scroll Title */}
      <OurTalent />
      {/* <About aboutRef={aboutRef} /> */}
      <Julian julianRef={julianRef} />
      <Tutors />
      <Testimonial />
      {/* <WhyUs whyUsRef={whyUsRef} /> */}
      {/* LOGO CLOUD */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-20">
        <motion.div
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1, delay: 0.3 }}
          viewport={{ once: true }}
          className="mt-6 grid grid-cols-1 lg:grid-cols-2 gap-0.5 lg:mt-8 items-center">
          <div className="col-span-1 flex justify-center py-8 px-8 ">
            <img className="max-h-40" src={ata} alt="ATA" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 ">
            <img className="max-h-20" src={tutorwo} alt="tutorwo" />
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Home;
