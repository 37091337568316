import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
// import heroCover from "./assets/hero-cover-edited-compressed.png";
import heroCover from "./assets/heroGirlSmilling.png";
import circleWithBlueTick from "./assets/circleWithBlueTick.png";
import doodleStar from "./assets/vectors/doodleStar.svg";
import { motion } from "framer-motion";
import { fadeDown, fadeRightToLeft, fadeUp } from ".";
import Marquee from "react-fast-marquee";
import { MegaphoneIcon } from "@heroicons/react/24/solid";

const Hero = () => {
  return (
    // <div className="hero-container">
    //   <div className="left-10 sm:left-0 absolute ml-10  lg:ml-28 sm:mt-[12%] md:mt-[15%] lg:w-[40%]">
    //     <motion.h1
    //       variants={fadeUp}
    //       initial={"hidden"}
    //       animate={"visible"}
    //       transition={{ duration: 1.5 }}
    //       className="mt-4 text-2xl tracking-tight font-bold text-white sm:mt-5 sm:text-2xl md:text-4xl  lg:mt-6 xl:text-6xl">
    //       <span className="hidden md:block mb-2">
    //         Bring the <span className="text-yellow">very best</span>
    //       </span>
    //       <span className="hidden md:block pb-3 bg-clip-text text-transparent bg-white sm:pb-5">
    //         out of your child
    //       </span>
    //       <div className="md:hidden flex flex-col mt-10 -translate-x-7 text-[1.8rem] sm:text-4xl">
    //         <span className="block mb-2">
    //           <span className="text-yellow">High achieving</span> tutors
    //         </span>
    //       </div>
    //     </motion.h1>
    //     <motion.div
    //       variants={fadeUp}
    //       initial={"hidden"}
    //       animate={"visible"}
    //       transition={{ duration: 1.5, delay: 0.4 }}>
    //       <p className="text-lg text-grey py-2 block sm:pb-5 md:hidden absolute -left-7">
    //         Learn from top-achieving and high-performing tutors.
    //       </p>
    //     </motion.div>
    //     <motion.div
    //       variants={fadeUp}
    //       initial={"hidden"}
    //       animate={"visible"}
    //       transition={{ duration: 1.5, delay: 0.4 }}>
    //       <p className="hidden md:inline  w-[80%] sm:w-full text-grey sm:text-xl lg:text-lg xl:text-xl">
    //         Learn from top-achieving and high-performing tutors.
    //       </p>
    //     </motion.div>
    //     <motion.div
    //       variants={fadeUp}
    //       initial={"hidden"}
    //       animate={"visible"}
    //       transition={{ duration: 1.5, delay: 0.8 }}
    //       className="hidden lg:flex mt-10">
    //       <a href="https://forms.gle/vkf19CCGFQeAPYUF8" target="_blank">
    //         <button className="block w-30 py-3 px-4 rounded-md shadow bg-purple text-white font-medium hover:bg-yellow">
    //           Book a lesson
    //         </button>
    //       </a>
    //       <Link to="/private-tutoring">
    //         <button className="block mx-4 w-30 py-3 px-4 rounded-md shadow bg-white text-purple font-medium hover:bg-yellow">
    //           Learn more
    //         </button>
    //       </Link>
    //     </motion.div>
    //   </div>
    //   <img className="block w-full" src={heroCover} alt="TutorWo Cover"></img>
    // </div>
    <section className="flex flex-col bg-purple bg-hero mb-20">
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSeka3ASGbWgsnAhF2K8r6j2azn7siTUW68SsRMpgLaiMJQC-A/viewform?usp=sf_link "
        target="_blank"
        rel="noreferrer">
        <div className="my-5 w-[80%] flex justify-center items-center mx-auto text-white text-base">
          <MegaphoneIcon className="w-5 h-5 mr-5" />
          <Marquee pauseOnHover gradientColor={[255, 255, 255]}>
            Plan ahead for 2024! Secure a slot now for private tutoring in 2024
            with one of our high achieving and top performing tutors. Don't miss
            out! Enquiry today in pursuit of academic excellence in 2024. &nbsp;
          </Marquee>
        </div>
      </a>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-5 md:gap-20">
        <div className="lg:mb-20">
          <div className="flex items-center">
            {/* HERO TEXT LEFT SIDE */}
            <motion.h1
              variants={fadeUp}
              initial={"hidden"}
              animate={"visible"}
              transition={{ duration: 1.5 }}
              className="mt-4 text-2xl font-bold text-white sm:mt-5 sm:text-2xl md:text-4xl lg:mt-6 xl:text-6xl">
              <span className="hidden md:block mb-2">
                <span className="text-yellow">Unlock </span>your full
              </span>
              <span className="hidden md:block pb-3 bg-clip-text text-transparent bg-white sm:pb-5">
                academic potential
              </span>
              <span className="block md:hidden mx-5 text-4xl sm:text-5xl">
                <span className="text-yellow">Unlock </span> your full
                <span className="inline md:hidden pb-3 bg-clip-text text-transparent bg-white sm:pb-5 text-4xl sm:text-5xl ml-2">
                  academic potential
                </span>
              </span>
            </motion.h1>
            {/* HERO TEXT LEFT SIDE */}
            {/* HERO IMAGE RIGHT SIDE */}
            <motion.div
              className="relative flex justify-center mr-5"
              variants={fadeRightToLeft}
              initial={"hidden"}
              animate={"visible"}
              transition={{ duration: 1.5, delay: 0.5 }}>
              <img
                className="md:w-24 md:h-24 w-full h-full md:ml-10"
                src={circleWithBlueTick}></img>
              <motion.svg
                className="md:w-24 md:h-24 w-full h-full absolute top-0 md:right-0"
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ ease: "linear", duration: 5, repeat: Infinity }}
                width="135"
                height="133"
                viewBox="0 0 135 133"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M54.7101 107.956L48.3942 122.063L45.4419 120.728L48.0898 114.814L41.9446 112.036L39.2966 117.95L36.3443 116.616L42.6602 102.509L45.6126 103.844L42.9646 109.758L49.1098 112.536L51.7578 106.622L54.7101 107.956Z"
                  fill="#863D90"
                />
                <path
                  d="M33.0232 110.992L31.4025 112.953L28.6938 110.693L30.3145 108.732L33.0232 110.992ZM41.398 100.445L35.6797 107.364L36.988 108.456L35.2379 110.121L31.6631 107.138L38.9104 98.3691L41.398 100.445Z"
                  fill="#863D90"
                />
                <path
                  d="M36.7149 89.1515C37.2083 89.8412 37.6704 90.5831 38.101 91.3774C38.5222 92.1587 38.8255 92.8574 39.0107 93.4737L37.0458 94.8932C36.842 94.2509 36.5341 93.5456 36.1221 92.7774C35.7231 91.9998 35.3048 91.3052 34.8672 90.6936C34.2248 89.7958 33.7074 89.1827 33.315 88.8542C32.9356 88.5164 32.6227 88.4366 32.3763 88.6146C32.1688 88.7645 32.0913 88.9587 32.1438 89.197C32.187 89.4224 32.4088 89.8149 32.8092 90.3744L34.6107 92.8922C35.7931 94.5448 35.7748 95.8114 34.5557 96.6922C34.1795 96.9639 33.7614 97.1081 33.3011 97.1248C32.8409 97.1415 32.3571 96.9877 31.8496 96.6634C32.072 98.1016 31.5542 99.2751 30.2962 100.184C29.103 101.046 27.9751 101.298 26.9127 100.941C25.8373 100.593 24.7782 99.6898 23.7354 98.2324C23.5213 97.9331 23.3145 97.5891 23.115 97.2003C22.8932 96.8078 22.7366 96.5065 22.6451 96.2962L20.1593 92.8219L21.8548 91.6858L22.9022 93.1497C22.9897 92.4746 23.3706 91.8934 24.0451 91.4062C24.9918 90.7222 25.9647 90.4734 26.9636 90.6597C27.9496 90.8553 28.8615 91.5387 29.6995 92.7098C29.8484 92.918 29.9863 93.1244 30.113 93.3289C30.2174 93.5298 30.3264 93.7372 30.4401 93.9512C30.7713 94.3041 31.0995 94.5703 31.4247 94.7498C31.7406 94.9164 32.0088 94.92 32.2293 94.7607C32.5276 94.5452 32.4859 94.1707 32.1042 93.6372L30.2328 91.0218C29.5624 90.0849 29.2597 89.2081 29.3246 88.3914C29.3765 87.5841 29.8045 86.89 30.6086 86.3091C31.5165 85.6532 32.4842 85.5759 33.5117 86.0771C34.5392 86.5783 35.607 87.6031 36.7149 89.1515ZM28.7796 94.5587C28.221 93.778 27.7049 93.3316 27.2313 93.2198C26.7355 93.1042 26.1699 93.276 25.5344 93.7351C24.8989 94.1943 24.5502 94.683 24.4883 95.2015C24.4171 95.7069 24.6608 96.3499 25.2195 97.1307C25.7501 97.8724 26.2745 98.3028 26.7926 98.422C27.2977 98.5505 27.8745 98.3805 28.523 97.9121C29.1196 97.481 29.4535 97.0128 29.5247 96.5074C29.5866 95.989 29.3382 95.3394 28.7796 94.5587Z"
                  fill="#863D90"
                />
                <path
                  d="M29.3549 84.9778L13.9734 89.9851L12.9792 86.9014L18.4563 85.1184C17.9792 84.7858 17.5311 84.3343 17.1118 83.7641C16.6926 83.1938 16.3627 82.5356 16.1222 81.7895C15.6606 80.358 15.659 79.2059 16.1173 78.3332C16.5756 77.4605 17.5046 76.7963 18.9043 76.3406L25.842 74.0821L26.8362 77.1658L20.0583 79.3723C19.328 79.61 18.8599 79.9307 18.6538 80.3343C18.4428 80.7227 18.4674 81.3204 18.7276 82.1275C18.8798 82.5996 19.141 83.0446 19.5111 83.4626C19.8763 83.8653 20.2702 84.1746 20.6928 84.3903L28.3607 81.8941L29.3549 84.9778Z"
                  fill="#863D90"
                />
                <path
                  d="M25.6384 65.462L11.6914 56.5415L12.4057 53.0621L28.7224 50.4393L28.0467 53.7307L24.1098 54.4097L22.9659 59.9815L26.3189 62.1471L25.6384 65.462ZM20.6184 59.0047L21.5692 54.3733L15.0048 55.4391L20.6184 59.0047Z"
                  fill="#863D90"
                />
                <path
                  d="M32.9591 45.1225C31.9506 46.8866 30.7399 47.9889 29.3269 48.4295C27.9218 48.8563 26.3809 48.5857 24.7042 47.6179C22.9721 46.618 21.9692 45.3741 21.6957 43.8861C21.4301 42.3841 21.7778 40.7928 22.7386 39.1121C23.1357 38.4176 23.5256 37.8483 23.9085 37.4042C24.2914 36.9602 24.7287 36.566 25.2204 36.2217L27.2366 37.3855C26.8718 37.6368 26.5098 37.9636 26.1508 38.366C25.7917 38.7684 25.4613 39.2335 25.1596 39.7614C24.5561 40.817 24.3172 41.7506 24.443 42.5622C24.5549 43.3658 25.186 44.0995 26.3361 44.7634C27.3892 45.3713 28.3012 45.5652 29.0721 45.3451C29.8509 45.1111 30.5659 44.4246 31.2171 43.2856C31.495 42.7995 31.7165 42.2992 31.8816 41.7848C32.0546 41.2566 32.1592 40.735 32.1955 40.2202L34.2741 41.4201C34.1439 42.5827 33.7056 43.8168 32.9591 45.1225Z"
                  fill="#863D90"
                />
                <path
                  d="M35.7945 40.6352L24.7349 28.8306L27.0887 26.6041L31.0268 30.8075C31.1232 30.2318 31.3389 29.6313 31.674 29.006C32.009 28.3807 32.4613 27.7987 33.0308 27.2599C34.1234 26.2264 35.1603 25.7301 36.1414 25.771C37.1226 25.812 38.1163 26.3696 39.1227 27.4438L44.111 32.7681L41.7573 34.9946L36.8839 29.7929C36.3588 29.2325 35.8706 28.9454 35.4194 28.9318C34.9797 28.9072 34.4519 29.1863 33.8359 29.769C33.4755 30.1099 33.186 30.538 32.9672 31.0533C32.76 31.5576 32.6492 32.0478 32.6349 32.5239L38.1482 38.4087L35.7945 40.6352Z"
                  fill="#863D90"
                />
                <path
                  d="M40.6248 19.7877L39.4255 17.5441L42.5302 15.8685L43.7295 18.1121L40.6248 19.7877ZM47.2041 31.5364L42.9728 23.6203L41.4732 24.4296L40.5953 22.1762L44.6927 19.9649L50.0553 29.9976L47.2041 31.5364Z"
                  fill="#863D90"
                />
                <path
                  d="M59.5347 27.2023C57.6229 27.6694 55.9927 27.5653 54.6441 26.89C53.2917 26.1992 52.3804 24.8819 51.9101 22.938C51.4849 21.1807 51.6144 19.6749 52.2986 18.4207C52.9947 17.1472 54.2752 16.2826 56.1404 15.8269C57.8501 15.4092 59.2648 15.5413 60.3846 16.223C61.5161 16.8854 62.2568 17.9397 62.6067 19.386L63.0921 21.3921L55.0953 23.3457C55.4807 24.1904 56.0316 24.6982 56.7478 24.869C57.4797 25.0361 58.4362 24.9754 59.6175 24.6868C60.2081 24.5425 60.7933 24.3419 61.3732 24.085C61.9685 23.8242 62.4631 23.5552 62.8569 23.2778L63.3988 25.5172C62.9423 25.8758 62.3853 26.193 61.7277 26.4689C61.0739 26.7604 60.3429 27.0049 59.5347 27.2023ZM54.6268 21.4096L59.8725 20.1281L59.7258 19.5216C59.5715 18.884 59.2646 18.4319 58.805 18.1653C58.3417 17.8832 57.6671 17.8504 56.7811 18.0668C55.7397 18.3212 55.06 18.7097 54.742 19.2321C54.4394 19.7507 54.4011 20.4765 54.6268 21.4096Z"
                  fill="#863D90"
                />
                <path
                  d="M70.0094 26.3203L66.3424 14.6507L69.7176 14.8938L71.9652 23.1887L75.3909 15.3024L78.7422 15.5437L73.4804 26.5702L70.0094 26.3203Z"
                  fill="#863D90"
                />
                <path
                  d="M84.5496 15.5373L85.3797 13.1325L88.7111 14.2937L87.881 16.6985L84.5496 15.5373ZM80.4054 28.3512L83.3344 19.8665L81.7253 19.3057L82.7804 17.1318L87.177 18.6643L83.4648 29.4176L80.4054 28.3512Z"
                  fill="#863D90"
                />
                <path
                  d="M87.0806 31.0047L93.4475 21.5773L95.926 23.2674L95.4207 24.23C96.0011 24.1997 96.6537 24.2768 97.3787 24.4613C98.1258 24.6415 98.8232 24.9525 99.4709 25.3942C100.714 26.2415 101.422 27.1507 101.596 28.1217C101.771 29.0927 101.446 30.1882 100.622 31.408L96.5389 37.4543L93.862 35.6289L97.8514 29.7219C98.2812 29.0854 98.4535 28.5445 98.3682 28.099C98.2961 27.6626 97.9098 27.2054 97.2092 26.7277C96.7994 26.4483 96.3203 26.2571 95.7719 26.1543C95.2367 26.0605 94.7363 26.0582 94.2707 26.1473L89.7574 32.83L87.0806 31.0047Z"
                  fill="#863D90"
                />
                <path
                  d="M97.2015 45.5196C96.6801 44.8508 96.188 44.1285 95.7251 43.3526C95.2721 42.5893 94.9403 41.9035 94.73 41.2954L96.6346 39.7961C96.8647 40.4295 97.2013 41.1215 97.6446 41.8722C98.0752 42.6327 98.5217 43.3096 98.9841 43.9026C99.6628 44.7733 100.205 45.3646 100.611 45.6766C101.004 45.9985 101.319 46.0655 101.558 45.8774C101.759 45.7191 101.829 45.5219 101.767 45.2859C101.714 45.0625 101.476 44.6795 101.053 44.1369L99.1499 41.6952C97.9005 40.0926 97.8667 38.8262 99.0485 37.896C99.4131 37.609 99.825 37.4477 100.284 37.4121C100.743 37.3765 101.233 37.5102 101.753 37.8133C101.472 36.3854 101.941 35.1915 103.161 34.2316C104.317 33.3211 105.434 33.0227 106.51 33.3362C107.599 33.6398 108.694 34.4982 109.796 35.9115C110.022 36.2017 110.243 36.5369 110.458 36.9172C110.696 37.3002 110.865 37.5949 110.965 37.8012L113.591 41.1704L111.944 42.3754L110.837 40.9558C110.778 41.634 110.421 42.2304 109.767 42.745C108.849 43.4674 107.888 43.7561 106.882 43.6111C105.889 43.4562 104.95 42.8109 104.064 41.6753C103.907 41.4734 103.761 41.2728 103.626 41.0737C103.513 40.8772 103.396 40.6744 103.273 40.4654C102.928 40.1264 102.589 39.8739 102.257 39.7079C101.934 39.5545 101.666 39.5619 101.452 39.7301C101.163 39.9578 101.22 40.3302 101.624 40.8476L103.601 43.3839C104.309 44.2924 104.648 45.156 104.616 45.9747C104.598 46.7835 104.199 47.4947 103.419 48.1083C102.539 48.8011 101.576 48.9182 100.528 48.4597C99.4811 48.0012 98.3722 47.0212 97.2015 45.5196ZM104.907 39.79C105.497 40.5471 106.031 40.9718 106.509 41.0641C107.009 41.1591 107.567 40.9641 108.183 40.4792C108.799 39.9943 109.128 39.4916 109.168 38.971C109.218 38.463 108.949 37.8305 108.358 37.0734C107.798 36.3542 107.256 35.9457 106.733 35.8479C106.223 35.7403 105.654 35.9339 105.026 36.4287C104.447 36.8839 104.133 37.3655 104.083 37.8735C104.042 38.394 104.317 39.0329 104.907 39.79Z"
                  fill="#863D90"
                />
                <path
                  d="M108.042 59.995L120.772 57.5675L119.891 52.8981L122.342 52.4305L124.703 64.9296L122.251 65.3971L121.374 60.7512L108.643 63.1787L108.042 59.995Z"
                  fill="#863D90"
                />
                <path
                  d="M108.281 72.8282C108.447 71.4783 108.949 70.4684 109.789 69.7984C110.643 69.1463 111.8 68.9106 113.261 69.0914L120.335 69.9666L119.941 73.1825L112.938 72.3162C112.176 72.2219 111.598 72.3357 111.204 72.6578C110.826 72.9818 110.592 73.5091 110.502 74.2396C110.434 74.7954 110.483 75.3174 110.649 75.8055C110.813 76.3094 111.041 76.7487 111.334 77.1235L119.337 78.1136L118.943 81.3296L107.653 79.9328L108.017 76.9551L109.104 76.9686C108.781 76.445 108.533 75.8419 108.358 75.1593C108.198 74.4946 108.172 73.7176 108.281 72.8282Z"
                  fill="#863D90"
                />
                <path
                  d="M104.523 86.0458C105.024 84.8329 105.713 84.0628 106.592 83.7356C107.465 83.4231 108.448 83.4944 109.541 83.9495L114.416 85.9789L115.029 84.4923L117.333 85.4517L116.72 86.9383L118.98 87.8792L118.631 91.2435L115.485 89.9336L114.387 92.5962L112.156 91.4593L113.181 88.9743L108.506 87.0279C107.93 86.7881 107.476 86.7551 107.145 86.9292C106.828 87.1093 106.554 87.4804 106.322 88.0425C106.151 88.4567 106.048 88.9164 106.013 89.4217L103.952 88.5638C103.954 88.183 104.004 87.788 104.104 87.3788C104.188 86.9635 104.328 86.5192 104.523 86.0458Z"
                  fill="#863D90"
                />
                <path
                  d="M99.0817 94.4797C100.388 92.7996 101.748 91.884 103.162 91.733C104.589 91.5919 106.045 92.1042 107.53 93.2698C109.016 94.4355 109.856 95.726 110.053 97.1415C110.253 98.5794 109.704 100.132 108.408 101.8C107.102 103.48 105.736 104.39 104.309 104.531C102.885 104.695 101.43 104.194 99.9448 103.028C98.4596 101.863 97.6124 100.567 97.403 99.1419C97.2063 97.7265 97.7659 96.1724 99.0817 94.4797ZM101.253 96.1837C100.615 97.0048 100.34 97.7355 100.431 98.3756C100.533 99.0257 101.044 99.7113 101.963 100.432C102.882 101.154 103.661 101.48 104.301 101.413C104.953 101.356 105.599 100.917 106.237 100.096C106.865 99.2871 107.128 98.5578 107.026 97.9078C106.936 97.2676 106.431 96.5869 105.512 95.8658C104.594 95.1447 103.808 94.8128 103.155 94.8701C102.515 94.9374 101.881 95.3752 101.253 96.1837Z"
                  fill="#863D90"
                />
                <path
                  d="M93.7589 100.215L100.508 109.372L98.1011 111.163L97.2879 110.302C97.067 110.866 96.7308 111.475 96.2794 112.13C95.8246 112.807 95.3305 113.404 94.7969 113.921L93.3446 111.95C93.6532 111.641 93.975 111.282 94.3098 110.873C94.6446 110.464 94.9507 110.057 95.228 109.651C95.5053 109.246 95.7202 108.876 95.8725 108.544L91.1596 102.149L93.7589 100.215Z"
                  fill="#863D90"
                />
                <path
                  d="M82.4068 106.608C83.1902 106.284 83.961 106.034 84.7191 105.858C85.4712 105.668 86.095 105.566 86.5905 105.551L87.6106 108.038C87.0082 108.045 86.3575 108.124 85.6585 108.275C84.9656 108.441 84.3456 108.637 83.7987 108.863C83.0596 109.169 82.5604 109.428 82.3012 109.639C82.0272 109.857 81.954 110.121 82.0815 110.432C82.2272 110.787 82.57 110.948 83.1098 110.915C83.6349 110.888 84.4164 110.833 85.4543 110.749C86.5488 110.677 87.4488 110.763 88.1544 111.008C88.86 111.252 89.398 111.826 89.7684 112.729C90.1813 113.736 90.1349 114.656 89.6292 115.488C89.1148 116.342 88.0445 117.106 86.4185 117.779C85.768 118.049 85.1333 118.251 84.5143 118.386C83.9014 118.536 83.368 118.627 82.9142 118.659L81.9032 116.195C82.3813 116.222 82.8972 116.173 83.451 116.047C84.0109 115.936 84.5126 115.789 84.9561 115.606C85.5917 115.342 86.0835 115.087 86.4314 114.839C86.7645 114.597 86.8703 114.328 86.7489 114.032C86.6153 113.706 86.3216 113.559 85.8678 113.591C85.3993 113.63 84.6817 113.693 83.715 113.782C82.7692 113.879 81.999 113.878 81.4046 113.777C80.8101 113.677 80.3318 113.46 79.9696 113.125C79.6134 112.805 79.3109 112.341 79.0619 111.734C78.1876 109.603 79.3025 107.894 82.4068 106.608Z"
                  fill="#863D90"
                />
              </motion.svg>
            </motion.div>
            {/* END - HERO IMAGE RIGHT SIDE */}
          </div>
          <motion.div
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 0.4 }}>
            <p className="hidden md:inline w-[80%] sm:w-full text-grey sm:text-xl lg:text-lg xl:text-xl">
              with our top ATAR-achieving tutors and high-performing school
              teachers
            </p>
            <p className="mx-5 mt-5 md:hidden w-[80%] sm:w-full text-grey sm:text-xl">
              with our top ATAR-achieving tutors and high-performing school
              teachers
            </p>
          </motion.div>
          {/* <motion.div
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 0.8 }}
            className="flex mt-10 ml-5 md:ml-0">
            <a href="https://forms.gle/vkf19CCGFQeAPYUF8" target="_blank">
              <button className="block w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                Book a lesson
              </button>
            </a>
            <Link to="/private-tutoring">
              <button className="block mx-4 w-30 py-3 px-4 rounded-md shadow  bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
                Learn more
              </button>
            </Link>
          </motion.div> */}
        </div>
        {/* RIGHT IMAGE */}
        <div className="relative">
          <motion.img
            variants={fadeRightToLeft}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5 }}
            className="w-80 h-80 md:w-96 md:h-96"
            src={heroCover}></motion.img>
          <motion.img
            className="absolute flex items-center top-36 -left-8 w-16 h-16 rounded-lg"
            variants={fadeDown}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1 }}
            src={doodleStar}
            alt="doodled yellow star"
          />
          <motion.img
            className="absolute flex items-center bottom-0 right-0 w-16 h-16 rounded-lg"
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1.5 }}
            src={doodleStar}
            alt="doodled yellow star"
          />
          {/* <motion.div
            variants={fadeDown}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1 }}
            className="absolute flex items-center top-40 w-auto p-2 h-6 bg-yellow rounded-lg shadow-2xl text-blue font-medium ring ring-offset-2 ring-yellow">
            <p>Founded 2020</p>
          </motion.div>
          <motion.div
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1.5 }}
            className="absolute flex items-center bottom-40 right-0 w-auto p-2 h-6 bg-yellow rounded-lg shadow-2xl text-blue font-medium ring ring-offset-2 ring-yellow">
            <p>{`Tutors ATAR >95`}</p>
          </motion.div> */}
        </div>
      </div>
      {/* WAVES */}
      <div className="relative overflow-hidden">
        {/* <svg
          className="z-50 translate-y-24"
          width="100%"
          height="269"
          viewBox="0 0 3320 269"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1360.51 4.43459C859.042 -15.6099 293.002 49.5022 2 268H3319C3314.5 250.181 3264.92 188.781 2942.52 188.781L2925.41 188.782C2615.52 188.804 2488.26 188.814 2254.51 119.264C2016.51 48.449 1744.51 19.7838 1360.51 4.43459Z"
            fill="white"
            stroke="white"
          />
        </svg> */}

        <svg
          width="100%"
          height="100%"
          className="border-none ring-0 -m-[0.03rem] p-0"
          viewBox="0 0 3317 267"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            transform="translate(0 2)"
            d="M1358.51 3.43459C857.042 -16.6099 291.002 48.5022 0 267H3317C3312.5 249.181 3262.92 187.781 2940.52 187.781L2923.41 187.782C2613.52 187.804 2486.26 187.814 2252.51 118.264C2014.51 47.449 1742.51 18.7838 1358.51 3.43459Z"
            fill="white"
          />
        </svg>

        {/* <svg
          style={{
            marginTop: "-1px",
            marginBottom: "-2px",
            strokeWidth: 0,
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320">
          <path
            fill="#fff"
            stroke="none"
            strokeOpacity="0"
            fillOpacity="1"
            d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,186.7C672,171,768,181,864,202.7C960,224,1056,256,1152,250.7C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg> */}
        <motion.svg
          animate={{ x: "50%" }}
          transition={{ repeat: Infinity, repeatType: "mirror", duration: 10 }}
          className="absolute lg:-bottom-32 xl:-bottom-24 z-50"
          width="100%"
          height="271"
          viewBox="0 0 3320 271"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 269C292.002 50.5022 858.042 -14.6099 1359.51 5.43459C1743.51 20.7838 2015.51 49.449 2253.51 120.264C2491.52 191.08 2619.12 189.781 2941.52 189.781C3263.92 189.781 3313.5 251.181 3318 269"
            stroke="white"
            strokeWidth="3"
          />
        </motion.svg>

        <motion.svg
          animate={{ x: "-50%" }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 10 }}
          className="absolute lg:-bottom-32  xl:-bottom-24 z-50"
          width="100%"
          height="271"
          viewBox="0 0 3320 271"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3319 269C3028 50.5022 2461.96 -14.6099 1960.49 5.43459C1576.49 20.7838 1304.49 49.449 1066.49 120.264C828.484 191.08 700.883 189.781 378.481 189.781C56.0786 189.781 6.50003 251.181 2 269"
            stroke="white"
            strokeWidth="3"
          />
        </motion.svg>
        {/* <div className="absolute bottom-0 z-50 bg-purple"></div> */}
      </div>
    </section>
  );
};

export default Hero;
