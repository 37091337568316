import React, { useRef, useEffect } from "react";
import ServicesHero from "../../ServicesHero";
import englishMathTutoring from "../../assets/englishMathTutoringIllustration.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useState } from "react";
import EnglishTutoring from "../../lowerLevelTutoring/EnglishTutoring";
import MathTutoring from "../../lowerLevelTutoring/MathTutoring";
import { fadeLeftToRight, fadeUp } from "../..";
import privateTutoringIcon from "../../assets/ServicesIcons/private-tutoring-icon.png";
import groupTutoringIcon from "../../assets/ServicesIcons/group-tutoring-icon.png";
import { motion, useScroll, useTransform } from "framer-motion";
import OnlineLearning from "../../OnlineLearning";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

const LowerLevelTutoring = () => {
  const sectionRef = useRef();
  const triggerRef = useRef();
  const infoRef = useRef();
  const wrapperRef = useRef();
  const elementRef = useRef();

  // const [displaySection, setDisplaySection] = useState({
  //   english: true,
  //   math: false,
  // });
  gsap.registerPlugin(ScrollTrigger);
  const [timeOut, setTimeOut] = useState(false);
  const setTime = () => {
    const timeOutFunction = () => {
      ScrollTrigger.refresh();
      setTimeOut(true);
    };

    setTimeout(timeOutFunction, 1000);
  };

  useEffect(() => {
    // let ctx = gsap.context(() => {
    setTime();
    gsap.fromTo(
      infoRef.current,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: infoRef.current,
          start: "20% center",
          end: "bottom center",
          scrub: 2,
          // pin: true,
          // markers: true,
        },
      }
    );

    ScrollTrigger.refresh();

    gsap.fromTo(
      wrapperRef.current,
      {
        translateX: 0,
      },
      {
        translateX: "-1200",
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top center",
          end: "bottom center",
          scrub: 2,
          // pin: true,
          // markers: true,
        },
      }
    );

    // ScrollTrigger.refresh();
    // }, sectionRef);

    // return () => ctx.cleaup(); // cleanup
  }, [timeOut]);

  // const headingRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    // target: headingRef,
    // offset: ["start center", "end end"],
  });

  const xEnglish = useTransform(scrollYProgress, [0, 1], [-100, -1000]);
  const xMath = useTransform(scrollYProgress, [0, 1], [-1000, -100]);
  const xOffer = useTransform(scrollYProgress, [0, 1], [500, -500]);

  // const displayEnglish = () => {
  //   setDisplaySection({ english: true, math: false });
  // };

  // const displayMath = () => {
  //   setDisplaySection({ english: false, math: true });
  // };

  const threePhasedApproach = [
    {
      number: "Phase 01",
      heading: "Modelled instruction",
      information:
        "We present, demonstrate and break down skills into clear and easy-to-follow steps for our students.",
    },
    {
      number: "Phase 02",
      heading: "Guided practice",
      information:
        "Students practise the taught skills alongside with us, receiving on-the-spot and corrective feedback along the way.",
    },
    {
      number: "Phase 03",
      heading: "Independent Practice",
      information:
        "We check for understanding and mastery by having our students practise the taught skills independently.",
    },
  ];

  return (
    <section
      ref={sectionRef}
      className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Tutoring"
        secondHeading="Years 3 to 10 English and Mathematics Tutoring"
        headingDescription="We are specialists in addressing the top concern held by an increasing number of parents like yourself: students are progressing through school without a solid grasp of the fundamental basic skills in English and Mathematics. This has been exacerbated by the disruption to learning brought about by COVID-19. Consequently, a large proportion of students have fallen behind and are not performing at the expected level. Your child may be one of them."
        leftTag="English Tutoring"
        rightTag="Mathematics Tutoring"
        image={englishMathTutoring}
        imageDescription="Illustration of scholarship selective school exam"
      />
      <div className="w-full">
        <h2 className="text-purple text-3xl font-bold mt-20 text-center w-[70%] mx-auto">
          Like many parents, we also believe in the importance of laying strong
          foundations in English and Mathematics for our students with an
          intensive focus on reading, writing and arithmetic (the operation of
          numbers).
        </h2>
        {/* Container */}
        <div
          ref={triggerRef}
          className="my-48 scroll-section-outer h-[30rem] md:h-96 xl:h-72 relative border-[3px] border-purple rounded-l-none rounded-r-3xl  sm:mx-5 md:mx-10 overflow-hidden">
          {/* Left side */}
          <div
            ref={infoRef}
            className="absolute left-0 w-[10rem] h-[29.8rem] sm:w-[15rem] md:w-96 md:h-96 xl:h-72 md:text-lg bg-purple rounded-r-3xl sm:rounded-r-full flex justify-center items-center px-5 md:px-10 border-4 border-yellow z-50">
            <p className="text-white font-semibold">
              To achieve this, we centre our lesson delivery with explicit
              teaching, a highly-structured, three-phased approach to teaching
              and learning.
            </p>
          </div>
          {/* END - Left side */}
          {/* Right Side */}
          <div
            ref={wrapperRef}
            className="scroll-section-inner flex my-auto h-full w-[200%] md:w-[150%] ml-[25rem] gap-5">
            {threePhasedApproach.map((approach, index) => (
              <div
                ref={elementRef}
                key={`Approach_${index}`}
                className="scroll-section bg-yellow w-full border-4 border-purple my-4 p-2 rounded-3xl">
                <h2 className="text-8xl text-purple">{approach.number}</h2>
                <h3 className="text-3xl text-blue font-bold mt-5">
                  {approach.heading}
                </h3>
                <p className="text-blue mt-2">{approach.information}</p>
              </div>
            ))}
          </div>
          {/* End - Right Side */}
        </div>
        {/* End - Container */}
        <h2 className="text-blue text-3xl font-bold mt-20 text-center w-[70%] mx-auto">
          Extensive educational research conducted in Australia and
          internationally confirms explicit teaching as the most effective
          approach to building strong foundational subject knowledge, skills,
          and understanding.
        </h2>
        {/* <div id="subject" className="flex flex-col justify-center items-center">
          <img
            className="w-72 h-72"
            src={englishMathArrows}
            alt="English and Math arrows pointing down at the buttons, showing pick one text above the arrows"
          />
          <div className="flex justify-center gap-5 sm:gap-20 text-white ">
            <button
              className="bg-purple p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-purple my-2 hover:bg-yellow hover:ring-yellow"
              onClick={displayEnglish}>
              English
            </button>
            <button
              className="bg-blue p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-blue my-2 hover:bg-yellow hover:ring-yellow"
              onClick={displayMath}>
              Mathematics
            </button>
          </div>
        </div> */}
        {/* ENGLISH MATH SECTIONS */}
        <motion.h1
          style={{ x: xEnglish }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap mt-48">
          English
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
        </motion.h1>
        <EnglishTutoring />
        <motion.h1
          style={{ x: xMath }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap mt-48">
          Mathematics
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
        </motion.h1>
        <MathTutoring />
        {/* {displaySection.english ? <EnglishTutoring /> : null}
        {displaySection.math ? <MathTutoring /> : null}
        {displaySection.english ? (
          <div className="flex justify-center gap-5 sm:gap-20 text-white mt-10">
            <Link to={"subject"} smooth={true} className="text-base text-white">
              <button
                className="bg-blue p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-blue my-2 hover:bg-yellow hover:ring-yellow"
                onClick={displayMath}>
                View Mathematics
              </button>
            </Link>
          </div>
        ) : (
          <div className="flex justify-center gap-5 sm:gap-20 text-white mt-10">
            <Link
              to={"subject"}
              smooth={true}
              className="text-base text-white ">
              <button
                className="bg-purple p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-purple my-2 hover:bg-yellow hover:ring-yellow"
                onClick={displayEnglish}>
                View English
              </button>
            </Link>
          </div>
        )} */}
        {/* END - ENGLISH MATH SECTIONS */}
      </div>
      {/* Private Group tutoring */}
      <div
        // ref={headingRef}
        className="flex flex-col justify-center items-center mt-48">
        <motion.h1
          style={{ x: xOffer }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          What we offer?
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
        </motion.h1>
        {/* <motion.h3
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-lg font-semibold tracking-wider text-purple uppercase">
          Tutoring types
        </motion.h3>
        <motion.h2
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className=" text-5xl font-bold text-blue ">
          What we offer?
        </motion.h2> */}
        <div className="flex flex-col md:flex-row gap-20 mt-20">
          {/* Private */}

          <div className="w-[23rem] h-[55rem] lg:w-[30rem] lg:h-[50rem] border-4 border-purple rounded-3xl flex flex-col justify-center gap-5 px-10 bg-cta">
            <div className="flex flex-col justify-center items-center gap-5">
              <img
                className="w-30 h-20"
                src={privateTutoringIcon}
                alt="an icon of two random people indicating private tutoring"></img>
              {/* <UsersIcon className="w-20 h-20 text-white bg-blue rounded-full p-2" /> */}
              <motion.h3
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                // viewport={{ once: true }}
                className="text-2xl text-blue font-bold">
                <motion.span
                  variants={fadeLeftToRight}
                  initial={"hidden"}
                  whileInView={fadeLeftToRight.visible}
                  transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                  className="before:block before:absolute before:w-60 before:h-5 before:-inset-1 before:-skew-y-3 before:bg-yellow before:bg-opacity-80 relative inline-block"></motion.span>
                <span className="relative text-blue uppercase">
                  Private Tutoring
                </span>
              </motion.h3>
              <motion.p
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                // viewport={{ once: true }}
                className=" text-lg text-gray-500 mt-5">
                Highly individualised one-to-one tutoring enables targeted
                instruction where every session is tailored to our students’
                individual learning needs and goals. This includes but not
                limited to:
              </motion.p>
            </div>
            <motion.ul
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-lg text-gray-500 list-disc flex flex-col gap-2 pl-10">
              <li>Bridging learning gaps</li>
              <li>Revision</li>
              <li>Extending knowledge</li>
              <li>Homework assistance</li>
            </motion.ul>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-base text-gray-500 italic">
              Any questions? Contact us on{" "}
              <span className="hover:text-yellow">
                <a href="tel:0456 206 990">0456 206 990</a>
              </span>{" "}
              or at{" "}
              <span className="hover:text-yellow">
                <a href="mailto:tutorwo.tutoring@gmail.com">
                  tutorwo.tutoring@gmail.com
                </a>
              </span>
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-base text-gray-500 italic">
              For added convenience, complete the online form and one of our
              representatives will be in touch with you.
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex justify-center mt-5">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeka3ASGbWgsnAhF2K8r6j2azn7siTUW68SsRMpgLaiMJQC-A/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer">
                <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Find a Private Tutor
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </motion.div>
          </div>
          {/* END - Private */}
          {/* Group */}
          <div className="w-[23rem] h-[83rem] lg:w-[30rem] lg:h-[67rem] border-4 border-purple rounded-3xl flex flex-col justify-start pt-10 items-center gap-1 px-10 bg-cta">
            <img
              className="w-30 h-20"
              src={groupTutoringIcon}
              alt="an icon of three random people indicating group tutoring"></img>
            {/* <UsersIcon className="w-20 h-20 text-white bg-blue rounded-full p-2" /> */}
            <motion.h3
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-2xl text-blue font-bold">
              <motion.span
                variants={fadeLeftToRight}
                initial={"hidden"}
                whileInView={fadeLeftToRight.visible}
                transition={{ duration: 1 }}
                // viewport={{ once: true }}
                className="before:block before:absolute before:w-60 before:left-6 md:before:left-0 md:before:w-96 before:top-12 md:before:top-10 before:h-5 before:-inset-1 before:-skew-y-3 before:bg-orange before:bg-opacity-80 relative inline-block"></motion.span>
              <span className="flex flex-col text-center">
                <span className="relative text-blue uppercase">
                  SMALL GROUP MASTERCLASSES
                </span>
                <span className="relative text-blue uppercase text-sm">
                  SECONDARY SCHOOL PREPARATION FOR YEARS 5 AND 6
                </span>
              </span>
            </motion.h3>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className=" text-lg text-gray-500 mt-7 mb-3">
              These masterclasses are targeted at Years 5 and 6 students,
              ensuring they commence secondary schooling in Year 7 with the
              solid foundational skills in English and Mathematics.
            </motion.p>
            <motion.ul
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md font-bold flex gap-4 mt-3 mb-1">
              <li className="text-purple border-2 border-purple px-2 rounded-full">
                English
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                Mathematics
              </li>
            </motion.ul>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500 font-bold my-2">
              Masterclasses will run on a weekly basis on most weeks during
              school terms.
            </motion.p>
            <motion.table
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              className="flex border-2 border-purple rounded-2xl p-[0.53rem] w-[102%]">
              <tbody className="text-sm text-gray-500 flex flex-col gap-1">
                {/* Term 1 */}
                <tr>
                  <td className="font-bold text-purple underline underline-offset-4">
                    Term 1
                  </td>
                </tr>
                <tr>
                  <td>Monday, 5 February 2024 - Thursday, 28 March 2024</td>
                </tr>
                {/* Term 2 */}
                <tr>
                  <td className="font-bold text-blue  underline underline-offset-4">
                    Term 2
                  </td>
                </tr>
                <tr>
                  <td>Monday, 15 April 2024 - Friday, 28 June 2024</td>
                </tr>
                {/* Term 3 */}
                <tr>
                  <td className="font-bold text-purple  underline underline-offset-4">
                    Term 3
                  </td>
                </tr>
                <tr>
                  <td>Monday, 15 July 2024 - Friday, 20 September 2024</td>
                </tr>
                {/* Term 4 */}
                <tr>
                  <td className="font-bold text-blue  underline underline-offset-4">
                    Term 4
                  </td>
                </tr>
                <tr>
                  <td>Monday, 7 October 2024 - Friday, 1 December 2024 </td>
                </tr>
              </tbody>
            </motion.table>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-sm text-gray-500 my-2 italic">
              The days and times for the weekly masterclasses will be determined
              on the availability of the tutor/s and the majority of students.
              Confirmation will be made in late January 2024.
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto font-bold text-md text-purple mt-4">
              Investment
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500">
              $50 per 90-minute masterclass per week
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto font-bold text-md text-purple mt-2">
              Venue
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500">
              In-person in the City of Monash
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex justify-center mt-8">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeJo6fsgDHQILrkbkmN54UHeRFgcXKcS329t-0F9ObBuCwCgQ/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer">
                <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Expression of Interest
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </motion.div>
          </div>
          {/* END - Group */}
        </div>
      </div>
      {/* End - Private group tutoring */}

      <OnlineLearning />
    </section>
  );
};

export default LowerLevelTutoring;
