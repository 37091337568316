import React from "react";
import { CameraIcon } from "@heroicons/react/24/solid";
import julian from "./assets/julianJomTransparentDecorated.png";
import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";
import { motion } from "framer-motion";
import { fadeRightToLeft, fadeUp } from ".";

const Julian = ({ julianRef }) => {
  return (
    <div
      id="julian"
      ref={julianRef}
      className="relative bg-white overflow-hidden mt-20">
      {/* Shapes decoration */}
      <CircleGrid
        position="absolute"
        top="10rem"
        color="#863d90"
        size="80px"
        className="hidden md:inline"
        zIndex={0}
      />
      <CircleGrid
        position="absolute"
        right="0"
        top="17rem"
        color="#863d90"
        size="80px"
        className="hidden md:inline"
        zIndex={0}
      />
      <Donut
        color="#863d90"
        top="6rem"
        left="1rem"
        size="4rem"
        className="hidden lg:inline lg:animate-shape"
        width={["0.5rem", "1rem", "1rem", "1rem"]}
        zIndex={0}
      />
      <Donut
        color="#863d90"
        top="17rem"
        right="1rem"
        size="4rem"
        className="hidden lg:inline lg:animate-shape"
        width={["0.5rem", "1rem", "1rem", "1rem"]}
        zIndex={0}
      />

      <div className="relative max-w-7xl mx-auto py-2 lg:py-10 px-4 sm:px-6 lg:px-8">
        {/* <CircleGrid
          position="absolute"
          top="6rem"
          right="1rem"
          color="#0B4697"
          className="hidden lg:inline"
          size="175px"
          zIndex={0}
        />
        <CircleGrid
          position="absolute"
          top="3rem"
          right="1rem"
          color="#0B4697"
          size="175px"
          className="hidden lg:inline"
          zIndex={0}
        /> */}
        <div className="mt-4 lg:grid lg:grid-cols-2 lg:gap-8 lg:ml-20">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              viewport={{ once: true }}
              transition={{ duration: 1.5, delay: 0.2 }}
              className="lg:hidden font-bold text-blue tracking-tight text-5xl pb-10 text-center">
              Our Leadership
            </motion.p>
            <div className="relative pl-2 md:pl-20 sm:w-[90%] lg:mt-20 text-base mx-auto max-w-prose lg:max-w-none lg:ml-20">
              <figure>
                <motion.div
                  variants={fadeRightToLeft}
                  initial={"hidden"}
                  whileInView={fadeRightToLeft.visible}
                  transition={{ duration: 1.5 }}
                  viewport={{ once: true }}
                  className="w-[80%] h-[80%] mx-auto">
                  <img
                    className="rounded-lg"
                    src={julian}
                    alt="Julian Jom - Founder of TutorWo"
                    width={1000}
                    height={1000}
                  />
                </motion.div>

                {/* <motion.figcaption
                  variants={fadeUp}
                  initial={"hidden"}
                  whileInView={fadeUp.visible}
                  transition={{ duration: 1.5 }}
                  className="mt-3 flex text-sm text-gray-500">
                  <CameraIcon
                    className="flex-none w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2">Julian Jom - Founder of TutorWo</span>
                </motion.figcaption> */}
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0 sm:text-left">
            {/* <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-blue font-bold">
                I have wealth of experience and success in educational and
                organisational leadership, teaching and learning, innovation,
                and staff development.
              </p>
            </div> */}
            <div className="mt-5 prose prose-indigo mx-5 md:mx-20 lg:mx-0 text-gray-500 lg:max-w-none lg:row-start-1 lg:col-start-1">
              {/* <motion.h2
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                viewport={{ once: true }}
                transition={{ duration: 1.5 }}
                className="text-lg lg:text-left font-semibold tracking-wider text-purple uppercase my-2">
                The Founder
              </motion.h2> */}
              <motion.p
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                viewport={{ once: true }}
                transition={{ duration: 1.5, delay: 0.2 }}
                className="hidden lg:flex lg:text-left font-bold text-blue tracking-tight text-5xl mb-5">
                Our Leadership
              </motion.p>
              <motion.div
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                viewport={{ once: true }}
                transition={{ duration: 1.5, delay: 0.4 }}>
                <p className="my-2 text-xl">
                  Our founder and director, Julian Jom, holds a Master of
                  Education (Educational Management) from the University of
                  Melbourne and possesses extensive teaching and educational
                  leadership experience across two of Australia’s leading
                  independent schools in Melbourne’s southeast.
                </p>
                <p className="my-2 text-xl">
                  One of Julian’s niches is his proven track record in leading
                  teaching teams to producing strong student academic results
                  driven by cutting-edge, research-based pedagogical practices.
                  He has coached, mentored, and trained many teachers in best
                  practice as a school middle leader. Julian was also previously
                  a sessional lecturer at one of Melbourne’s tertiary
                  institutions where he taught student teachers who were
                  completing their Bachelor of Education and Master of Teaching
                  degrees.
                </p>
                <p className="my-2 text-xl">
                  Julian’s wealth of experience and success in educational and
                  organisational leadership, teaching and learning, innovation,
                  and staff development and capacity building are instrumental
                  to ensuring the highest quality of teaching and learning at
                  TutorWo.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Julian;
