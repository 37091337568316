import React, { useEffect, useRef } from "react";
import { fadeLeftToRight, fadeUp } from "../..";
import { motion, useScroll, useTransform } from "framer-motion";
import examPreparationCircleBlueTick from "../../assets/examPreparationCircleBlueTick.png";
import scholarshipExam from "../../assets/scholarshipSelectiveExamIllustration.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { SparklesIcon } from "@heroicons/react/20/solid";
// Services Icon
import allInclusiveExpertise from "../../assets/ServicesIcons/all-inclusive-expertise.png";
import dualFocusedCirriculum from "../../assets/ServicesIcons/dual-focused-cirriculum.png";
import resourceRichMaterials from "../../assets/ServicesIcons/resource-rich-materials.png";
import holisticGrowth from "../../assets/ServicesIcons/holistic-growth.png";
import privateTutoringIcon from "../../assets/ServicesIcons/private-tutoring-icon.png";
import groupTutoringIcon from "../../assets/ServicesIcons/group-tutoring-icon.png";
import ServicesHero from "../../ServicesHero";
import OnlineLearning from "../../OnlineLearning";
import { useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

const ScholarshipSelectiveSchool = () => {
  // const [width, setWidth] = useState(0);

  // const className = {
  //   svgPathLine: "pathLine",
  //   svgStartingPoint: "startingPoint",
  //   hidden: "",
  // };
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
  const comp = useRef();
  const [timeOut, setTimeOut] = useState(false);
  const setTime = () => {
    const timeOutFunction = () => {
      ScrollTrigger.refresh();
      setTimeOut(true);
    };

    setTimeout(timeOutFunction, 1000);
  };
  useEffect(() => {
    // let ctx = gsap.context(() => {
    setTime();
    const textOpacity = gsap.timeline({
      defaults: {
        opacity: 1,
      },
      scrollTrigger: {
        trigger: "#timeline",
        scrub: true,
        start: "top center",
        end: "90% center",
        // pin: true,
        // markers: true,
      },
    });

    // textOpacity.scrollTrigger.refresh();

    textOpacity
      .to(".text01", {}, 0)
      .to(".text02", {}, 0.3)
      .to(".text03", {}, 0.5)
      .to(".text04", {}, 0.8);

    const pathMotion = gsap.timeline({
      scrollTrigger: {
        trigger: ".startingPoint",
        scrub: true,
        start: "top center",
        end: "5200% center",
        // markers: true,
      },
    });

    pathMotion.scrollTrigger.refresh();

    pathMotion.to(
      "#svg",
      {
        motionPath: {
          path: ".pathLine",
          align: ".pathLine",
          alignOrigin: [0.5, 0.5],
        },
        duration: 2,
      },
      0
    );

    // setWidth(window.innerWidth);
    // }, comp); // <- IMPORTANT! Scopes selector text

    // return () => ctx.revert(); // cleanup
  }, [timeOut]);

  const headingRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    target: headingRef,
    offset: ["start center", "end end"],
  });

  const x = useTransform(scrollYProgress, [0, 1], [-200, 500]);

  return (
    <section
      ref={comp}
      className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Exam Preparation"
        secondHeading="Scholarship and Selective Entry Schools Examination Preparation"
        headingDescription="Is your child aiming to secure a spot in one of the most prestigious selective entry schools or gain a scholarship at an excelling secondary school? Witness their academic journey reach new heights with our comprehensive program!"
        leftTag="Scholarship Exam Readiness"
        rightTag="Selective Entry Exam Training"
        image={scholarshipExam}
        imageDescription="Illustration of scholarship selective school exam"
      />
      {/* Wave svg TOP */}
      <svg
        style={{
          marginTop: "-1px",
          marginBottom: "-2px",
          strokeWidth: 0,
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320">
        <path
          fill="#863d90"
          stroke="none"
          strokeOpacity="0"
          fillOpacity="1"
          d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,186.7C672,171,768,181,864,202.7C960,224,1056,256,1152,250.7C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      {/* END - Wave svg TOP */}
      <div className="bg-purple w-full pl-10 lg:pl-36 relative flex pt-10 bg-hero">
        {/* LEFT SIDE */}
        <div id="timeline">
          {/* BLUE CIRCLE WITH TEXT - EXAM PREPARATION */}
          <img
            id="svg"
            className="absolute top-5 left-3 lg:left-28 w-24 h-24 z-50"
            src={examPreparationCircleBlueTick}
            alt="round circle with blue tick with text 'exam preparation'"></img>
          {/* END - BLUE CIRCLE WITH TEXT - EXAM PREPARATION */}
          {/* Timeline */}
          {}
          <svg
            width="43"
            height="1627"
            viewBox="0 0 43 1627"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              className="pathLine"
              d="M21.0001 21L21.0001 1619"
              stroke="white"
              strokeWidth="10"
              strokeLinecap="round"
            />
            <path
              className="startingPoint"
              d="M41 21C41 31.4934 32.4934 40 22 40C11.5066 40 3 31.4934 3 21C3 10.5066 11.5066 2 22 2C32.4934 2 41 10.5066 41 21Z"
              fill="#0B4697"
              stroke="white"
              strokeWidth="4"
            />
            <circle
              cx="21"
              cy="231"
              r="19"
              fill="#FCC219"
              stroke="white"
              strokeWidth="4"
            />
            <circle
              cx="22"
              cy="698"
              r="19"
              fill="#FCC219"
              stroke="white"
              strokeWidth="4"
            />
            <circle
              cx="22"
              cy="1257"
              r="19"
              fill="#FCC219"
              stroke="white"
              strokeWidth="4"
            />
            <circle
              cx="22"
              cy="1606"
              r="19"
              fill="#FF914D"
              stroke="white"
              strokeWidth="4"
            />
          </svg>
          {/* END- Timeline */}
        </div>
        {/* END - LEFT SIDE */}
        {/* RIGHT SIDE TEXT */}
        <div className="flex flex-col items-center">
          <h1 className="ml-10 sm:ml-0 text-5xl font-bold text-white">
            <span>
              <SparklesIcon className="mr-5 w-10 h-10 inline text-yellow" />
            </span>
            Our Program
            <span>
              <SparklesIcon className="ml-5 w-10 h-10 inline text-yellow" />
            </span>
          </h1>
          {/* BOX 1 */}
          <div className="text01 opacity-0 w-[90%] md:w-[80%] mt-20 bg-white border-4 border-yellow rounded-2xl p-5 shadow-xl ml-2 sm:ml-8 md:ml-20">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-5">
                <img
                  className="w-16 h-16"
                  src={allInclusiveExpertise}
                  alt="an icon of three circles connected indicating all inclusive"
                />
                <h1 className="text-yellow font-bold text-xl md:text-3xl">
                  All-inclusive Expertise
                </h1>
              </div>
              <p className="text-gray-500 text-sm md:text-base">
                Our team of exceptional tutors will bring a wealth of experience
                to the table. Having all attended a selective entry school, they
                will be able to provide the most relevant and accurate guidance
                to your child. In addition to helping them prepare for the
                exams, our tutors will be able to share their personal
                experiences of attending these schools which will foster a deep
                sense of motivation in your child.
              </p>
            </div>
          </div>
          {/* END - BOX 1 */}
          {/* BOX 2 */}
          <div className="text02 opacity-0 w-[90%] md:w-[80%] mt-12 md:mt-[12rem] bg-white border-4 border-orange rounded-2xl p-5 shadow-xl ml-2 sm:ml-8 md:ml-20">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-5">
                <img
                  className="w-16 h-16"
                  src={dualFocusedCirriculum}
                  alt="an icon of four squares two of them with same color indicating dual focused"
                />
                <h1 className="text-orange font-bold text-xl md:text-3xl">
                  Dual-focused Curriculum
                </h1>
              </div>
              <div className="text-gray-500 text-sm md:text-base flex flex-col gap-2">
                <p>
                  Our program is uniquely tailored to address all aspects of
                  scholarship and selective entry exams. This includes:
                </p>
                <ul className="pl-10 list-disc">
                  <li>Reading Comprehension</li>
                  <li>Verbal Reasoning</li>
                  <li>Writing (Creative, Persuasive and Scientific)</li>
                  <li>Mathematics</li>
                  <li>Numerical Reasoning</li>
                  <li>Science Reasoning</li>
                  <li>
                    General exam strategies (including but not limited to time
                    management)
                  </li>
                </ul>
                <p>
                  In addition, regular mock exams simulate real test conditions,
                  providing your child with valuable exam techniques and
                  boosting their confidence.
                </p>
              </div>
            </div>
          </div>
          {/* END - BOX 2 */}
          {/* BOX 3 */}
          <div className="text03 opacity-0 w-[90%] md:w-[80%] mt-12 md:mt-[8rem] lg:mt-[17rem] bg-white border-4 border-blue rounded-2xl p-5 shadow-xl ml-2 sm:ml-8 md:ml-20">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-5">
                <img
                  className="w-16 h-16"
                  src={resourceRichMaterials}
                  alt="an icon of a folder with document sign on the outside indicating resource rich material"
                />
                <h1 className="text-blue font-bold text-xl md:text-3xl">
                  Resource-rich Materials
                </h1>
              </div>
              <p className="text-gray-500 text-sm md:text-base">
                Our curated study materials, encompassing practice papers and
                supplemental resources, empower students to reinforce their
                learning and excel beyond tutoring sessions.
              </p>
            </div>
          </div>
          {/* END - BOX 3 */}
          {/* BOX 4 */}
          <div className="text04 opacity-0 w-[90%] md:w-[80%] mt-16 md:mt-[10rem] lg:mt-[12rem] bg-white border-4 border-yellow rounded-2xl p-5 shadow-xl ml-2 sm:ml-8 md:ml-20">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-5">
                <img
                  className="w-16 h-16"
                  src={holisticGrowth}
                  alt="an icon of a chart line moving upwards with a star at bottom right indicating holistic growth"
                />
                <h1 className="text-yellow font-bold text-xl md:text-3xl">
                  Holistic Growth
                </h1>
              </div>
              <p className="text-gray-500 text-sm md:text-base">
                Beyond academics, our program recognises the importance of
                cultivating essential life skills like critical thinking,
                problem-solving and effective communication - vital attributes
                that set students up for success in any endeavour!
              </p>
            </div>
          </div>
          {/* END - BOX 4 */}
        </div>
      </div>
      {/* END - RIGHT SIDE TEXT */}
      {/* Wave svg BOTTOM */}
      <svg
        style={{
          marginTop: "-1px",
          marginBottom: "-2px",
          strokeWidth: 0,
        }}
        className="rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320">
        <path
          fill="#863d90"
          stroke="none"
          strokeOpacity="0"
          fillOpacity="1"
          d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,186.7C672,171,768,181,864,202.7C960,224,1056,256,1152,250.7C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      {/* END - Wave svg BOTTOM */}
      <div
        ref={headingRef}
        className=" flex flex-col justify-center items-center">
        <motion.h1
          style={{ x }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap mt-20">
          What we offer?
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
        </motion.h1>
        {/* <motion.h3
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-lg font-semibold tracking-wider text-purple uppercase">
          Tutoring options
        </motion.h3> */}
        {/* <motion.h2
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className=" text-5xl font-bold text-blue ">
          What we offer?
        </motion.h2> */}
        {/* Private & Group tutoring */}
        <div className="flex flex-col lg:flex-row gap-20 mt-20">
          {/* Private */}

          <div className="w-[24rem] h-[59rem] lg:w-[30rem] lg:h-[55rem] border-4 border-purple rounded-3xl flex flex-col justify-center items-center gap-5 px-10 bg-cta">
            <img
              className="w-30 h-20"
              src={privateTutoringIcon}
              alt="an icon of two random people indicating private tutoring"></img>
            {/* <UsersIcon className="w-20 h-20 text-white bg-blue rounded-full p-2" /> */}
            <motion.h3
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-2xl text-blue font-bold">
              <motion.span
                variants={fadeLeftToRight}
                initial={"hidden"}
                whileInView={fadeLeftToRight.visible}
                transition={{ duration: 1 }}
                // viewport={{ once: true }}
                className="before:block before:absolute before:w-60 before:h-5 before:-inset-1 before:-skew-y-3 before:bg-yellow before:bg-opacity-80 relative inline-block"></motion.span>
              <span className="relative text-blue uppercase">
                Private Tutoring
              </span>
            </motion.h3>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className=" text-lg text-gray-500 mt-5">
              Highly individualised one-to-one tutoring enables targeted
              instruction where every session is tailored to your child’s
              specific learning needs and goals in the lead up to the exam. This
              includes but not limited to:
            </motion.p>
            <motion.ul
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-lg text-gray-500 pl-5 list-disc flex flex-col gap-5">
              <li>
                Devoting more time to areas of the exam where your child needs
                the most help.
              </li>
              <li>
                Receiving personalised, comprehensive and highly-targeted
                feedback so your child knows exactly what they need to do to
                produce winning answers and responses to exam questions.
              </li>
            </motion.ul>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-base text-gray-500 italic">
              Any questions? Contact us on{" "}
              <span className="hover:text-yellow">
                <a href="tel:0456 206 990">0456 206 990</a>
              </span>{" "}
              or at{" "}
              <span className="hover:text-yellow">
                <a href="mailto:tutorwo.tutoring@gmail.com">
                  tutorwo.tutoring@gmail.com
                </a>
              </span>
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-base text-gray-500 italic">
              For added convenience, complete the online form and one of our
              representatives will be in touch with you.
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex justify-center mt-5">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeka3ASGbWgsnAhF2K8r6j2azn7siTUW68SsRMpgLaiMJQC-A/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer">
                <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Find a Private Tutor
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </motion.div>
          </div>
          {/* END - Private */}
          {/* Group */}
          <div className="w-[24rem] h-[80rem] lg:w-[30rem] lg:h-[68rem] border-4 border-purple rounded-3xl flex flex-col justify-start pt-10 items-center gap-1 px-10 bg-cta">
            <img
              className="w-30 h-20"
              src={groupTutoringIcon}
              alt="an icon of three random people indicating group tutoring"></img>
            {/* <UsersIcon className="w-20 h-20 text-white bg-blue rounded-full p-2" /> */}
            <motion.h3
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-2xl text-blue font-bold">
              <motion.span
                variants={fadeLeftToRight}
                initial={"hidden"}
                whileInView={fadeLeftToRight.visible}
                transition={{ duration: 1 }}
                // viewport={{ once: true }}
                className="before:block before:absolute before:w-60 before:left-6 md:before:left-0 lg:before:w-96 before:top-12 md:before:top-10 before:h-5 before:-inset-1 before:-skew-y-3 before:bg-orange before:bg-opacity-80 relative"></motion.span>
              <span className="flex flex-col text-center">
                <span className="relative text-blue uppercase">
                  SMALL GROUP MASTERCLASSES
                </span>
                <span className="relative text-blue uppercase text-sm">
                  VICTORIAN SELECTIVE ENTRY SCHOOL EXAMINATION PREPARATION
                </span>
              </span>
            </motion.h3>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className=" text-lg text-gray-500 mt-7 mb-3">
              These masterclasses are intended for 2024 Year 8 students who will
              be sitting for the examination in 2024 for 2025 Year 9 entry into
              one of the 4 Victorian selective entry high schools:
            </motion.p>
            <motion.ul
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto ml-10 text-md flex flex-col gap-2 mb-2 text-gray-500 font-bold">
              <li className="text-purple pl-2 list-disc">
                Melbourne High School
              </li>
              <li className="text-blue pl-2 list-disc">
                The Mac.Robertson Girls’ High School
              </li>
              <li className="text-purple pl-2 list-disc">Nossal High School</li>
              <li className="text-blue pl-2 list-disc">
                Suzanne Cory High School
              </li>
            </motion.ul>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500 my-2">
              Masterclasses will run on a weekly basis on most weeks during
              Terms 1 and 2
            </motion.p>
            <motion.table
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              className="flex border-2 border-purple rounded-2xl p-[0.53rem] w-[102%]">
              <tbody className="text-sm text-gray-500 flex flex-col gap-1">
                {/* Term 1 */}
                <tr>
                  <td className="font-bold text-purple underline underline-offset-4">
                    Term 1
                  </td>
                </tr>
                <tr>
                  <td>Monday, 5 February 2024 - Thursday, 28 March 2024</td>
                </tr>
                {/* Term 2 */}
                <tr>
                  <td className="font-bold text-blue  underline underline-offset-4">
                    Term 2
                  </td>
                </tr>
                <tr>
                  <td>Monday, 15 April 2024 - Friday, 28 June 2024</td>
                </tr>
              </tbody>
            </motion.table>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-sm text-gray-500 mt-2 italic">
              The days and times for the weekly masterclasses will be determined
              on the availability of the tutor/s and the majority of students.
              Confirmation will be made in late January 2024.
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto font-bold text-md text-purple mt-4">
              Investment
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500">
              $50 per 90-minute masterclass per week
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto font-bold text-md text-purple mt-2">
              Venue
            </motion.p>
            <motion.ul
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500 pl-2 list-disc ml-5">
              <li>Online</li>
              <li>In-person in the City of Monash</li>
            </motion.ul>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex justify-center mt-8">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeJo6fsgDHQILrkbkmN54UHeRFgcXKcS329t-0F9ObBuCwCgQ/viewform?usp=sf_link "
                target="_blank"
                rel="noreferrer">
                <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Expression of Interest
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </motion.div>
          </div>
          {/* END - Group */}
        </div>
        {/* END Private and group tutoring box */}
      </div>
      {/* ENd - tutoring options - private and group */}
      <OnlineLearning />
    </section>
  );
};

export default ScholarshipSelectiveSchool;
