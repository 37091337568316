import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import {
  childSafeWellbeing,
  childSafeConduct,
  covidSafePlan,
  privacyPolicy,
  cancellationPolicy,
  refundPolicy,
} from "../../";

const Policies = () => {
  const policies = {
    c: [
      {
        name: "Child Safe and Wellbeing Policy",
        href: childSafeWellbeing,
      },
      { name: "Child Safe Code of Conduct", href: childSafeConduct },
      {
        name: "COVIDSafe Plan for In Person Learning",
        href: covidSafePlan,
      },
    ],
    p: [
      { name: "Privacy Policy", href: privacyPolicy },
      {
        name: "Private Tutoring Lesson Cancellation Policy",
        href: cancellationPolicy,
      },
    ],
    r: [{ name: "Refund Policy", href: refundPolicy }],
  };
  return (
    <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div className="max-w-max lg:max-w-7xl  mx-auto ">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="relative ">
            {/* Top right */}
            <CircleGrid
              position="absolute"
              right="-2rem"
              top="-2.5rem"
              color="#863d90"
              size="50px"
              className="hidden md:inline"
              zIndex={0}
            />

            {/* Top left */}
            <CircleGrid
              position="absolute"
              left="-2rem"
              bottom="-2.5rem"
              color="#863d90"
              className="hidden md:inline"
              size="80px"
              zIndex={0}
            />
            {/* Center right */}
            <CircleGrid
              position="absolute"
              right="-4rem"
              bottom="-15rem"
              color="#863d90"
              size="80px"
              className="hidden md:inline"
              zIndex={0}
            />
            {/* Bottom left */}
            <CircleGrid
              position="absolute"
              left="-4rem"
              bottom="-20rem"
              color="#863d90"
              className="hidden md:inline"
              size="50px"
              zIndex={0}
            />
            {/* Top left */}
            <Donut
              color="#863d90"
              top="7rem"
              left="-3rem"
              size="4rem"
              width={["0.5rem", "1rem", "1rem", "1rem"]}
              className="hidden lg:inline lg:animate-shape"
              zIndex={0}
            />
            {/* Bottom right */}
            <Donut
              color="#863d90"
              top="15rem"
              right="-3rem"
              size="4rem"
              width={["0.5rem", "1rem", "1rem", "1rem"]}
              className="hidden lg:inline lg:animate-shape"
              zIndex={0}
            />
          </div>
          <div className="text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Policies
            </h2>
            <p className="mb-5 sm:mb-0 text-center mt-2 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
              TutorWo Policies
            </p>
            <div className="relative mt-2 md:bg-white flex flex-col justify-center items-center">
              <ul className="pl-8 lg:pl-20 mt-8 lg:mt-16 space-y-12 text-lg text-gray-500">
                {policies.c.map((item) => (
                  <li key={item.name}>
                    <a
                      className=" hover:text-yellow flex items-center"
                      href={item.href}
                      target="_blank"
                      rel="noreferrer">
                      <ArrowTopRightOnSquareIcon
                        className="flex-none w-5 h-5 mr-5 stroke-gray-500 stroke-2"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
                {policies.p.map((item) => (
                  <li key={item.name}>
                    <a
                      className=" hover:text-yellow flex items-center"
                      href={item.href}
                      target="_blank"
                      rel="noreferrer">
                      <ArrowTopRightOnSquareIcon
                        className="flex-none w-5 h-5 mr-5 stroke-gray-500 stroke-2"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
                {policies.r.map((item) => (
                  <li key={item.name}>
                    <a
                      className=" hover:text-yellow flex items-center"
                      href={item.href}
                      target="_blank"
                      rel="noreferrer">
                      <ArrowTopRightOnSquareIcon
                        className="flex-none w-5 h-5 mr-5 stroke-gray-500 stroke-2"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              {/* <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center my-5">
                <p>
                  TutorWo is committed to providing quality services to you and
                  this policy outlines our ongoing obligations to you in respect
                  of how we manage your Personal Information. We have adopted
                  the Australian Privacy Principles (APPs) contained in the
                  Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the
                  way in which we collect, use, disclose, store, secure and
                  dispose of your Personal Information. A copy of the Australian
                  Privacy Principles may be obtained from the website of The
                  Office of the Australian Information Commissioner at
                  www.aoic.gov.au
                </p>
              </div> */}
            </div>
          </div>
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Personal Information
            </h2>
            <div className="relative mt-2 md:bg-white  w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  Personal Information is information or an opinion that
                  identifies an individual. Examples of Personal Information we
                  collect include: names, addresses, email addresses, phone and
                  facsimile numbers. This Personal Information is obtained in
                  many ways including interviews, correspondence, by telephone
                  and facsimile, by email, via our website www.tutorwo.com and
                  from third parties. We don’t guarantee website links or policy
                  of authorised third parties. We collect your Personal
                  Information for the primary purpose of providing our services
                  to you, providing information to our clients and marketing. We
                  may also use your Personal Information for secondary purposes
                  closely related to the primary purpose, in circumstances where
                  you would reasonably expect such use or disclosure. You may
                  unsubscribe from our mailing/marketing lists at any time by
                  contacting us in writing. When we collect Personal Information
                  we will, where appropriate and where possible, explain to you
                  why we are collecting the information and how we plan to use
                  it.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Sensitive Information
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  Sensitive information is defined in the Privacy Act to include
                  information or opinion about such things as an individual’s
                  racial or ethnic origin, political opinions, membership of a
                  political association, religious or philosophical beliefs,
                  membership of a trade union or other professional body,
                  criminal record or health information. Sensitive information
                  will be used by us only: For the primary purpose for which it
                  was obtained For a secondary purpose that is directly related
                  to the primary purpose With your consent; or where required or
                  authorised by law.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Third Parties
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  Where reasonable and practicable to do so, we will collect
                  your Personal Information only from you. However, in some
                  circumstances we may be provided with information by third
                  parties. In such a case we will take reasonable steps to
                  ensure that you are made aware of the information provided to
                  us by the third party.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Disclosure of Personal Information
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  Your Personal Information may be disclosed in a number of
                  circumstances including the following:
                </p>
                <ul className="list-disc list-inside ">
                  <li>
                    Third parties where you consent to the use or disclosure
                  </li>
                  <li>Where required or authorised by law</li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Security of Personal Information
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  Your Personal Information is stored in a manner that
                  reasonably protects it from misuse and loss and from
                  unauthorized access, modification or disclosure. When your
                  Personal Information is no longer needed for the purpose for
                  which it was obtained, we will take reasonable steps to
                  destroy or permanently de-identify your Personal Information.
                  However, most of the Personal Information is or will be stored
                  in client files which will be kept by us for a minimum of 7
                  years.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Access to your Personal Information
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  You may access the Personal Information we hold about you and
                  to update and/or correct it, subject to certain exceptions. If
                  you wish to access your Personal Information, please contact
                  us in writing. TutorWo will not charge any fee for your access
                  request but may charge an administrative fee for providing a
                  copy of your Personal Information. In order to protect your
                  Personal Information, we may require identification from you
                  before releasing the requested information.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Maintaining the Quality of your Personal Information
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  It is an important to us that your Personal Information is up
                  to date. We will take reasonable steps to make sure that your
                  Personal Information is accurate, complete and up to date. If
                  you find that the information we have is not up to date or is
                  inaccurate, please advise us as soon as practicable so we can
                  update our records and ensure we can continue to provide
                  quality services to you.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Policy Updates
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  This Policy may change from time to time and is available on
                  our website.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
          {/* <div className="mt-20 text-base max-w-prose lg:max-w-none">
            <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
              Privacy Policy Complaints and Enquiries
            </h2>
            <div className="relative md:bg-white p-2 w-[80%] m-auto">
              <div className="prose prose-indigo prose-lg  text-gray-500 lg:max-w-none text-center ">
                <p>
                  If you have any queries or complaints about our Privacy
                  Policy, please contact us.
                </p>
              </div>
            </div>
          </div> */}
          {/* ENDS HERE */}
        </div>
      </div>
    </div>
  );
};

export default Policies;
