export { default as NavBar } from "./Navbar";
export { default as Home } from "./pages/Home";
export { default as PrivateTutoring } from "./pages/oldServices/PrivateTutoring";
export { default as MasterClasses } from "./pages/oldServices/MasterClasses";
// export { default as FindTutor } from "./pages/FindTutor";
export { default as Faq } from "./pages/Faq";
export { default as OurTutors } from "./pages/OurTutors";
export { default as ChildProtection } from "./pages/compliance/ChildProtection";
export { default as Privacy } from "./pages/compliance/Privacy";
export { default as TermsConditions } from "./pages/compliance/TermsConditions";
export { default as WorkWithUs } from "./pages/WorkWithUs";
export { default as Contact } from "./pages/Contact";
export { default as Footer } from "./Footer";

// Components
export { default as Hero } from "./Hero";
export { default as About } from "./About";
export { default as Julian } from "./Julian";
export { default as WhyUs } from "./WhyUs";

// Tutor image

export { default as jade } from "./assets/tutors/Jade.jpg";
export { default as omar } from "./assets/tutors/Omar.jpeg";
export { default as ruby } from "./assets/tutors/Ruby.jpeg";
export { default as justine } from "./assets/tutors/Justine.jpg";
export { default as yiChen } from "./assets/tutors/YiChen.jpg";
export { default as winnie } from "./assets/tutors/Winnie.jpg";
export { default as fiona2 } from "./assets/tutors/Fiona2.jpg";
export { default as tristina } from "./assets/tutors/Tristina.jpeg";
export { default as tina } from "./assets/tutors/Tina.jpeg";
export { default as sucharita } from "./assets/tutors/Sucharita.jpeg";
export { default as matthew } from "./assets/tutors/Matthew.jpeg";
export { default as cathryn } from "./assets/tutors/Cathryn.jpg";
export { default as carol1 } from "./assets/tutors/Carol1.jpg";
export { default as sammi } from "./assets/tutors/Sammi.jpg";
export { default as catherine } from "./assets/tutors/Catherine.jpeg";
export { default as charmaine } from "./assets/tutors/Charmaine.jpeg";
export { default as lorraine } from "./assets/tutors/Lorraine.jpeg";
export { default as ruoChen } from "./assets/tutors/RuoChen.JPG";
export { default as ahelee } from "./assets/tutors/Ahelee.jpg";
export { default as amelia } from "./assets/tutors/Amelia.jpg";
export { default as andy } from "./assets/tutors/Andy.jpg";
export { default as carol2 } from "./assets/tutors/Carol2.JPG";
export { default as diya } from "./assets/tutors/Diya.jpg";
export { default as parkhi } from "./assets/tutors/Pakhi.jpg";
export { default as sylvia } from "./assets/tutors/Sylvia.jpeg";
export { default as priyenka } from "./assets/tutors/Priyenka.jpg";
export { default as venus } from "./assets/tutors/Venus.jpg";
export { default as zoe } from "./assets/tutors/Zoe.jpeg";
export { default as ardhan } from "./assets/tutors/Ardhan.jpg";
export { default as helene } from "./assets/tutors/Helene.jpg";
export { default as rachel } from "./assets/tutors/Rachel.jpg";

// Policies
export { default as childSafeWellbeing } from "../policies/Child Safe and Wellbeing Policy Issue 1.pdf";
export { default as childSafeConduct } from "../policies/Child Safe Code of Conduct Issue 1.pdf";
export { default as covidSafePlan } from "../policies/COVIDSafe Plan for In Person Learning Issue 9.pdf";
export { default as privacyPolicy } from "../policies/Privacy Policy Issue 1.pdf";
export { default as cancellationPolicy } from "../policies/Private Tutoring Lesson Cancellation Policy Issue 4.pdf";
export { default as refundPolicy } from "../policies/Refund Policy Issue 1.pdf";

// Motion effects
export const fadeUp = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const fadeDown = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const fadeLeftToRight = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export const fadeRightToLeft = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export const drawSolidSvg = {
  hidden: { pathLength: 0 },
  visible: {
    pathLength: 1,
  },
};

export const drawDashedSvg = {
  hidden: { pathLength: 0 },
  visible: {
    pathLength: 0.2,
    pathSpacing: 0.4,
  },
};
