import React from "react";
import {
  drawDashedSvg,
  drawSolidSvg,
  fadeDown,
  fadeLeftToRight,
  fadeUp,
} from ".";
import { motion } from "framer-motion";
import doodleSquares from "./assets/vectors/doodleSquares.svg";
import doodleCircles from "./assets/vectors/doodleCircles.svg";
import doodleStars from "./assets/vectors/doodleStar.svg";

const ServicesHero = ({
  // firstHeading,
  secondHeading,
  headingDescription,
  leftTag,
  rightTag,
  image,
  imageDescription,
}) => {
  return (
    <div>
      {/* Title, Introduction */}
      <div className="flex flex-col items-center gap-5 text-center">
        {/* <motion.h3
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-lg font-semibold tracking-wider text-purple uppercase">
          {firstHeading}
        </motion.h3> */}
        <motion.h1
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1, delay: 0.3 }}
          viewport={{ once: true }}
          className="mx-2 text-5xl font-bold text-blue">
          {secondHeading}
        </motion.h1>
        <motion.p
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1, delay: 0.6 }}
          viewport={{ once: true }}
          className="text-lg text-gray-500 w-[80%] mt-5">
          {headingDescription}
        </motion.p>
        <div className="flex justify-center relative bg-bars mt-5">
          {/* Left */}
          <motion.img
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true }}
            className="hidden md:flex absolute bottom-20 left-20"
            src={doodleCircles}
          />
          <motion.img
            variants={fadeDown}
            initial={"hidden"}
            whileInView={fadeDown.visible}
            transition={{ duration: 1, delay: 1.9 }}
            viewport={{ once: true }}
            className="flex absolute top-20 left-20 w-12 h-12"
            src={doodleStars}
          />
          {/* Aeroplane */}
          <motion.svg
            variants={fadeLeftToRight}
            initial={"hidden"}
            whileInView={fadeLeftToRight.visible}
            transition={{ duration: 1, delay: 3 }}
            viewport={{ once: true }}
            className="hidden lg:flex absolute top-32 left-40 w-40 h-40"
            width="187"
            height="89"
            viewBox="0 0 187 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M141.525 59.5691C141.032 59.7994 140.456 59.8276 139.931 59.6255C139.105 59.3118 138.565 58.4969 138.594 57.6118L139.092 40.1945C139.235 35.0916 139.402 29.3122 137.083 24.5909C135.575 21.5095 133.026 18.9149 130.562 16.4064L118.334 3.9365C117.532 3.11798 117.533 1.80985 118.333 1.00008C118.62 0.71688 118.974 0.528573 119.348 0.45688C119.685 0.230902 120.102 0.104873 120.544 0.104891L184.866 1.61342C185.658 1.63374 186.366 2.10603 186.704 2.8311C187.043 3.55617 186.932 4.41086 186.431 5.01204L142.232 59.0207C142.038 59.2605 141.786 59.4474 141.516 59.5734L141.525 59.5691ZM124.48 4.33442L133.461 13.4918C136.033 16.1104 138.938 19.0781 140.754 22.7722C143.532 28.4265 143.342 35.0309 143.186 40.3468L142.861 51.6833L180.529 5.64705L124.471 4.33877L124.48 4.33442Z"
              fill="#0B4697"
            />
            <path
              d="M141.996 27.7332C141.028 28.1852 139.867 27.8099 139.331 26.856C138.772 25.8549 139.113 24.5946 140.096 24.0318L153.45 16.474C154.447 15.9163 155.692 16.2756 156.26 17.2723C156.818 18.2733 156.477 19.5337 155.494 20.0964L142.14 27.6543C142.094 27.676 142.047 27.6977 141.991 27.7238L141.996 27.7332Z"
              fill="#0B4697"
            />
            <motion.path
              variants={drawDashedSvg}
              initial={"hidden"}
              animate={"visible"}
              transition={{
                duration: 1,
                ease: "easeInOut",
                delay: 3.2,
              }}
              d="M1 86.7139C99 31.5139 124.833 29.3806 125.5 35.2139"
              stroke="#0B4697"
              strokeWidth="3"
            />
          </motion.svg>
          {/*END - Aeroplane */}
          <motion.div
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1.5 }}
            className="hidden xl:flex items-center absolute left-32 bottom-28 w-auto p-2 h-6 bg-yellow rounded-lg shadow-2xl text-blue font-medium ring ring-offset-2 ring-yellow">
            <p>{leftTag}</p>
          </motion.div>
          {/* <motion.div
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1.9 }}
            className="hidden md:flex items-center absolute left-10 top-32 w-auto p-2 h-6 bg-yellow rounded-lg shadow-2xl text-blue font-medium ring ring-offset-2 ring-yellow">
            <p>{`Selective School Entry Strategy`}</p>
          </motion.div> */}
          {/* Right */}
          <motion.img
            variants={fadeDown}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 0.5 }}
            className="hidden md:flex absolute top-20 right-20"
            src={doodleSquares}
          />
          <motion.img
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1.9 }}
            className="flex absolute bottom-20 right-20  w-12 h-12"
            src={doodleStars}
          />
          {/* Arrow */}
          <svg
            className="hidden lg:flex absolute right-10 lg:bottom-10 xl:bottom-20 w-72 h-72"
            width="378"
            height="150"
            viewBox="0 0 378 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <motion.path
              variants={drawSolidSvg}
              initial={"hidden"}
              animate={"visible"}
              transition={{
                duration: 1,
                ease: "easeInOut",
                delay: 4,
              }}
              d="M374.659 147.935C337.656 117.878 231.71 39.9926 183.74 74.3121C123.777 117.211 204.993 130.956 225.833 102.843C249.119 71.4301 227.584 38.0389 163.509 20.395C112.25 6.27992 34.6086 36.3572 2.19568 53.1602"
              stroke="#0B4697"
              strokeWidth="3"
            />
            <motion.path
              variants={drawSolidSvg}
              initial={"hidden"}
              animate={"visible"}
              transition={{
                duration: 1,
                ease: "easeInOut",
                delay: 4.8,
              }}
              d="M20.9235 1.26552L2.19574 53.1609L30.5315 83.9301"
              stroke="#0B4697"
              strokeWidth="3"
            />
          </svg>
          {/* END - Arrow */}
          <motion.div
            variants={fadeDown}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 1.7 }}
            className="hidden xl:flex items-center  absolute right-32 top-36 w-auto p-2 h-6 bg-yellow rounded-lg shadow-2xl text-blue font-medium ring ring-offset-2 ring-yellow">
            <p>{rightTag}</p>
          </motion.div>
          {/* <motion.div
            variants={fadeUp}
            initial={"hidden"}
            animate={"visible"}
            transition={{ duration: 1.5, delay: 2.1 }}
            className="hidden md:flex items-center absolute right-10 bottom-32 w-auto p-2 h-6 bg-yellow rounded-lg shadow-2xl text-blue font-medium ring ring-offset-2 ring-yellow">
            <p>{`Scholarship Exam Coaching`}</p>
          </motion.div> */}
          <motion.img
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1, delay: 0.6 }}
            viewport={{ once: true }}
            className=" w-3/5 h-3/5 md:w-2/5 md:h-2/5 lg:translate-y-36 z-50"
            src={image}
            alt={imageDescription}
          />
        </div>
      </div>
      {/* End - Title, Introduction */}
    </div>
  );
};

export default ServicesHero;
