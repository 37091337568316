import React from "react";
import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const flexibility = [
  {
    name: "Decide subjects and age groups",
    description:
      "You decide which subjects and age groups you want to tutor (subject to your qualifications, experience and expertise).",
  },
  {
    name: "Your hourly rate",
    description: "You set and take home 100 percent of your own hourly rate.",
  },

  {
    name: "Full control",
    description:
      "You have full control over how many hours per week and when you want to tutor.",
  },
  {
    name: "Autonomy",
    description:
      "You will supply your own teaching and learning resources that you feel will best support your student. However, TutorWo will be assist should you require assistance with resources.",
  },
];

const support = [
  {
    name: "Mentoring and support",
    description:
      "Our Curriculum and Pedagogical Mentor will be available to provide you with the advice, guidance and support when required. This will be particularly invaluable if this will be your first time tutoring.",
  },
  {
    name: "Profile published on our website",
    description:
      "We will create your profile that includes a professionally constructed blurb outlining your achievements, experience, and academic background.",
  },
  {
    name: "Continuous advertising and marketing activities",
    description:
      "We will continuously engage in advertising and many other forms of marketing activities to attract students for you.",
  },
  {
    name: "Indemnity and liability protection",
    description:
      "We have taken out insurance with Aon Risk Solutions that will cover all tutors, including independent contractors, for any issues that arise from the provision of tutoring services.",
  },
];

const development = [
  {
    name: "Social connections and wellbeing",
    description:
      "Social events for our whole team of tutors and general staff run 2-3 times per year. Many connections and friendships are formed among team members at these social events.",
  },
  {
    name: "Latest updates, research, innovation and trends",
    description:
      "You will be kept abreast with the latest updates, research, innovation and trends in teaching and learning, which will be particularly useful to those pre- and in-service teachers.",
  },
  {
    name: "Free support for next career move",
    description:
      "At TutorWo, we understand that for many of you, tutoring is not a long-term career. When it is time for you to make the next move, you will have free access to services provided by Julian Jom Consulting. This includes assistance in preparing for job applications and interview coaching and training.",
  },
];

const WorkWithUs = () => {
  return (
    <div>
      <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl  mx-auto ">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="relative ">
              {/* Shapes decoration */}
              <CircleGrid
                position="absolute"
                right="-2rem"
                top="-2.5rem"
                color="#863d90"
                size="50px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                left="-2rem"
                bottom="-2.5rem"
                color="#863d90"
                size="80px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                right="-4rem"
                bottom="-15rem"
                color="#863d90"
                size="80px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                left="-4rem"
                bottom="-20rem"
                color="#863d90"
                size="50px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                right="-2rem"
                bottom="-50rem"
                color="#863d90"
                size="50px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                left="-3rem"
                bottom="-30rem"
                color="#863d90"
                size="80px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                right="-4rem"
                bottom="-60rem"
                color="#863d90"
                size="80px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                left="-4rem"
                bottom="-70rem"
                color="#863d90"
                size="80px"
                zIndex={0}
              />
              <CircleGrid
                position="absolute"
                left="-4rem"
                bottom="-45rem"
                color="#863d90"
                size="50px"
                zIndex={0}
              />
              <Donut
                color="#863d90"
                top="7rem"
                left="-3rem"
                size="4rem"
                className="hidden lg:inline lg:animate-shape"
                width={["0.5rem", "1rem", "1rem", "1rem"]}
                zIndex={0}
              />
              <Donut
                color="#863d90"
                top="42rem"
                left="-3rem"
                size="4rem"
                className="hidden lg:inline lg:animate-shape"
                width={["0.5rem", "1rem", "1rem", "1rem"]}
                zIndex={0}
              />
              <Donut
                color="#863d90"
                top="20rem"
                right="-3rem"
                size="4rem"
                className="hidden lg:inline lg:animate-shape"
                width={["0.5rem", "1rem", "1rem", "1rem"]}
                zIndex={0}
              />
              <Donut
                color="#863d90"
                top="52rem"
                right="-3rem"
                size="4rem"
                className="hidden lg:inline lg:animate-shape"
                width={["0.5rem", "1rem", "1rem", "1rem"]}
                zIndex={0}
              />
            </div>
            <div className="text-base max-w-prose lg:max-w-none">
              {/* <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase ">
                Become a tutor
              </h2> */}
              <p className="text-center mt-2 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
                Work with TutorWo
              </p>
              <div className="relative my-10 md:bg-white w-[80%] m-auto">
                <div className=" text-lg text-gray-500 lg:max-w-none text-center ">
                  <p>
                    We continue to be on the lookout for top-performing pre- and
                    in-service schoolteachers and high-achieving Victorian
                    Certificate of Education (VCE) graduates to conduct
                    one-to-one and/or group tutoring.
                  </p>
                  <p className="mt-5">
                    As a tutor with TutorWo, you will be operating as an
                    independent contractor. This means you will experience the
                    best of both world: a high degree of autonomy and
                    flexibility, and at the same time, ongoing support from
                    TutorWo.
                  </p>
                  <p className="text-center my-20 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
                    Here are the benefits when tutoring students:
                  </p>
                </div>
              </div>
            </div>
            {/* ENDS HERE */}
            {/* StART HERE PROS 1 - Flexibility */}
            <div className="bg-white">
              <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                  <h2 className="text-base font-semibold text-purple uppercase tracking-wide">
                    Benefits
                  </h2>
                  <p className="mt-2 text-3xl font-bold text-blue">
                    Flexibility
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    You have full control over how many students you have and
                    when you want to work.
                  </p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                  <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                    {flexibility.map((flexibility) => (
                      <div key={flexibility.name} className="relative">
                        <dt>
                          <CheckIcon
                            className="absolute h-6 w-6 text-purple stroke-purple stroke-2"
                            aria-hidden="true"
                          />
                          <p className="ml-9 text-lg leading-6 font-medium text-blue">
                            {flexibility.name}
                          </p>
                        </dt>
                        <dd className="mt-2 ml-9 text-base text-gray-500">
                          {flexibility.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
            {/* StART HERE PROS 2 - Support */}
            <div className="bg-white mt-20">
              <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                  <h2 className="text-base font-semibold text-purple uppercase tracking-wide">
                    Benefits
                  </h2>
                  <p className="mt-2 text-3xl font-bold text-blue">Support</p>
                  <p className="mt-4 text-lg text-gray-500">
                    The advantage of tutoring with us means you are supported in
                    every step of the way.
                  </p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                  <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                    {support.map((support) => (
                      <div key={support.name} className="relative">
                        <dt>
                          <CheckIcon
                            className="absolute h-6 w-6 text-purple stroke-purple stroke-2"
                            aria-hidden="true"
                          />
                          <p className="ml-9 text-lg leading-6 font-medium text-blue">
                            {support.name}
                          </p>
                        </dt>
                        <dd className="mt-2 ml-9 text-base text-gray-500">
                          {support.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
            {/* StART HERE Pros 2 - Development */}
            <div className="bg-white my-20">
              <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                  <h2 className="text-base font-semibold text-purple uppercase tracking-wide">
                    Benefits
                  </h2>
                  <p className="mt-2 text-3xl font-bold text-blue">
                    Development
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    We care about your personal and professional growth and
                    development.
                  </p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                  <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                    {development.map((development) => (
                      <div key={development.name} className="relative">
                        <dt>
                          <CheckIcon
                            className="absolute h-6 w-6 text-purple stroke-purple stroke-2"
                            aria-hidden="true"
                          />
                          <p className="ml-9 text-lg leading-6 font-medium text-blue">
                            {development.name}
                          </p>
                        </dt>
                        <dd className="mt-2 ml-9 text-base text-gray-500">
                          {development.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>

            {/* START HERE Low commision Container*/}
            <div className="relative lg:w-[70%] mx-auto px-10 lg:px-16 rounded-xl py-16 bg-purple flex flex-col gap-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute left-0 -top-48 md:-top-32 h-[100%]"
                viewBox="0 0 1440 320">
                <path
                  opacity="8%"
                  fill="#f3f4f5"
                  fillOpacity="1"
                  d="M0,128L40,128C80,128,160,128,240,144C320,160,400,192,480,197.3C560,203,640,181,720,176C800,171,880,181,960,181.3C1040,181,1120,171,1200,192C1280,213,1360,267,1400,293.3L1440,320L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-[55%] left-0 sm:top-32 h-[100%]"
                viewBox="0 0 1440 320">
                <path
                  opacity="8%"
                  fill="#f3f4f5"
                  fillOpacity="1"
                  d="M0,128L40,128C80,128,160,128,240,144C320,160,400,192,480,197.3C560,203,640,181,720,176C800,171,880,181,960,181.3C1040,181,1120,171,1200,192C1280,213,1360,267,1400,293.3L1440,320L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
              </svg>
              <h2 className="text-3xl font-bold text-yellow" id="join-heading">
                Interested in joining TutorWo?
              </h2>
              <p className="text-lg text-white z-50">
                Contact 0456 206 990 or email tutorwo.tutoring@gmail.com now!
              </p>
              {/* <p className="text-lg text-white">
                Tutors will not find themselves locked into contracts that are
                inflexible and restrictive in nature, however, to ensure
                consistency and quality control over TutorWo’s pool of tutors as
                well as compliance with governmental regulations and guidelines,
                all tutors will need to agree to a set of standards that include
                but are not limited to lesson delivery and codes of conduct.
                Tutors will still be able to engage in other forms of
                employment, which include working with other tutoring agencies
                and centres.
              </p> */}
              <div className="z-50 flex flex-col md:flex-row gap-6">
                <a
                  className=" block w-full py-3 px-5 text-center bg-white border border-transparent rounded-xl shadow-md text-lg font-medium text-purple hover:bg-yellow sm:inline-block sm:w-auto"
                  href="tel:0456 206 990">
                  Call us
                </a>
                <a
                  className="block w-full py-3 px-5 text-center bg-purple  border-2 border-white rounded-xl shadow-md text-lg font-medium text-white hover:bg-yellow sm:inline-block sm:w-auto"
                  href="mailto:tutorwo.tutoring@gmail.com">
                  Send email
                </a>
              </div>
            </div>
            {/* END HERE */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
