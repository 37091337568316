import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { fadeLeftToRight, fadeUp } from ".";
import { StarIcon } from "@heroicons/react/20/solid";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
} from "@heroicons/react/24/outline";

const Testimonial = () => {
  const userReviews = [
    {
      review:
        "Diya (Mathematics Tutor) has been incredibly helpful and has definitely allowed me to gain a greater understanding of the content. She has explained everything clearly and her use of diagrams and writing have been very helpful for me. She is very patient and kind, and has made tutoring feel approachable.",
      name: "Grace",
      level: "Tertiary Student",
      year: "",
    },
    {
      review:
        "Amelia (English Tutor) sends me the lesson plan prior to class and is very flexible in adapting the class to suit my needs.",
      name: "Amber",
      level: "Year 12",
      year: "2023",
    },
    {
      review:
        "Amelia (English Tutor) sends me a lesson plan before every class with detailed information on the topic along with resources to help guide me. This allows for me to be prepared and ready to learn for when my class begins. She is very respectful and happily assists me if I am confused or unsure about anything. Amelia has allowed me to express my writing but also provide ample amounts of suggestions and support to ensure I can gain as much success as possible through my writing.",
      name: "Anish",
      level: "Year 12",
      year: "2023",
    },
    {
      review:
        "Diya (Mathematics Tutor) is excellent at summarising for students to help them revise. She goes beyond the tutoring and extends her assistance to include helpful exam /test tips. Diya also has a caring and positive attitude towards her students. I would highly recommend her.",
      name: "Mirella",
      level: "Year 11 Parent",
      year: "2023",
    },
    {
      review:
        "Fiona (Mathematics Tutor) is amazing! She really helps the kids, explains things to them and has patience. I believe she is pushing my youngest daughter as well which is good and helping my eldest where she can. Highly recommended.",
      name: "Andy",
      level: "Years 7 and 9 Parent",
      year: "2023",
    },
    {
      review:
        "Andy (Mathematics Tutor) starts his session off by getting an update from Ian, investigates also looking at the school IT platforms. Andy will explain to Ian as to what the learning outcomes are from his new lesson. Andy is working parallel with Ian's curriculum so that he and Ian's maths teacher are on the same page. Andy at the end of his lesson will give me an update, let me know what I need to follow up with Ian and at times ask me to seek clarification from Ian's teacher at school on particular work that Ian has done. Ian's school teacher is more than happy to engage in the conversation. Ian is currently averaging 78.9 on his maths school work.",
      name: "Tony",
      level: "Year 10 Parent",
      year: "2023",
    },
    {
      review:
        "Linoy (English Tutor) has developed a good working relationship with Ian. She arrives and gets an update from him as to where he is at with his English school work and looks with Ian at the school IT platforms that show current work as well as reports that show where Ian is at with his work. Linoy then uses a word document with the work that she and Ian do during her 2 hour session, and  this is shared with Ian and a copy sent to me. Ian recently received an A grade for his school test. His first ever A in English!",
      name: "Tony",
      level: "Year 10 Parent",
      year: "2023",
    },
    {
      review:
        "Omar’s (Legal Studies Tutor) enthusiasm and energy set him apart from other tutors. Having a tutor like him who is attentive and engaging makes it easier to focus on work. Omar has assisted me by pointing out the flaws in my work and thought process, allowing me to re-evaluate and reflect on why I had made that mistake. He encourages me to voice my opinions, which has given me the motivation to strive for success. ",
      name: "Alissa",
      level: "Year 12",
      year: "2023",
    },
    {
      review:
        "I really like how Pakhi (Psychology Tutor) uses PowerPoints when teaching me, which she sends through after every class. This really helps as I can use those when revising for tests and SACS. When I was preparing for my first SAC, she was really helpful like giving me tips and being available when I have any questions prior to the SAC.",
      name: "Rachael",
      level: "Year 11",
      year: "2023",
    },
    {
      review:
        "Carol (Mathematics Tutor) asks in advance what I want to go through in class, which is very helpful as it is like following what I need. Whenever we go through a question together, she goes through each step to make sure I understand how to do them and why we do them. Whenever she notices that an answer may be incorrect, she goes through each step again, which is something that I want to do since I usually just go with whatever answer I get.",
      name: "Rachael",
      level: "Year 11",
      year: "2023",
    },
    {
      review:
        "Tristina (English Tutor) has stood out to me as a tutor as she has provided unlimited resources and given feedback that is specific and detailed to improving the quality of my work.",
      name: "Tiffany",
      level: "Year 12, 2023",
      year: "2023",
    },
    {
      review:
        "My girls said Ahelee (English Tutor) was good, easy to talk to and understand. She even gave me an email with a short breakdown. That was nice as well.",
      name: "Andy",
      level: "Years 7 and 9 Parent",
      year: "2023",
    },
    {
      review:
        "Ahelee (English Tutor) was very positive and enthusiastic in her approach to teaching. My daughter came away feeling happy and positive with new ideas/ strategies that she will be working on.",
      name: "Deborah",
      level: "Year 12 Parent",
      year: "2023",
    },
    {
      review:
        "Thank you for all your support this year. I was able to get an ATAR of 98.10. All of the tutors I have had the privilege of working with are absolutely phenomenal and I would highly recommend them to everyone. They are all patient, kind and extremely knowledgeable in their respective fields. Thank you for arranging for the initial meetings and organising subsequent lessons. ",
      name: "Anonymous",
      level: "Year 12",
      year: "2022",
    },
    {
      review:
        "Yichen (Mathematics Tutor) has helped consolidate my learning in my advanced Mathematics classes at school. The tutoring sessions are very personalised and flexible. I feel more confident in the Mathematics I am doing now.",
      name: "Anna",
      level: "Year 10",
      year: "2021",
    },
  ];

  const carousel = useRef();
  const [width, setWidth] = useState(0);
  const [timeOut, setTimeOut] = useState(false);
  const setTime = () => {
    const timeOutFunction = () => {
      setTimeOut(true);
    };

    setTimeout(timeOutFunction, 1000);
  };
  useEffect(() => {
    setTime();
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, [timeOut]);

  return (
    <section className="flex flex-col text-center">
      {/* Title */}
      <div className="flex flex-col gap-5 mb-5 lg:mb-14">
        <motion.h1
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1, delay: 0.3 }}
          viewport={{ once: true }}
          className="text-4xl md:text-5xl font-bold text-blue tracking-wider">
          We are{" "}
          <span className="text-purple uppercase">Highly Recommended</span>
        </motion.h1>
        <motion.p
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1, delay: 0.6 }}
          viewport={{ once: true }}
          className="text-xl text-gray-500">
          Discover what our clients are saying about us
        </motion.p>
      </div>
      {/* END - Title */}
      {/* User Reviews */}
      <div className="overflow-hidden pt-5 " ref={carousel}>
        <motion.div
          variants={fadeLeftToRight}
          initial={"hidden"}
          whileInView={fadeLeftToRight.visible}
          transition={{ duration: 1, delay: 0.3 }}
          viewport={{ once: true }}
          className="flex gap-10 w-[2200%] md:w-[600%] mx-10 cursor-grab"
          whileTap={{ cursor: "grabbing" }}
          drag="x"
          dragElastic={1}
          dragConstraints={{ right: 0, left: -width }}>
          {userReviews.map((review, index) => (
            <article
              key={`review_${index}`}
              className=" w-full h-96 border-2 border-purple rounded-xl relative flex justify-center items-center">
              {/* Stars */}
              <div className="absolute -top-5 flex bg-white">
                <StarIcon className="text-yellow w-10 h-10" />
                <StarIcon className="text-yellow w-10 h-10" />
                <StarIcon className="text-yellow w-10 h-10" />
                <StarIcon className="text-yellow w-10 h-10" />
                <StarIcon className="text-yellow w-10 h-10" />
              </div>
              {/* END - Stars */}
              <FormatQuoteRoundedIcon
                sx={{ fontSize: 40 }}
                className=" absolute -left-4 rotate-180 mb-2 bg-white text-blue"
              />
              <FormatQuoteRoundedIcon
                sx={{ fontSize: 40 }}
                className=" absolute -right-4 mb-2 bg-white text-blue"
              />
              <div className="flex flex-col my-10 mx-10 relative">
                <p className="italic text-blue font-bold">{review.review}</p>
                <p className="mt-2 font-bold text-purple">
                  {review.name}, <span>{review.level}</span>{" "}
                </p>
                <p className="text-purple">{review.year}</p>
              </div>
            </article>
          ))}
        </motion.div>
      </div>
      {/* END - User Reviews */}
      <motion.h2
        className="mt-20 text-purple font-bold text-3xl text-center"
        variants={fadeUp}
        initial={"hidden"}
        whileInView={fadeUp.visible}
        transition={{ duration: 1 }}
        viewport={{ once: true }}>
        <span>
          <ArrowLongLeftIcon className="inline w-10 h-10 mr-3" />
        </span>
        Try Dragging{" "}
        <span>
          <ArrowLongRightIcon className="inline w-10 h-10" />
        </span>
      </motion.h2>
    </section>
  );
};

export default Testimonial;
