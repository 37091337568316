import React, { useEffect } from "react";
import Logo from "./assets/white-logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { animateScroll, Link } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";
import { privacyPolicy } from "./";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const Footer = () => {
  const footerNavigation = {
    // aboutLocal: [
    //   { name: "About TutorWo", href: "about" },
    //   { name: "The Founder", href: "julian" },
    //   { name: "Why TutorWo", href: "whyus" },
    // ],
    // aboutRoute: [
    //   { name: "Meet our tutors", href: "/our-tutors" },
    //   { name: "FAQ", href: "/faq" },
    // ],
    services: [
      // { name: "Master Classes", href: "/master-classes" },
      // { name: "Private Tutoring", href: "/private-tutoring" },
      {
        name: "Years 3 to 10 English and Mathematics Tutoring",
        href: "/english-math-tutoring",
      },
      {
        name: "Scholarship and Selective Entry School Exam Preparation",
        href: "/scholarship-selective-school",
      },
      { name: "VCE Tutoring", href: "/vce-tutoring" },
      {
        name: "VCE Essay and Exam Marking Service",
        href: "/vce-essay-exam-marking",
      },
      { name: "VCE Study Guides", href: "/vce-study-guides" },
    ],
    findTutor: {
      name: "Find a private tutor",
      href: "https://docs.google.com/forms/d/e/1FAIpQLSeka3ASGbWgsnAhF2K8r6j2azn7siTUW68SsRMpgLaiMJQC-A/viewform?usp=sf_link",
    },
    contact: [
      { name: "Become a tutor", href: "/work-with-us" },
      { name: "General Enquiry", href: "/contact" },
    ],
    // privacy: [{ name: "Privacy Policy", href: privacyPolicy }],
    legal: [{ name: "Policies", href: "/policies" }],
    social: [
      {
        name: "Instagram",
        href: "https://www.instagram.com/tutorwo_/",
        icon: InstagramIcon,
      },
      {
        name: "Facebook",
        href: "https://www.facebook.com/TutorWo",
        icon: FacebookIcon,
      },
      {
        name: "Linkedin",
        href: "https://www.linkedin.com/company/tutorwo/",
        icon: LinkedInIcon,
      },
    ],
  };

  const location = useLocation();

  useEffect(() => {
    animateScroll.scrollToTop();
  }, [location.pathname]);

  return (
    <div>
      <svg
        style={{
          marginTop: "-1px",
          marginBottom: "-2px",
          strokeWidth: 0,
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320">
        <path
          fill="#863d90"
          stroke="none"
          strokeOpacity="0"
          fillOpacity="1"
          d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,186.7C672,171,768,181,864,202.7C960,224,1056,256,1152,250.7C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      <footer className="bg-purple" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img className="h-10" src={Logo} alt="Company name" />
              <div className="pl-3 flex flex-col gap-5">
                <a href="tel:0456 206 990">
                  <p className="text-white text-base hover:text-yellow">
                    Phone: 0456 206 990
                  </p>
                </a>
                <a href="mailto:tutorwo.tutoring@gmail.com">
                  <p className="text-white text-base hover:text-yellow">
                    Email: tutorwo.tutoring@gmail.com
                  </p>
                </a>
                <p className="text-white text-base">ABN: 54 872 498 226</p>
              </div>
              <div className="flex pl-3 space-x-6">
                {footerNavigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    className="text-white hover:text-yellow">
                    <span className="sr-only">{item.name}</span>

                    <item.icon
                      sx={{ fontSize: "1.8rem" }}
                      className="h-10 w-10"
                      aria-hidden="true"
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="md:mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-1 md:gap-8">
                {/* <div>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                    About
                  </h3>
                  <ul className="mt-4 space-y-4 cursor-pointer">
                    {footerNavigation.aboutLocal.map((item) =>
                      location.pathname === "/" ? (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            smooth={true}
                            className="text-base text-white hover:text-yellow">
                            {item.name}
                          </Link>
                        </li>
                      ) : (
                        <li key={item.name}>
                          <NavLink
                            exact
                            to={"/#" + item.href}
                            className="text-base text-white hover:text-yellow">
                            {item.name}
                          </NavLink>
                        </li>
                      )
                    )}
                    {footerNavigation.aboutRoute.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className="text-base text-white hover:text-yellow">
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div> */}
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                    WHAT WE OFFER?
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {footerNavigation.services.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className="text-base text-white hover:text-yellow">
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-14 md:mt-0 md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                    Contact
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li key={footerNavigation.findTutor.name}>
                      <a
                        href={footerNavigation.findTutor.href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-base text-white hover:text-yellow flex items-center ">
                        {footerNavigation.findTutor.name}
                        <ArrowTopRightOnSquareIcon className="h-5 w-5 stroke-white stroke-2 ml-2" />
                      </a>
                    </li>
                    {footerNavigation.contact.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className="text-base text-white hover:text-yellow">
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                    Legal
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {/* {footerNavigation.privacy.map((item) => (
                      <li key={item.name}>
                        <a
                          className="text-base text-white hover:text-yellow flex items-center"
                          target="_blank"
                          rel="noreferrer"
                          href={item.href}>
                          {item.name}
                          <ArrowTopRightOnSquareIcon className="w-5 h-5 stroke-white stroke-2 ml-2" />
                        </a>
                      </li>
                    ))} */}
                    {footerNavigation.legal.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className="text-base text-white hover:text-yellow">
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12  border-white border-t-2 py-8">
            <p className="text-base text-white xl:text-center">
              Copyright © 2023 TutorWo | Powered by Tutorwo
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
