import React from "react";
// import { SendMessage } from "../Nodemailer API/contactForm";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
// import { XIcon } from "@heroicons/react/solid";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { Transition } from "@headlessui/react";
import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";
// import {
//   validateEmail,
//   validateFirstName,
//   validateMessage,
// } from "./ContactFormValidation";

const ContactForm = () => {
  // const contactForm = useRef();

  // const [{ firstName, lastName, email, phone, subject, message }, setDetails] =
  //   useState({});
  // const [send, setSend] = useState();
  // const [submitted, setSubmitted] = useState(false);
  // const [error, setError] = useState(false);

  // useEffect(() => {
  //   if (send) {
  //     setSubmitted(true);
  //   } else {
  //     setError(true);
  //   }
  // }, [send]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubmitted(false);
  //   }, 2000);
  // }, [submitted]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setError(false);
  //   }, 2000);
  // }, [error]);

  // const handleChange = (e) => {
  //   const propChanging = e.target.id;
  //   const valueToChange = e.target.value;
  //   setDetails({
  //     ...{ firstName, lastName, email, phone, subject, message },
  //     [propChanging]: valueToChange,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   SendMessage({
  //     firstName,
  //     lastName,
  //     email,
  //     phone,
  //     subject,
  //     message,
  //     setSend,
  //   });
  //   contactForm.current.reset();
  // };

  return (
    <main className="overflow-hidden">
      {/* Contact section */}
      <section
        className="relative bg-white mb-32"
        aria-labelledby="contact-heading">
        <CircleGrid
          position="absolute"
          top="0rem"
          color="#863d90"
          size="80px"
          className="hidden lg:inline"
          zIndex={0}
        />
        <CircleGrid
          position="absolute"
          right="0"
          top="5rem"
          color="#863d90"
          size="80px"
          className="hidden lg:inline"
          zIndex={0}
        />
        <Donut
          color="#863d90"
          top="1rem"
          left="1rem"
          size="4rem"
          className="hidden lg:inline lg:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        />
        <Donut
          color="#863d90"
          top="6rem"
          right="1rem"
          size="4rem"
          className="hidden lg:inline lg:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        />
        {/* SMALL shapes decoration */}
        <CircleGrid
          position="absolute"
          right="5rem"
          top="2rem"
          color="#863d90"
          size="50px"
          className="hidden lg:inline"
          zIndex={0}
        />
        <CircleGrid
          position="absolute"
          right="2rem"
          top="12rem"
          color="#863d90"
          size="50px"
          className="hidden lg:inline"
          zIndex={0}
        />
        <CircleGrid
          position="absolute"
          left="5rem"
          top="8rem"
          color="#863d90"
          size="50px"
          className="hidden lg:inline"
          zIndex={0}
        />
        <CircleGrid
          position="absolute"
          left="2rem"
          top="13rem"
          color="#863d90"
          size="50px"
          className="hidden lg:inline"
          zIndex={0}
        />
        <div className="max-w-7xl lg:max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl rounded-xl">
            <div className="grid">
              {/* Contact information */}
              <div className="relative overflow-hidden py-10 px-6 bg-purple text-white sm:px-10 xl:p-12 rounded-xl ring-4 ring-offset-2 ring-purple mt-2">
                <h3 className="text-lg font-medium text-white">
                  Contact information
                </h3>
                <p className="mt-6 text-base text-teal-50 max-w-3xl">
                  Have a question in mind? There are many ways to reach us.
                </p>
                <dl className="mt-8 space-y-6">
                  <dt>
                    <span className="sr-only">Phone number</span>
                  </dt>
                  <dd className="flex text-base text-white">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-white stroke-white stroke-2"
                      aria-hidden="true"
                    />
                    <a href="tel:0456 206 990">
                      <span className="ml-3 hover:text-yellow">
                        0456 206 990
                      </span>
                    </a>
                  </dd>
                  <dt>
                    <span className="sr-only">Email</span>
                  </dt>
                  <dd className="flex text-base text-white">
                    <EnvelopeIcon
                      className="flex-shrink-0 w-6 h-6 text-white stroke-white stroke-2"
                      aria-hidden="true"
                    />
                    <a href="mailto:tutorwo.tutoring@gmail.com">
                      <span className="ml-3 hover:text-yellow">
                        tutorwo.tutoring@gmail.com
                      </span>
                    </a>
                  </dd>
                </dl>
                <ul className="mt-8 flex space-x-12 ">
                  <li>
                    <a
                      className="text-white hover:text-yellow"
                      href="https://www.instagram.com/tutorwo_/"
                      target="_blank">
                      <span className="sr-only">Instagram</span>
                      <InstagramIcon sx={{ fontSize: "1.8rem" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-yellow"
                      href="https://www.facebook.com/TutorWo"
                      target="_blank">
                      <span className="sr-only">Facebook</span>
                      <FacebookIcon sx={{ fontSize: "1.8rem" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-yellow"
                      href="https://www.linkedin.com/company/tutorwo/"
                      target="_blank">
                      <span className="sr-only">LinkedIn</span>
                      <LinkedInIcon sx={{ fontSize: "1.8rem" }} />
                    </a>
                  </li>
                </ul>
              </div>

              {/* Contact form */}
              {/* <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <h3 className="text-lg font-medium text-blue">
                  Send us a message
                </h3>
                {submitted && (
                  <div
                    aria-live="assertive"
                    className="z-50 fixed top-20 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                      <Transition
                        show={submitted}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="p-4">
                            <div className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="h-6 w-6 text-green-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-blue">
                                  Message sent successfully!
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  Your message has been sent. We will respond to
                                  it as soon as we can.
                                </p>
                              </div>
                              <div className="ml-4 flex-shrink-0 flex">
                                <button
                                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
                                  onClick={() => {
                                    setSubmitted(false);
                                  }}>
                                  <span className="sr-only">Close</span>
                                  <XIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                )}
                {error && (
                  <div
                    aria-live="assertive"
                    className="z-50 fixed top-20 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                      <Transition
                        show={submitted}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="p-4">
                            <div className="flex items-start">
                              <div className="flex-shrink-0">
                                <XIcon
                                  className="h-6 w-6 text-red-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-blue">
                                  An error has occured!
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  There was an error while sending your message.
                                  Please try again later, or use alternative
                                  contact method.
                                </p>
                              </div>
                              <div className="ml-4 flex-shrink-0 flex">
                                <button
                                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
                                  onClick={() => {
                                    setSubmitted(false);
                                  }}>
                                  <span className="sr-only">Close</span>
                                  <XIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                )}
                <form
                  ref={contactForm}
                  method="POST"
                  onSubmit={handleSubmit}
                  className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-purple">
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="given-name"
                        onChange={handleChange}
                        className="py-3 px-4 block border-2 border-solid border-purple w-full shadow-sm text-warm-gray-900 focus:ring-yellow focus:border-yellow border-warm-gray-300 rounded-md"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-purple">
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="family-name"
                        onChange={handleChange}
                        className="py-3 px-4 block w-full shadow-sm border-2 border-solid border-purple text-warm-gray-900 focus:ring-yellow focus:border-yellow border-warm-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-purple">
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={handleChange}
                        className="py-3 px-4 block w-full border-2 border-solid border-purple shadow-sm text-warm-gray-900 focus:ring-yellow focus:border-yellow border-warm-gray-300 rounded-md"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-purple">
                        Phone
                      </label>
                      <span id="phone-optional" className="text-sm text-blue">
                        Optional
                      </span>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        onChange={handleChange}
                        className="py-3 px-4 block w-full border-2 border-solid border-purple shadow-sm text-warm-gray-900 focus:ring-yellow focus:border-yellow border-warm-gray-300 rounded-md"
                        aria-describedby="phone-optional"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="subject"
                      className="block text-sm font-medium text-purple">
                      Subject
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        onChange={handleChange}
                        className="py-3 px-4 block w-full border-2 border-solid border-purple shadow-sm text-warm-gray-900 focus:ring-yellow focus:border-yellow border-warm-gray-300 rounded-md"
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-purple">
                        Message
                      </label>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="py-3 px-4 border-2 border-solid border-purple block w-full shadow-sm text-warm-gray-900 focus:ring-yellow focus:border-yellow  border-warm-gray-300 rounded-md"
                        aria-describedby="message-max"
                        onChange={handleChange}
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:flex sm:justify-end">
                    <button
                      type="submit"
                      className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-purple hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple sm:w-auto">
                      Submit
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactForm;
