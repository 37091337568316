import React, { useRef } from "react";
import vceTutoring from "../../assets/vceTutoringIllustration.png";
import ServicesHero from "../../ServicesHero";
import {
  BookOpenIcon as BookOpenIconOutline,
  DocumentTextIcon as DocumentTextIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  BoltIcon as BoldIconOutline,
  UserIcon,
  ChatBubbleBottomCenterTextIcon,
  FaceSmileIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleLeftRightIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { fadeDown, fadeUp } from "../..";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import privateTutoringIcon from "../../assets/ServicesIcons/private-tutoring-icon.png";
import groupTutoringIcon from "../../assets/ServicesIcons/group-tutoring-icon.png";
import questionOne from "../../assets/vceTutoringQuestionsChatBox/questionOne.png";
import questionTwo from "../../assets/vceTutoringQuestionsChatBox/questionTwo.png";
import questionThree from "../../assets/vceTutoringQuestionsChatBox/questionThree.png";
import questionOneIllustration from "../../assets/vceTutoringQuestionsChatBox/questionOneIllustration.svg";
import questionTwoIllustration from "../../assets/vceTutoringQuestionsChatBox/questionTwoIllustration.svg";
import questionThreeIllustration from "../../assets/vceTutoringQuestionsChatBox/questionThreeIllustration.svg";

import Subjects from "../../Subjects";
import VceGroupSubjects from "../../VceGroupSubjects";
import OnlineLearning from "../../OnlineLearning";

const VceTutoring = () => {
  const boostConfidence = [
    {
      name: "pointOne",
      icon: DocumentTextIconOutline,
      color: "text-orange",
      heading: "Simplying complex topics",
      text: "Unpacking and simplifying complex concepts and topics in ways that make it easier for you to understand",
    },
    {
      name: "pointTwo",
      icon: BookOpenIconOutline,
      color: "text-yellow",
      heading: "Sharing our secret recipe",
      text: "Sharing with you our very own recipe to formulating winning answers and responses to SAC and exam questions",
    },
    {
      name: "pointTwo",
      icon: UserGroupIconOutline,
      color: "text-orange",
      heading: "Giving multiple perspectives",
      text: "Providing you with multiple perspectives in areas where there teacher and examiner subjectivity comes into play in SAC and exam marking",
    },
    {
      name: "pointTwo",
      icon: BoldIconOutline,
      color: "text-yellow",
      heading: "Transferring powerful strategies",
      text: "Equipping you with our very own powerful study and memorisation strategies",
    },
  ];

  const individualisedTutoring = [
    {
      name: "pointOne",
      icon: UserIcon,
      borderColor: "border-yellow",
      color: "text-yellow",
      heading: "Targeted Assistance",
      text: "Devoting more time to areas where you need the most help, which includes having difficult-to-understand concepts and topics explained to you again and again until you get it.",
    },
    {
      name: "pointTwo",
      icon: ChatBubbleBottomCenterTextIcon,
      borderColor: "border-blue",
      color: "text-blue",
      heading: "Customised Feedback",
      text: "Receiving personalised, comprehensive and highly-targeted feedback so you know exactly what you need to do to produce winning answers and responses in upcoming SACs and exams.",
    },
    {
      name: "pointTwo",
      icon: FaceSmileIcon,
      borderColor: "border-purple",
      color: "text-purple",
      heading: "Neutral Observer",
      text: "Having a fresh pair of eyes with us acting as your neutral observer in how you can do even better in approaching and managing the demands of the VCE.",
    },
    {
      name: "pointTwo",
      icon: FlagIcon,
      borderColor: "border-orange",
      color: "text-orange",
      heading: "Sustained Progress",
      text: "Ensuring you remain on track to the very end.",
    },
  ];

  // const parent = {
  //   hidden: { opacity: 0 },
  //   visible: {
  //     opacity: 1,
  //     transition: {
  //       staggerChildren: 0.6,
  //       delayChildren: 1.5,
  //     },
  //   },
  // };

  // const [mouseOver, setMouseOver] = useState(false);

  // console.log(mouseOver);

  // const handleMouseOver = () => {
  //   setMouseOver(true);
  // };
  const headingRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    target: headingRef,
    offset: ["start center", "end end"],
  });

  const x = useTransform(scrollYProgress, [0, 1], [-200, 500]);

  const questionOneRef = useRef(null);
  const questionOneInView = useInView(questionOneRef);
  const questionTwoRef = useRef(null);
  const questionTwoInView = useInView(questionTwoRef);
  const questionThreeRef = useRef(null);
  const questionThreeInView = useInView(questionThreeRef);

  return (
    <section className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Tutoring"
        secondHeading="VCE Tutoring"
        headingDescription="Our team of top ATAR tutors (who have all been there and done that) and high-performing school teachers understand first hand the challenges you face with the rollercoaster of being a VCE student."
        leftTag="Individualised tutoring"
        rightTag="Unlock VCE success"
        image={vceTutoring}
        imageDescription="Illustration of scholarship selective school exam"
      />
      <div className="flex flex-col bg-purple mb-32">
        <svg
          style={{
            marginTop: "-1px",
            marginBottom: "-2px",
            strokeWidth: 0,
          }}
          viewBox="0 0 1200 120"
          className="w-[150%]">
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            fill="white"></path>
        </svg>
        <div className="flex flex-col lg:flex-row justify-center items-center mx-auto gap-32 sm:gap-48 lg:gap-10 py-10 md:w-[80%]">
          <motion.h2
            variants={fadeDown}
            initial={"hidden"}
            whileInView={fadeDown.visible}
            transition={{ duration: 1 }}
            // viewport={{ once: true }}
            className="text-5xl text-white w-[90%] lg:w-[60%] tracking-wide font-bold leading-[3.5rem] xl:mt-5">
            Our team of experts will boost your{" "}
            <span className="text-yellow">confidence</span> and help you unlock
            VCE <span className="text-yellow">success</span> by:
          </motion.h2>

          <div className="relative flex flex-col">
            <svg
              className="absolute -bottom-36 sm:-bottom-52 lg:-bottom-60 left-0 w-[48rem] md:w-[50rem]"
              width="967"
              height="934"
              viewBox="0 0 967 934"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 927V166.387C218.75 123.614 653.837 95.0362 844.662 86.0938V876.557C578.882 817.159 174.146 885.437 5 927Z"
                stroke="#FCC219"
                strokeWidth="10"
                strokeLinecap="round"
                strokeDasharray="20 20"
              />
              <path
                d="M905.827 368.303L493.445 65.3502L554.611 0L966.993 308.997L905.827 368.303Z"
                fill="#D9D9D9"
              />
            </svg>
            {boostConfidence.map((point, index) => (
              <motion.div
                key={`confidence_${index}`}
                className="flex flex-col justify-center text-white w-[80%] mx-auto">
                <motion.div
                  variants={fadeDown}
                  initial={"hidden"}
                  whileInView={fadeDown.visible}
                  transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                  className={`flex items-center gap-5 ${point.color}`}>
                  <point.icon className={`w-10 h-10 `} />
                  <h2 className="font-semibold text-lg">{point.heading}</h2>
                </motion.div>
                <motion.p
                  variants={fadeUp}
                  initial={"hidden"}
                  whileInView={fadeUp.visible}
                  transition={{ duration: 1, delay: 0.5 }}
                  // viewport={{ once: true }}
                  className="mb-5">
                  {point.text}
                </motion.p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-start w-full md:w-[80%] mt-36 mb-48 gap-20">
        <motion.h2
          variants={fadeDown}
          initial={"hidden"}
          whileInView={fadeDown.visible}
          transition={{ duration: 1 }}
          // viewport={{ once: true }}
          className="text-5xl text-purple w-[90%] lg:w-[60%] tracking-wide font-bold leading-[3.5rem] xl:mt-5">
          We also know how invaluable mentoring is in navigating your way
          through VCE, which we <span className="text-yellow">add value</span>{" "}
          by:
        </motion.h2>
        <div className="relative md:w-[60%] flex flex-col justify-center gap-10">
          <svg
            className="absolute -top-[14rem] sm:-top-[14rem] lg:-top-96 -left-[12rem] md:-left-[15rem] lg:-left-32 w-[48rem] md:w-[50rem]"
            width="1170"
            height="934"
            viewBox="0 0 1170 934"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1165 927V166.387C906.143 123.614 379.24 95.0362 148.146 86.0938V876.557C470.012 817.159 960.16 885.437 1165 927Z"
              stroke="#0B4697"
              strokeWidth="10"
              strokeLinecap="round"
              strokeDasharray="20 20"
            />
            <path
              d="M74.0732 368.303L573.479 65.3502L499.406 0L6.10352e-05 308.997L74.0732 368.303Z"
              fill="#D9D9D9"
            />
          </svg>

          {/* 1 */}
          <div className="w-[22rem]sm:w-[30rem] lg:w-[40rem] h-20 text-purple mt-32 lg:mt-0 mx-36 sm:mx-0">
            <motion.div
              variants={fadeDown}
              initial={"hidden"}
              whileInView={fadeDown.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex items-center gap-5">
              <ChatBubbleLeftRightIcon className="w-10 h-10" />
              <h3 className="font-semibold">Bouncing ideas</h3>
            </motion.div>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1, delay: 0.5 }}
              // viewport={{ once: true }}
            >
              Being your sounding board for ideas and concerns (getting diverse
              opinions better informs your decision making)
            </motion.p>
          </div>
          {/* 2 */}
          <div className="w-[22rem] sm:w-[30rem] lg:w-[40rem] h-20 mt-5 text-blue mx-36 sm:mx-0">
            <motion.div
              variants={fadeDown}
              initial={"hidden"}
              whileInView={fadeDown.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex items-center gap-5">
              <UsersIcon className="w-10 h-10" />
              <h3 className="font-semibold">Providing guidance</h3>
            </motion.div>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1, delay: 0.5 }}
              // viewport={{ once: true }}
            >
              Advising you on what to expect for the year ahead and how to put
              your best foot forward in managing and juggling the competing
              demands throughout your most crucial years of schooling.
            </motion.p>
          </div>
          {/* END dot points */}
        </div>
      </div>
      {/* END 2nd Box */}
      <div
        ref={headingRef}
        className="flex flex-col justify-center items-center mt-48 w-full">
        <motion.h1
          style={{ x }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          What we offer?
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
        </motion.h1>
        {/* Lecture and master class */}
        <div className="flex flex-col justify-center items-center mt-40 gap-5 w-full text-center">
          <img src={groupTutoringIcon} alt="Illustration of group tutoring" />
          <h2 className="text-blue font-bold text-3xl uppercase text-center">
            SMALL GROUP MASTERCLASSES
          </h2>
          <div>
            {/* <motion.ul
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md font-bold grid grid-cols-1 md:grid-cols-3 gap-4 mt-3 mb-1">
              <li className="text-purple border-2 border-purple px-2 rounded-full">
                VCE Biology Units 3 and 4
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                VCE English Units 3 and 4
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                VCE English Language Units 3 and 4
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                VCE Mathematics - General Units 3 and 4
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                VCE Mathematics - Methods Units 3 and 4
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                VCE Mathematics - Specialist Units 3 and 4
              </li>
              <li className="text-blue border-2 border-blue px-2 rounded-full">
                VCE Psychology Units 3 and 4
              </li>
            </motion.ul> */}
            <VceGroupSubjects />
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500 font-bold my-2">
              Masterclasses will run on a weekly basis on most weeks during
              school terms.
            </motion.p>
            <motion.table
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              className="flex border-2 border-purple rounded-2xl p-[0.53rem] w-[95%] md:w-[40%] my-5 mx-auto">
              <tbody className="text-sm text-gray-500 flex flex-col mx-auto gap-1">
                {/* Term 1 */}
                <tr>
                  <td className="font-bold text-purple underline underline-offset-4">
                    Term 1
                  </td>
                </tr>
                <tr>
                  <td>Monday, 5 February 2024 - Thursday, 28 March 2024</td>
                </tr>
                {/* Term 2 */}
                <tr>
                  <td className="font-bold text-blue  underline underline-offset-4">
                    Term 2
                  </td>
                </tr>
                <tr>
                  <td>Monday, 15 April 2024 - Friday, 28 June 2024</td>
                </tr>
                {/* Term 3 */}
                <tr>
                  <td className="font-bold text-purple  underline underline-offset-4">
                    Term 3
                  </td>
                </tr>
                <tr>
                  <td>Monday, 15 July 2024 - Friday, 20 September 2024</td>
                </tr>
                {/* Term 4 */}
                <tr>
                  <td className="font-bold text-blue  underline underline-offset-4">
                    Term 4
                  </td>
                </tr>
                <tr>
                  <td> Monday, 7 October 2024 - Friday, 25 October 2024 </td>
                </tr>
              </tbody>
            </motion.table>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-sm text-gray-500 my-2 italic w-[90%] mx-auto">
              The days and times for the weekly masterclasses will be determined
              on the availability of the tutor/s and the majority of students.
              Confirmation will be made in late January 2024.
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto font-bold text-md text-purple mt-4">
              Investment
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500">
              $35 per 60-minute masterclass per week
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto font-bold text-md text-purple mt-2">
              Venue
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="mr-auto text-md text-gray-500">
              Online
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex justify-center mt-8">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeJo6fsgDHQILrkbkmN54UHeRFgcXKcS329t-0F9ObBuCwCgQ/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer">
                <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Expression of Interest
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </motion.div>
          </div>
        </div>
        {/* End - lecture and master class */}
        <div className="flex flex-col justify-center items-center mt-40 gap-5">
          <img
            src={privateTutoringIcon}
            alt="Illustration of private tutoring"
          />
          <h2 className="text-blue font-bold text-3xl uppercase">
            Private Tutoring
          </h2>

          <div className=" flex flex-col justify-center items-center mt-12 gap-20">
            {/* Question box 1 */}
            <div className="flex gap-10 w-[60%] md:w-full">
              <img
                className="hidden md:inline w-56 h-56"
                src={questionOneIllustration}
                alt="chat box with doodled circles as background"
              />
              <div
                ref={questionOneRef}
                className={`w-[36rem] h-[8rem] border-2 border-purple rounded-full translate-y-16 flex justify-center ${
                  questionOneInView ? "" : "overflow-hidden"
                }`}>
                <motion.div
                  intial={{ y: 100 }}
                  whileInView={{ y: -50 }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 0.8,
                  }}
                  viewport={{ once: true }}
                  className="bg-white">
                  <img
                    className="w-[20rem] h-[6rem] md:w-[32rem] md:h-[8rem] translate-y-5 md:translate-y-0"
                    src={questionOne}
                    alt="chat box with doodled circles as background"
                  />
                </motion.div>
              </div>
            </div>
            {/* End- Question box 1 */}
            {/* Question box 2 */}
            <div className="flex gap-10 w-[60%] md:w-full">
              <div
                ref={questionTwoRef}
                className={`w-[36rem] h-[8rem] border-2 border-yellow rounded-full translate-y-16 flex justify-center ${
                  questionTwoInView ? "" : "overflow-hidden"
                }`}>
                <motion.div
                  intial={{ y: 100 }}
                  whileInView={{ y: -50 }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 0.8,
                  }}
                  viewport={{ once: true }}
                  className="bg-white">
                  <img
                    className="w-[20rem] h-[6rem] md:w-[32rem] md:h-[8rem] translate-y-5 md:translate-y-0"
                    src={questionTwo}
                    alt="chat box with doodled circles as background"
                  />
                </motion.div>
              </div>
              <img
                className="hidden md:inline w-56 h-56"
                src={questionTwoIllustration}
                alt="chat box with doodled circles as background"
              />
            </div>
            {/* End- Question box 2 */}
            {/* Question box 1 */}
            <div className="flex gap-10 w-[60%] md:w-full">
              <img
                className="hidden md:inline w-56 h-56"
                src={questionThreeIllustration}
                alt="chat box with doodled circles as background"
              />
              <div
                ref={questionThreeRef}
                className={`w-[36rem] h-[8rem] border-2 border-blue rounded-full translate-y-16 flex justify-center ${
                  questionThreeInView ? "" : "overflow-hidden"
                }`}>
                <motion.div
                  intial={{ y: 100 }}
                  whileInView={{ y: -80 }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 0.8,
                  }}
                  viewport={{ once: true }}
                  className="bg-white">
                  <img
                    className="w-[20rem] h-[8rem] translate-y-5 md:translate-y-0 md:w-[32rem] md:h-[10rem]"
                    src={questionThree}
                    alt="chat box with doodled circles as background"
                  />
                </motion.div>
              </div>
            </div>
            {/* End- Question box 1 */}
          </div>
          <div className="flex flex-col lg:flex-row w-[60%] lg:w-[80%] mt-36 gap-20">
            <h2 className="text-purple font-bold text-3xl">
              Highly <span className="text-yellow">individualised</span>{" "}
              one-to-one tutoring enables targeted instruction where every
              session is tailored to your specific learning needs and goals.
              This includes but not limited to:
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              {individualisedTutoring.map((point, index) => (
                <div
                  key={`individualisedTutoring_${index}`}
                  className={`border-4 ${point.borderColor} rounded-3xl p-5`}>
                  <motion.div
                    variants={fadeDown}
                    initial={"hidden"}
                    whileInView={fadeDown.visible}
                    transition={{ duration: 1 }}
                    // viewport={{ once: true }}
                    className={`flex items-center gap-5 ${point.color}`}>
                    <point.icon className={`w-10 h-10 `} />
                    <h2 className="font-semibold text-lg">{point.heading}</h2>
                  </motion.div>
                  <motion.p
                    variants={fadeUp}
                    initial={"hidden"}
                    whileInView={fadeUp.visible}
                    transition={{ duration: 1 }}
                    // viewport={{ once: true }}
                    className="mb-5 text-gray-500 mt-2">
                    {point.text}
                  </motion.p>
                </div>
              ))}
            </div>
          </div>
          <Subjects />
          {/* Enquiry */}
          <div className="flex flex-col mt-48 text-center w-[60%] lg:w-[90%] z-50">
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-base text-gray-500 italic">
              Any questions? Contact us on{" "}
              <span className="hover:text-yellow">
                <a href="tel:0456 206 990">0456 206 990</a>
              </span>{" "}
              or at{" "}
              <span className="hover:text-yellow">
                <a href="mailto:tutorwo.tutoring@gmail.com">
                  tutorwo.tutoring@gmail.com
                </a>
              </span>
            </motion.p>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="text-base text-gray-500 italic">
              For added convenience, complete the online form and one of our
              representatives will be in touch with you.
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              // viewport={{ once: true }}
              className="flex justify-center mt-5">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeka3ASGbWgsnAhF2K8r6j2azn7siTUW68SsRMpgLaiMJQC-A/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer">
                <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Find a Private Tutor
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                </button>
              </a>
            </motion.div>
          </div>
          {/* END -enquiry */}
        </div>
      </div>
      <OnlineLearning />
    </section>
  );
};

export default VceTutoring;
