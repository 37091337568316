import React from "react";
import ContactForm from "../ContactForm";

const Contact = () => {
  return (
    <div>
      {/* Header */}
      <div className="bg-warm-gray-50">
        <div className="pt-24 pb-12 lg:pt-32">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            {/* <h2 className="text-lg text-center font-semibold tracking-wider text-purple stroke-purple stroke-2 uppercase">
              Contact us
            </h2> */}
            <p className="text-center mt-2 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
              General Enquiry
            </p>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contact;
