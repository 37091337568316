import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CircleGrid } from "react-awesome-shapes/dist/shapes/circlegrid";
import { Donut } from "react-awesome-shapes/dist/shapes/donut";

const privateTutoring = [
  {
    question: "Can I choose between online and in-person tutoring",
    answer:
      "Most of our private tutoring is done online. In-person private tutoring is only available if there are tutors operating in the area where the student resides or studies. We strongly encourage students to take up tutoring online. Given the one-to-one nature of private tutoring, it is for most people as effective as in-person tutoring. Students who opt for online tutoring have access to a far greater choice of tutors to choose from.",
  },
  {
    question: "Can I choose my tutor?",
    answer:
      "Upon receiving enquiries from prospective students for private tutoring, we will provide options of all tutors after considering learning styles, preferred days, and budget. We pride ourselves for knowing who our tutors are, how they teach, and how they relate with students. This therefore gives us an advantage when recommending students to tutors who are the best fit.",
  },
  {
    question: "How do I pay?",
    answer:
      "Our preferred method of payment is bank transfer. Upon confirmation, we will provide students and/or their families with our bank account details.",
  },
  {
    question: "Do I have to pay for a minimum number of lessons?",
    answer: "No. Payment made after every lesson is the most popular option.",
  },
  {
    question: "How often should I receive tutoring?",
    answer:
      "It is an expectation that lessons will run on a weekly basis throughout the school terms based on the term dates of the students’ schools. Depending on your preference, every lesson will typically run for 60 or 90 minutes. Consistency and continuity in weekly tutoring lessons are fundamental to maximising the impact of tutoring and in turn will ensure maximal growth and progress.",
  },
  {
    question: "Do I get a trial lesson?",
    answer:
      "We do not lock students into minimum lessons. You may have your first lesson with your assigned tutor, and then decide whether to process further thereafter.",
  },
  {
    question: "What if I am unhappy with my tutoring?",
    answer:
      "Even though we pride ourselves for the high calibre of tutors in our talent pool, we also recognise that highly effective tutoring is dependent on the tutor’s teaching style being the right fit for you. In an unlikely event that you do not find this the case, please contact TutorWo at tutorwo.tutoring@gmail.com or on 0456 206 990 and we will be able to provide you with options, such as providing you with other tutors to choose from.",
  },
  {
    question:
      "What happened if I am unable to make a lesson, how do I reschedule a lesson?",
    answer:
      "It is an expectation that lessons will run on a weekly basis throughout the school terms based on the term dates of the students’ schools. Where possible, lessons should be rescheduled to another day during that same week rather than cancelled for that week. Any changes to a scheduled lesson must be communicated and discussed with the tutor in advance, no later than 24 hours prior to avoid cancellation charges.",
  },
  {
    question: "Will I get charged if I missed a lesson?",
    answer:
      "Students who cancel a lesson within 24 hours’ notice or fail to attend will be charged the full amount for that missed lesson.",
  },
  {
    question: "What do I need to do if I don’t want tutoring anymore? ",
    answer:
      "We will not lock you into a minimum number of lessons. Therefore, you have the flexibility to discontinue tutoring without any fees imposed provided that 24-hour notice is given. Simply contact us at tutorwo.tutoring@gmail.com or on 0456 206 990.",
  },
];

const masterClasses = [
  {
    question: "Can I choose between online and in-person tutoring? ",
    answer:
      "Our group masterclasses are all held online. This means students can conveniently benefit from a classroom-style, interactive learning environment from the comfort of their own homes and not having to worry about transportation before and after class.",
  },
  {
    question: "How do I pay?",
    answer:
      "Our preferred method of payment is bank transfer. Upon confirmation, we will provide students and/or their families with our bank account details.",
  },
  {
    question: "Do I have to pay for a minimum number of lessons?",
    answer:
      "Upfront payment for all sessions in the advertised duration of the program will be required.",
  },
  {
    question: "What if I am unhappy with my tutoring?",
    answer:
      "Please be in touch with TutorWo at tutorwo.tutoring@gmail.com or on 0456 206 990 and we will happily assist.",
  },
  {
    question: "What happened if I am unable to make a session?",
    answer:
      "Resources used during the session, for example PowerPoint slides and notes, will be provided for the purposes of assisting you in catching up.",
  },
  {
    question: "Will I get charged if I missed a lesson?",
    answer: "Yes, but we will provide you with the materials you have missed.",
  },
  {
    question: "What do I need to do if I don’t want tutoring anymore? ",
    answer:
      "Please be in touch with TutorWo at tutorwo.tutoring@gmail.com or on 0456 206 990 and we will happily provide you with options.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Faq = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        {/* Shapes decoration top */}
        <CircleGrid
          position="absolute"
          right="2rem"
          top="5rem"
          color="#863d90"
          size="50px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <CircleGrid
          position="absolute"
          left="1rem"
          top="10rem"
          color="#863d90"
          size="80px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <Donut
          color="#863d90"
          top="12rem"
          left="1rem"
          size="4rem"
          className="hidden xl:inline xl:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        />
        <Donut
          color="#863d90"
          top="7rem"
          right="1rem"
          size="4rem"
          className="hidden xl:inline xl:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        />
        {/* Decoration bottom */}
        <CircleGrid
          position="absolute"
          left="1rem"
          top="38rem"
          color="#863d90"
          size="80px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <CircleGrid
          position="absolute"
          left="2rem"
          top="24rem"
          color="#863d90"
          size="50px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <CircleGrid
          position="absolute"
          right="2rem"
          top="50rem"
          color="#863d90"
          size="50px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <CircleGrid
          position="absolute"
          left="2rem"
          top="54rem"
          color="#863d90"
          size="50px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <CircleGrid
          position="absolute"
          right="1rem"
          top="20rem"
          color="#863d90"
          size="80px"
          zIndex={0}
          className="hidden xl:inline"
        />
        <Donut
          color="#863d90"
          top="42rem"
          right="2rem"
          size="4rem"
          className="hidden xl:inline xl:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        />
        <Donut
          color="#863d90"
          top="48rem"
          left="2rem"
          size="4rem"
          className="hidden xl:inline xl:animate-shape"
          width={["0.5rem", "1rem", "1rem", "1rem"]}
          zIndex={0}
        />
        <div className="max-w-3xl mx-auto ">
          <h2 className="text-lg text-center font-semibold tracking-wider text-purple  uppercase">
            Frequently asked questions
          </h2>
          {/* Private Tutoring starts here */}
          <div>
            <p className="text-center mt-5 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
              Private Tutoring
            </p>
            <dl className="my-6 space-y-6 divide-y divide-grey">
              {privateTutoring.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-purple">
                          <span className="font-medium text-blue">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
          {/* Private Tutoring ends here */}
          {/* Master Classes starts here */}
          <div>
            <p className="text-center mt-36 text-3xl font-bold text-blue tracking-tight sm:text-4xl">
              Master Classes
            </p>
            <dl className="my-6 space-y-6 divide-y divide-grey">
              {masterClasses.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-purple">
                          <span className="font-medium text-blue">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
          {/* Master Classes ends here */}
        </div>
      </div>
    </div>
  );
};

export default Faq;
