import React from "react";
import cloud from "../components/assets/vectors/cloud.svg";
import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const Subjects = () => {
  const sectionRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start center", "end end"],
  });

  const temporaryY = useSpring(scrollYProgress, {
    stiffness: 200,
    damping: 10,
    restDelta: 0.001,
  });
  const y1 = useTransform(temporaryY, [0, 1], [-50, 300]);
  const y2 = useTransform(temporaryY, [0, 1], [-50, 200]);
  const opacity = useTransform(scrollYProgress, [0, 0.4], [0, 1]);

  return (
    <section ref={sectionRef}>
      {/* SUBJECTS */}
      <div className="relative flex flex-col justify-center items-center w-full text-center">
        <img
          className="w-[70%] lg:w-[80%] mt-20 z-10"
          src={cloud}
          alt="a cloud shape"
        />
        <p className="absolute  top-[10%] lg:top-[25%] text-white font-bold text-xl sm:text-3xl lg:text-5xl xl:text-6xl z-[11]">
          We offer the following subjects
        </p>
        <div className="grid grid-cols-2 lg:grid-cols-5 translate-x-10 w-[80%] lg:w-full">
          {/* Subject 1 */}
          <motion.div className="relative" style={{ y: y1, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="254"
              height="245"
              viewBox="0 0 254 245"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.153 8.3739C159.46 12.0958 189.762 17.3595 210.297 40.0258C232.597 64.6392 245.523 97.5004 239.54 130.214C233.255 164.579 209.427 191.875 179.315 209.431C145.445 229.178 105.08 245.637 69.3996 229.411C33.0681 212.889 18.9784 171.209 9.69001 132.293C0.234738 92.6773 -9.6509 45.7632 18.5782 16.4813C45.608 -11.5567 90.5595 3.63429 129.153 8.3739Z"
                fill="#FF914D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M140.153 15.3739C170.46 19.0958 200.762 24.3595 221.297 47.0258C243.597 71.6392 256.523 104.5 250.54 137.214C244.255 171.579 220.427 198.875 190.315 216.431C156.445 236.178 116.08 252.637 80.3996 236.411C44.0681 219.889 29.9784 178.209 20.69 139.293C11.2347 99.6773 1.3491 52.7632 29.5782 23.4813C56.608 -4.55666 101.559 10.6343 140.153 15.3739Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>

            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[1.1rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Biology Units 1-4
            </p>
          </motion.div>
          {/* END - subject 1 */}
          {/* Subject 2 */}
          <motion.div className="relative" style={{ y: y2, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="254"
              height="245"
              viewBox="0 0 254 245"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.153 8.3739C159.46 12.0958 189.762 17.3595 210.297 40.0258C232.597 64.6392 245.523 97.5004 239.54 130.214C233.255 164.579 209.427 191.875 179.315 209.431C145.445 229.178 105.08 245.637 69.3996 229.411C33.0681 212.889 18.9784 171.209 9.69001 132.293C0.234738 92.6773 -9.6509 45.7632 18.5782 16.4813C45.608 -11.5567 90.5595 3.63429 129.153 8.3739Z"
                fill="#FF914D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M140.153 15.3739C170.46 19.0958 200.762 24.3595 221.297 47.0258C243.597 71.6392 256.523 104.5 250.54 137.214C244.255 171.579 220.427 198.875 190.315 216.431C156.445 236.178 116.08 252.637 80.3996 236.411C44.0681 219.889 29.9784 178.209 20.69 139.293C11.2347 99.6773 1.3491 52.7632 29.5782 23.4813C56.608 -4.55666 101.559 10.6343 140.153 15.3739Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>

            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[1.1rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Chemistry Units 1-4
            </p>
          </motion.div>
          {/* END - subject 2 */}
          {/* Subject 3 */}
          <motion.div className="relative" style={{ y: y1, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="249"
              height="277"
              viewBox="0 0 249 277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
                fill="#863D90"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.9rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Chinese Second Language Units 1-4
            </p>
          </motion.div>
          {/* END - subject 3 */}
          {/* Subject 1 */}
          <motion.div className="relative" style={{ y: y2, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="249"
              height="277"
              viewBox="0 0 249 277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
                fill="#863D90"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.8rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Chinese Second Language Advanced Units 1-4
            </p>
          </motion.div>
          {/* END - subject 1 */}
          {/* Subject 2 */}
          <motion.div className="relative" style={{ y: y1, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="249"
              height="277"
              viewBox="0 0 249 277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
                fill="#863D90"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[1.1rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              English Units 1-4
            </p>
          </motion.div>
          {/* END - subject 2 */}
          {/* Subject 3 */}
          <motion.div className="relative" style={{ y: y1, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="249"
              height="277"
              viewBox="0 0 249 277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
                fill="#863D90"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.9rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              English Language Units 1-4
            </p>
          </motion.div>
          {/* END - subject 3 */}

          {/* Subject 1 */}
          <motion.div className="relative" style={{ y: y2, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="249"
              height="277"
              viewBox="0 0 249 277"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
                fill="#863D90"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-16 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.9rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              English Literature Units 1-4
            </p>
          </motion.div>
          {/* END - subject 1 */}
          {/* Subject 2 */}
          <motion.div className="relative" style={{ y: y1, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="257"
              height="235"
              viewBox="0 0 257 235"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M134.283 5.39198C171.855 2.461 215.338 -5.72042 240.385 22.4144C266.198 51.4103 256.388 94.9956 247.727 132.825C239.074 170.623 228.959 214.157 193.097 228.976C158.343 243.338 123.387 216.021 90.4959 197.799C61.1056 181.516 28.694 165.447 19.9205 133.034C10.8186 99.4082 23.85 63.4461 47.1037 37.4921C68.8362 13.236 101.798 7.92609 134.283 5.39198Z"
                fill="#0B4697"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.283 5.39198C156.855 2.461 200.338 -5.72042 225.385 22.4144C251.198 51.4103 241.388 94.9956 232.727 132.825C224.074 170.623 213.959 214.157 178.097 228.976C143.343 243.338 108.387 216.021 75.4959 197.799C46.1056 181.516 13.694 165.447 4.92048 133.034C-4.18145 99.4082 8.84997 63.4461 32.1037 37.4921C53.8362 13.236 86.7979 7.92609 119.283 5.39198Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-20 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.9rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Mathematics - General Units 1-4
            </p>
          </motion.div>
          {/* END - subject 2 */}
          {/* Subject 3 */}
          <motion.div className="relative" style={{ y: y2, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="257"
              height="235"
              viewBox="0 0 257 235"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M134.283 5.39198C171.855 2.461 215.338 -5.72042 240.385 22.4144C266.198 51.4103 256.388 94.9956 247.727 132.825C239.074 170.623 228.959 214.157 193.097 228.976C158.343 243.338 123.387 216.021 90.4959 197.799C61.1056 181.516 28.694 165.447 19.9205 133.034C10.8186 99.4082 23.85 63.4461 47.1037 37.4921C68.8362 13.236 101.798 7.92609 134.283 5.39198Z"
                fill="#0B4697"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.283 5.39198C156.855 2.461 200.338 -5.72042 225.385 22.4144C251.198 51.4103 241.388 94.9956 232.727 132.825C224.074 170.623 213.959 214.157 178.097 228.976C143.343 243.338 108.387 216.021 75.4959 197.799C46.1056 181.516 13.694 165.447 4.92048 133.034C-4.18145 99.4082 8.84997 63.4461 32.1037 37.4921C53.8362 13.236 86.7979 7.92609 119.283 5.39198Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-20 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.9rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Mathematics - Methods Units 1-4
            </p>
          </motion.div>
          {/* END - subject 3 */}
          {/* Subject 4 */}
          <motion.div className="relative" style={{ y: y1, opacity }}>
            <svg
              className={`-translate-y-40 lg:-translate-y-32 w-[65%] lg:w-[80%]`}
              width="257"
              height="235"
              viewBox="0 0 257 235"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M134.283 5.39198C171.855 2.461 215.338 -5.72042 240.385 22.4144C266.198 51.4103 256.388 94.9956 247.727 132.825C239.074 170.623 228.959 214.157 193.097 228.976C158.343 243.338 123.387 216.021 90.4959 197.799C61.1056 181.516 28.694 165.447 19.9205 133.034C10.8186 99.4082 23.85 63.4461 47.1037 37.4921C68.8362 13.236 101.798 7.92609 134.283 5.39198Z"
                fill="#0B4697"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.283 5.39198C156.855 2.461 200.338 -5.72042 225.385 22.4144C251.198 51.4103 241.388 94.9956 232.727 132.825C224.074 170.623 213.959 214.157 178.097 228.976C143.343 243.338 108.387 216.021 75.4959 197.799C46.1056 181.516 13.694 165.447 4.92048 133.034C-4.18145 99.4082 8.84997 63.4461 32.1037 37.4921C53.8362 13.236 86.7979 7.92609 119.283 5.39198Z"
                stroke="#E6E7E8"
                strokeWidth="3"
              />
            </svg>
            <p className="absolute left-6 -top-20 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[1.1rem] md:text-xl text-white lg:text-xl font-semibold w-[40%] sm:w-[50%]">
              Physics Units 1-4
            </p>
          </motion.div>
          {/* END - subject 4 */}
        </div>
        {/* END - row 3 */}
      </div>
      {/* END SUBJECTS */}
    </section>
  );
};

export default Subjects;
